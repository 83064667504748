import React, { FC } from 'react'

type ForwardIconProps = {
  className?: string
}

const ForwardIcon: FC<ForwardIconProps> = (props) => {
  const { className } = props
  return (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000S/svg" className={className}>
      <path d="M1 11L5 6L1 0.999999" stroke="#4392CE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ForwardIcon
