import React, { FC, SyntheticEvent } from 'react'

interface ISliderArrowProps {
  color?: string
  className?: string
  onClick?: (e: SyntheticEvent) => void
}

const SliderArrowLeft: FC<ISliderArrowProps> = ({ color, className, onClick }) => {
  return (
    <svg className={className} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M14.3984 5.6484C14.8255 5.22126 14.8255 4.52874 14.3984 4.1016C13.9713 3.67447 13.2787 3.67447 12.8516 4.1016L6.7266 10.2266C6.29947 10.6537 6.29947 11.3463 6.7266 11.7734L12.8516 17.8984C13.2787 18.3255 13.9713 18.3255 14.3984 17.8984C14.8255 17.4713 14.8255 16.7787 14.3984 16.3516L9.0468 11L14.3984 5.6484Z"
        fill={color}
      />
    </svg>
  )
}

SliderArrowLeft.defaultProps = {
  color: '#99A2AD',
}

export default SliderArrowLeft
