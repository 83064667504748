import { useEffect, useRef } from 'react'

export const SCROLL_OFF_CLASS = 'dy-scrollbar-off'
export const SCROLL_ON_CLASS = 'dy-scrollbar-on'
/**
 * - just add in you component -
 * useInvisibleScroll()
 * - and use className={' dy-scrollbar-off'} on container
 **/
function useInvisibleScroll() {
  const scroll = useRef<any>(null)

  function scrollHandler(e: any) {
    if (scroll.current !== null) {
      clearTimeout(scroll.current)
    }
    scroll.current = setTimeout(
      (e) => {
        if (e !== null) {
          try {
            if (e && e.target && e.target.classList.contains(SCROLL_ON_CLASS)) {
              e.target.classList.remove(SCROLL_ON_CLASS)
            }
          } catch (e) {}
        }
      },
      1000,
      e,
    )
    try {
      if (e && e.target && e.target.classList && !e.target.classList.contains(SCROLL_ON_CLASS)) {
        e.target.classList.add(SCROLL_ON_CLASS)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    subscribe()
    return () => unSubscribe()
  })
  const subscribe = () => {
    window.addEventListener('scroll', scrollHandler, true)
  }
  const unSubscribe = () => {
    //TODO: С отпиской что-то не та
    window.removeEventListener('scroll', scrollHandler)
  }
  return { subscribe, unSubscribe }
}

export default useInvisibleScroll
