import React, { FC, useEffect } from 'react'
import { FormInstance, FormItemProps } from 'antd/lib/form'
import { Form as AntForm } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import FormItemLabel from 'antd/lib/form/FormItemLabel'

import { captchaActions } from '../../../../store/slices/captchaSlice'
import { RootState } from '../../../../store/store'
import { Input } from '../../index'
import styles from './captcha.module.scss'

export type CaptchaProps = FormItemProps & {
  form: FormInstance
  error?: boolean
}

const Captcha: FC<CaptchaProps> = (props) => {
  const { form, error } = props
  const captcha = useSelector<RootState, Captcha | undefined>((state) => state.captcha.captcha)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(captchaActions.fetchCaptcha())
  }, [dispatch])

  useEffect(() => {
    form.setFieldsValue({ captchaKey: captcha?.captchaKey })
  }, [form, captcha])

  const itemProps = { ...props, form: undefined }

  return (
    <div className={styles.wrapper}>
      <FormItemLabel htmlFor={'captchaValue'} prefixCls={'ant-form'} label={'Введите код с картинки'} />
      <div className={styles.inputWrapper}>
        <img src={captcha?.captchaBase64} alt={'captcha'} />
        <AntForm.Item required={true} {...itemProps}>
          <Input id={'captchaValue'} className={error ? styles.error : ''} />
        </AntForm.Item>
        {error && (
          <div className={styles.errorMessage + ' ant-form-item-explain ant-form-item-explain-error'}>
            Вы неверно ввели символы. Попробуйте еще раз
          </div>
        )}
        <AntForm.Item required={true} {...itemProps} name={'captchaKey'} hidden>
          <Input hidden name={'captchaKey'} value={captcha?.captchaKey} />
        </AntForm.Item>
      </div>
    </div>
  )
}

Captcha.defaultProps = {
  name: 'captchaValue',
}

export default Captcha
