import React from 'react'
import { Row } from 'antd'

import styles from './poll-option-line.module.scss'
import { Text } from '../../../../../../common/text'

type PollOptionLineProps = {
  votePercent: number | undefined
  isChoices: boolean
}

const PollOptionLine: React.FC<PollOptionLineProps> = ({ votePercent, isChoices, children }) => {
  const readOnlyClass = typeof votePercent === 'number' ? styles.readOnly : ''
  const isChoicesReadOnly = isChoices ? styles.isChoicesReadOnly : ''
  const currentClass = isChoices ? isChoicesReadOnly : readOnlyClass

  return (
    <div className={[styles.lineContainer, currentClass].join(' ')}>
      {typeof votePercent === 'number' && (
        <div className={styles.percentsWrapper}>
          <div className={styles.percentsContainer}>
            <Row justify={'end'} align={'middle'}>
              <Text className={[styles.text, currentClass].join(' ')} size={'xl'} strong>
                {`${votePercent}%`}
              </Text>
            </Row>
          </div>
        </div>
      )}
      {children}
    </div>
  )
}

export default PollOptionLine
