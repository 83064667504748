import React, { FC } from 'react'

type ArrowDropdownProps = {
  size?: string | number
  color?: string
  className?: string
  rotate?: number
}

const ArrowDropdown: FC<ArrowDropdownProps> = ({ size = 24, color = '#000', className = '', rotate }) => {
  const svgStyle = rotate
    ? {
        msTransform: `rotate(${rotate}deg) translateY(-2px)`,
        transform: `rotate(${rotate}deg) translateY(-2px)`,
        transition: 'transform .2s ease-in',
      }
    : {
        transition: 'transform .2s ease-in',
      }

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={svgStyle}
    >
      <path d="M7 11.5L12 15.5L17 11.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowDropdown
