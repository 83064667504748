import { generatePath, useHistory } from 'react-router-dom'
import { globalSearchActions } from '../../../../../store/slices/globalSearchSlice'
import { useAppDispatch } from '../../../../../store/store'
import * as routes from '../../../../../constants/routes'

type UseItemGlobalSearch = (
  groupType: string,
  id: number,
  downloadLink: string,
) => {
  goLinkOrDownload: () => void
}

const useItemGlobalSearch: UseItemGlobalSearch = (groupType, id, downloadLink) => {
  const baseURL: string = process.env.REACT_APP_ENDPOINT || ''
  const dispatch = useAppDispatch()
  const history = useHistory()
  const hideModal = () => dispatch(globalSearchActions.hideModal())

  const getUrl = () => {
    const generate = (path: string): string => {
      const nameId = /\/:(\w+)/.exec(path)
      if (nameId && nameId[1]) {
        return generatePath(path, { [nameId[1]]: id })
      }
      return '/'
    }
    switch (groupType) {
      case 'News':
        return generate(routes.NEWS_DETAIL)
      case 'Event':
        return generate(routes.EVENT)
      case 'Album':
        return generate(routes.GALLERY_ALBUM)
      case 'Competence':
        return generate(routes.COMPETENCY)
      case 'CompetenceDocument':
        return generate(routes.COMPETENCY) + '/docs/'
      case 'Championship':
        return generate(routes.CHAMPIONSHIP)
      default:
        return '/'
    }
  }

  const download = () => {
    const a = document.createElement('a')
    a.href = baseURL + downloadLink
    a.target = '_blank'
    a.download = downloadLink.split('/').pop() || downloadLink
    a.click()
  }

  const goLinkOrDownload = () => {
    if (groupType === 'CompetenceDocument') {
      download()
    } else {
      hideModal()
      history.push(getUrl())
    }
  }

  return {
    goLinkOrDownload,
  }
}

export default useItemGlobalSearch
