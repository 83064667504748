import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const PushpinWithPoint: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1583 2.72427C14.2992 2.0194 15.1689 1.75609 15.6772 2.26437L21.7364 8.32359C22.2447 8.83188 21.9814 9.70154 21.2765 9.84251L19.4759 10.2026C19.4132 10.2152 19.3556 10.246 19.3103 10.2913L16.4302 13.1714C16.3755 13.2261 16.3421 13.2986 16.3362 13.3757L15.9892 17.8872C15.9381 18.5513 15.3583 19.0483 14.6942 18.9972C14.4071 18.9751 14.1374 18.8511 13.9339 18.6475L10.28 14.993L5.5364 19.7372C5.18492 20.0886 4.61508 20.0886 4.2636 19.7372C3.94142 19.415 3.91457 18.9093 4.18306 18.5565L4.2636 18.4644L9.007 13.72L5.35324 10.0669C4.91365 9.6273 4.88435 8.9328 5.26532 8.45922L5.35324 8.36129C5.55681 8.15772 5.82649 8.03369 6.11354 8.01161L10.625 7.66457C10.7022 7.65864 10.7747 7.6253 10.8294 7.57059L13.7095 4.69046C13.7547 4.64521 13.7856 4.58758 13.7981 4.52483L14.1583 2.72427ZM15.593 4.72577L15.5632 4.87784C15.4809 5.28905 15.2788 5.66672 14.9823 5.96326L12.1022 8.84338C11.7436 9.20192 11.2686 9.42038 10.7631 9.45927L7.539 9.70677L14.293 16.4608L14.5415 13.2377C14.5755 12.7953 14.747 12.3764 15.0296 12.0383L15.1574 11.8986L18.0375 9.01848C18.334 8.72195 18.7117 8.51982 19.1229 8.43758L19.274 8.40677L15.593 4.72577Z"
        fill={color}
      />
      <circle cx="3" cy="3" r="2.5" fill="#E64646" stroke="white" />
    </svg>
  )
}

PushpinWithPoint.defaultProps = {
  size: 22,
  className: '',
  color: '#818C99',
}

export default PushpinWithPoint
