import { Grid, Spin } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import useIsSticky from '../../../hooks/useIsSticky'
import { RootState } from '../../../store/store'
import { addCountlyEvent } from '../../../utils/stats'
import { AdminIcon, Burger } from '../../common/icons'
import Logo from '../../common/icons/logo/logo'
import { Button, Link } from '../../common/index'
import { Text } from '../../common/text'
import FormsModal from '../../forms/forms-modal/forms-modal'
import GlobalSearch from '../../global-search/global-search'
import BurgerMenu from './burger-menu/burger-menu'
import styles from './header.module.scss'
import TopProfile from './top-profile/top-profile'

const Header: FC = () => {
  const loading = useSelector((state: RootState) => state.account.loading)
  const user = useSelector((state: RootState) => state.account.user)
  const [tab, setTab] = useState<TabHeader>('hidden')
  const selectTab = (tab: TabHeader): void => setTab(tab)
  const { stickedClassName, sticked } = useIsSticky(false, styles.sticked, 0)
  const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false)

  // TODO: Зачем тут вешается event listener?
  useEffect(() => {
    document.body.addEventListener('click', () => {
      setShowBurgerMenu(false)
    })
  }, [])

  const onBurgerClick = (event: React.MouseEvent) => {
    event.nativeEvent.stopPropagation()
    setShowBurgerMenu(true)
  }

  const breakpointXl = Grid.useBreakpoint().xl

  return (
    <div className={[styles.header, breakpointXl ? stickedClassName : undefined].join(' ')}>
      <div>
        <div className={styles.content}>
          <div className={styles.logoWrapper}>
            <RouterLink to={'/'} onClick={() => addCountlyEvent('Клик по логотипу КРЭА')}>
              <Logo />
              <div className={styles.logoText}>
                <Text size={9.24}>Росэнергоатом</Text>
                <Text size={7.56}>Росатом</Text>
              </div>
            </RouterLink>
          </div>
          {!loading && (
            <div className={styles.authBtnWrapper}>
              {breakpointXl && (
                <>
                  <div className={styles.icon} onClick={() => addCountlyEvent('Переход в поиск на портале')}>
                    <GlobalSearch />
                  </div>
                  {user?.adminRoute && (
                    <Link to={user.adminRoute} className={styles.icon} external>
                      <AdminIcon size={40} />
                    </Link>
                  )}
                </>
              )}
              {!user && (
                <>
                  <Button
                    type={'primary'}
                    size={'small'}
                    onClick={() => {
                      selectTab('login')
                      addCountlyEvent(`Авторизация`, { Действие: 'Форма открыта' })
                    }}
                  >
                    Вход
                  </Button>
                  <Button
                    type={'link'}
                    size={'small'}
                    onClick={() => {
                      selectTab('registration')
                      addCountlyEvent(`Регистрация`, { Действие: 'Форма открыта' })
                    }}
                  >
                    Регистрация
                  </Button>
                </>
              )}
              {breakpointXl && user && <TopProfile />}
            </div>
          )}
          {breakpointXl && !sticked && loading && (
            <div className={styles.box_spin}>
              <Spin />
            </div>
          )}
          <div className={styles.mobileView}>
            {!breakpointXl && (
              <div className={styles.icon} onClick={() => addCountlyEvent('Переход в поиск на портале')}>
                <GlobalSearch />
              </div>
            )}
            <Button type={'ghost'} onClick={onBurgerClick}>
              <Burger />
            </Button>
          </div>
        </div>
        <FormsModal tab={tab} selectTab={selectTab} />

        <BurgerMenu show={showBurgerMenu} selectTab={selectTab} onClose={() => setShowBurgerMenu(false)} />
      </div>
    </div>
  )
}

export default Header
