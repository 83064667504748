import React, { FC } from 'react'
import { Menu } from 'antd'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'

const { Item } = Menu

export interface IOptionProps extends MenuItemProps {
  value?: string
}

const Option: FC<IOptionProps> = (props) => {
  return <Item {...props}>{props.children}</Item>
}

export default Option
