import React, {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithChildren,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
  useEffect,
  useState,
} from 'react'
import styles from './block.module.scss'
import { Button } from '../index'
import { Grid, Spin } from 'antd'

const { useBreakpoint } = Grid

type BlockProps = {
  title?: ReactNode
  className?: string
  bodyClassName?: string
  isCollapsable?: boolean
  onReadMoreChange?: (state: boolean) => void
  role?: string
  loading?: boolean
}

const Block: ForwardRefExoticComponent<PropsWithoutRef<PropsWithChildren<BlockProps>> & RefAttributes<HTMLDivElement>> =
  forwardRef(function _Block(props, ref) {
    const { title, children, className, bodyClassName, isCollapsable, onReadMoreChange, role, loading } = props

    const [collapsed, setCollapsed] = useState(true)
    const screens = useBreakpoint()

    useEffect(() => {
      onReadMoreChange && onReadMoreChange(!screens.md && collapsed)
    }, [collapsed, onReadMoreChange, isCollapsable, screens.md])

    return (
      <div
        className={[styles.container, className, isCollapsable && collapsed ? styles.collapsed : undefined].join(' ')}
        role={role}
        ref={ref}
      >
        {title && <div className={styles.title}>{title}</div>}
        <Spin spinning={loading || false}>
          <div className={[styles.body, bodyClassName || ''].join(' ')}>{children}</div>
        </Spin>
        {isCollapsable && (
          <div className={styles.readMoreContainer}>
            {collapsed ? (
              <Button type={'link'} onClick={() => setCollapsed(false)}>
                Читать полностью
              </Button>
            ) : (
              <Button type={'link'} onClick={() => setCollapsed(true)}>
                Скрыть
              </Button>
            )}
          </div>
        )}
      </div>
    )
  })

export default Block
