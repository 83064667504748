import { Empty } from 'antd'
import React, { FC } from 'react'
import { SearchGroup } from '../../../../api/model/models'
import { NotFound } from '../../../common/icons'
import GroupForGlobalSearch from '../group'
import useResultsFieldGlobalSearch from './hooks/useResultsFieldGlobalSearch'
import styles from './styles.module.scss'

const ResultsField: FC = () => {
  const { groups } = useResultsFieldGlobalSearch().state
  if (groups && groups.length > 0)
    return (
      <div className={styles.wrapper}>
        <div className={styles.scroll}>
          {groups.map((group: SearchGroup) => (
            <GroupForGlobalSearch groupType={group?.modelName} key={`${group?.modelName}${group?.name}`} />
          ))}
        </div>
      </div>
    )
  if (groups && groups.length === 0)
    return (
      <Empty
        description="Нет подходящих результатов по Вашему запросу"
        image={<NotFound color="#bfbfbf" />}
        className={styles.empty}
      />
    )
  return null
}

export default ResultsField
