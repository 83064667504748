import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { ForumCommentRead } from '../../api/model/forumCommentRead'
import { ForumCommentCreate } from '../../api/model/forumCommentCreate'
import { ForumCommentUpdate } from '../../api/model/forumCommentUpdate'

type ForumCommentsState = {
  comments: ForumCommentRead[]
  page: number
  pages: number
  perPage: number
  editingComment?: ForumCommentRead
  repliedToComment?: ForumCommentRead
  loading: boolean
  successSend?: boolean
  createdCommentId?: number
  prevActionIsUpdateComment: boolean
}

export const initialForumCommentsState: ForumCommentsState = {
  comments: [],
  page: 1,
  pages: 1,
  perPage: 20,
  loading: false,
  prevActionIsUpdateComment: false,
}

const fetchForumComments = createAsyncThunk('/fetchForumComments', async (payload: GetForumCommentsPayload) => {
  return appAPI.forumComments.getComments(payload)
})

const createForumComment = createAsyncThunk('/createForumComment', async (payload: Partial<ForumCommentCreate>) => {
  return (await appAPI.forumComments.postComment(payload)).data
})

const updateForumComment = createAsyncThunk(
  '/updateForumComment',
  async (payload: { id: number } & ForumCommentUpdate) => {
    const result = await appAPI.forumComments.putComment(payload)
    return { message: result.data.message, id: payload.id }
  },
)

const removeForumComment = createAsyncThunk('/removeForumComment', async (payload: { id: number }) => {
  await appAPI.forumComments.deleteComment(payload.id)
  return payload.id
})

const forumCommentsSlice = createSlice({
  name: 'comments',
  initialState: initialForumCommentsState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    setRepliedToComment: (state, { payload }: PayloadAction<ForumCommentRead | undefined>) => {
      state.repliedToComment = payload
      state.editingComment = undefined
    },
    setEditingComment: (state, { payload }: PayloadAction<ForumCommentRead | undefined>) => {
      state.editingComment = payload
      state.repliedToComment = undefined
    },
    clearCreatedCommentId: (state) => {
      state.createdCommentId = undefined
    },
    clearComments: () => {
      return initialForumCommentsState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchForumComments.pending, (state) => {
        state.loading = true
        state.prevActionIsUpdateComment = false
      })
      .addCase(fetchForumComments.fulfilled, (state, { payload }) => {
        if (state.page === 1) {
          state.comments = payload.data.results?.reverse() || []
        } else {
          state.comments = [...(payload.data.results?.reverse() || []), ...state.comments]
        }

        state.pages = payload.data.pages || 1
        state.loading = false
      })

      .addCase(createForumComment.pending, (state) => {
        state.loading = true
        state.createdCommentId = undefined
        state.successSend = false
        state.prevActionIsUpdateComment = false
      })
      .addCase(createForumComment.fulfilled, (state, { payload }) => {
        state.loading = false
        state.page = 1
        state.createdCommentId = payload.id
        state.successSend = true
        state.repliedToComment = undefined
      })

      .addCase(updateForumComment.pending, (state) => {
        state.loading = true
        state.createdCommentId = undefined
        state.successSend = false
        state.prevActionIsUpdateComment = true
      })
      .addCase(updateForumComment.fulfilled, (state, { payload }) => {
        const index = state.comments.findIndex((comment) => comment.id === payload.id)
        if (index !== -1) state.comments[index].message = payload.message
        state.loading = false
        state.createdCommentId = payload.id
        state.successSend = true
        state.repliedToComment = undefined
        state.editingComment = undefined
      })

      .addCase(removeForumComment.pending, (state) => {
        state.loading = true
        state.prevActionIsUpdateComment = true
      })
      .addCase(removeForumComment.fulfilled, (state, { payload: id }) => {
        const index = state.comments.findIndex((comment) => comment.id === id)
        if (index !== -1) state.comments.splice(index, 1)
        state.loading = false
      })
  },
})

export default forumCommentsSlice.reducer

export const forumCommentsActions = {
  ...forumCommentsSlice.actions,
  fetchForumComments,
  createForumComment,
  updateForumComment,
  removeForumComment,
}
