import { Moment } from 'moment'
import { RangeValue } from './common/title-range-picker/title-range-picker'
import { QuestionEditApi } from './survey-questions/map/question-edit-map'

export interface IcoType {
  color?: string
  size?: number
  className?: string
  onClick?: () => void
}

export enum Statuses {
  available = 'available',
  planned = 'planned',
  notavailable = 'notavailable',
  all = '',
}
export interface CompetenceType {
  id: number
  name: string
  pictogram?: string
  description?: string
}
export interface SurveyAdminType {
  id: number
  title: string
  description: string
  competences: number[]
  //competence: CompetenceType
  testStatus?: Statuses
}

export interface UserType {
  id: number
  FIO: string
}

export interface GroupType {
  id: number
  name: string
  competence?: CompetenceType
  competences?: number[]
  usersCount?: number
  questionsCount?: number
}
export interface SectionGroup {
  id: number
  name: string
  competences?: number[]
  usersCount?: number
  questionsCount?: number
}

export type ResponseTests = {
  tests: SurveyAdminType[]
}
/* response imitation - End */

export type RuleType = {
  id: number
  competence: number
  startDate: string
  maxDate: string
  maxAttempts: number
}

export type TestType = {
  id: number
  competence: number
  title: string
  description: string
  testStatus: Statuses
  duration?: number
  successPercent?: number
}

export type UserAttemptType = {
  timer?: string | number
  currentAttempt: number
  dateStarted: string | number
  testPeriodTimer: string | 0
}

export type TestsDataType = TestDataType[]

export type TestDataType = {
  active: number
  dateToStart: string | number
  remainAttempts: number
  rule: RuleType
  test: TestType
  testAttemptId: number
  userAttempt: UserAttemptType
}

export type QuestionViewFile = {
  src: string
  alt?: string
}

export interface QuestionType {
  id: number
  competence: number
  file: QuestionViewFile | string | null
  text?: string
  name?: string
  section: number
  type: string
  answers: AnswersType
  correctAnswer?: string | string[] | number | number[]
  userAnswer?: string | string[] | number | number[] | null
  validators?: any[]
  isRequired?: boolean
  valueName?: string
}

export interface QuestionViewType extends QuestionType {
  questionEstimation: number
  score: number
}

export interface answerEditType {
  idB: number
  data: string
  correct: boolean
  order?: number
}

export enum QuestionsTypes {
  text = 'text',
  radioGroup = 'radiogroup',
  checkboxes = 'checkbox',
  orderGroup = 'ranking',
  numberRange = 'range',
}

export interface QuestionEdit {
  id: number
  competences: Array<number>
  file: File | null | string
  text: string
  section: number
  type: QuestionsTypes
  answers: Array<answerEditType>
  points: number
  isEditing?: boolean
}

export interface QuestionEditSection extends QuestionEdit {
  sectionId: number
  competenceId: number
  score: number
  text: string
}

export type UserAnswersType = UserAnswerType[]

export type UserAnswerType = { question: string; answerText: string; isCorrect: string }

export type AnswersType = AnswerType[]

export type AnswerType = {
  id: number
  answer: string
}

export type CurrentAttemptsType = AttemptType[]

export type AttemptType = {
  fullAttempts: ParticipantsAttemptsType[][]
  test: TestType
  testAttemptId: number
  testMaxPoints: number
  userAttempt: AttemptUserType[]
}

export type PassAttemptType = {
  id: number
  dateCreated: string
  dateEndCalculated: string | null
  dateEndActual: string | null
  timer: number
  score: string
  result: boolean
  participantBind: number
}

export type AttemptUserType = {
  currentAttempt: number
  participantsAttempts: ParticipantsAttemptsType[]
  testAttempt: number
}

export type TestQuestionType = {
  id: number
  competence: number
  file: null | string
  text: string
  section: number
  type: string
  answers: AnswerType[]
}

export type ParticipantsAttemptsAnswersType = {
  pages: number
  total: number
  results: ParticipantsAttemptsAnswerType[]
}

export type ParticipantsAttemptsAnswerType = {
  id: number
  answer: number[] | string
  questionEstimation: string
  isCorrect: string
  dateCreated: string
  dateEdited: string | null
  dateRemoved: string | null
  question: number
  attempt: number
  participantBind: number
}

export type ParticipantsAttemptsType = {
  answers: ParticipantsAttemptsAnswerType[] // todoEvg: удалитть потом
  currentAttempt: number
  currentPercent: number
  date: string
  dateCreated: string
  id: number
  maxPoints: number
  result: boolean
  score: string
  successPercent: number
  testId: number
  testTitle: string
  timer: string
}

export interface EditSurvey {
  competence: CompetenceType
  competences: number[]
  id: number
  title: string
  description: string
  timeRange: RangeValue | undefined
  group: GroupType
  rule: Rule
  duration: number | Moment | null
  successPercent: number
  allowPrevious: boolean
  allowSkip: boolean
  prohibitExit: boolean
  mixAnswers: boolean
  mixQuestions: boolean
  hideQuestions: boolean
  sections: Array<number | null>
  status: Statuses
  testStatus?: Statuses
  participants: Array<Participants>
  testingQuestions: Array<TestingQuestion>
}

export type TestingQuestion = {
  id: number
  questions: SurveyQuestionEditAdmin
}

export type SurveyQuestionEditAdmin = {
  id: number
  competenceId: number
  competences: number[]
  file: null | number
  text: string
  type: QuestionsTypes
  score: number
  active: boolean
  sectionId: number
  answers: Array<SurveyQuestionEditAnswer>
}

type SurveyQuestionEditAnswer = {
  id: number
  answer: string
  correct: boolean
  position: null | number
}

export type Participants = {
  id: number
  group: SectionGroup
  rule: Rule
}

export type Rule = {
  id: number
  maxAttempts: number
  name: string
  startDate: string
  maxDate: string
  testPeriod: number | Moment | null
}

export type CurrentDataDetailResultType = {
  attemptsCount: number
  authorCompany: string
  authorName: string
  correctAnswersCount: number
  dateCreated: string
  id: number
  questionCount: number
  result: boolean
  score: string
  testScore: string
  successPercent: number
  timer: string
  test: ResultSurveyType
}

export type ResultSurveyType = {
  allowPrevious: boolean
  allowSkip: boolean
  competences: number[]
  description: string
  duration: number
  id: number
  mixAnswers: boolean
  mixQuestions: boolean
  prohibitExit: boolean
  hideQuestions: boolean
  successPercent: number
  title: string
  testingQuestions: ResultSurveyQuestionsType[]
}

export type ResultSurveyQuestionsType = {
  questions: ResultSurveyQuestion
}
export type ResultSurveyQuestion = {
  active: boolean
  file: string | null
  answers: SurveyQuestionEditAnswer[]
  competences: number[]
  id: number
  score: number
  sectionId: number
  text: string
  type: QuestionsTypes
  userAnswer: string | string[] | number | number[] | null
}

export type CurrentDataDetailType = {
  id: number
  competence: number
  dateCreated: string
  description: string
  duration: number
  questions: QuestionType[]
  questionsOrder: string
  title: string
  prohibitExit: boolean
  allowSkip: boolean
  allowPrevious: boolean
  mixAnswers: boolean
  hideQuestions: boolean
  active?: string
  error?: string
  status?: string
}

export type CurrentTimerType = {
  timer: string
  timerSeconds: number
  timerState: number
  statusStart?: string
  detail?: string
  userAnswers?: Record<number, string | string[] | number | number[]>[]
}

export type ArrayResultsType = ResultsType[]

export type ResultsType = {
  answer: string | string[] | number | number[]
  question: number
}

export type TimerStateType = { timerState: number }

export type IdTitleType = { id: number; title: string }

export type CacheType<T> = {
  id: number
  data: T
}

export interface SectionType {
  name: string
  desc: string
  competence: CompetenceType
  questionsCount?: number
  id: number
}

export type EditGroup = {
  id: number
  name: string
  competences: number[]
  Members: idType[]
}

export interface SectionFull {
  name: string
  competences: number[]
  id: number
  sectionQuestions: Array<idType>
}

type idType = {
  id: number
}

export enum TestMode {
  test,
  preView,
  view,
  questionView,
}

export type ParticipantsPayloadType = {
  championshipId?: number
  championshipPoints?: boolean
  competenceId?: number | number[]
  limit?: number
  offset?: number
}

export type QuestionAdminFilter = {
  competences: Array<number | null>
  sections: Array<number | null>
  types: Array<QuestionsTypes>
}
export type ResultTest = {
  id: number
  authorName: string
  testName: string
  authorCompany: string
  correctAnswersCount: number
  questionCount: number
  successPercent: string
  attemptsCount: number
  timer: number
  dateCreated: string
  result: boolean
  score: string
  testScore: number
}

export type QuestionsList = {
  id: number
  name: string
  competences: number[]
  questions: QuestionEditApi[]
}

export type QuestionList = {
  id: number
  name: string
}
