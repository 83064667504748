import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
}

const Attach: FC<Props> = ({ size = 28, className = '', color = '#818C99' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.7331 11.6259C14.0678 11.2912 14.6105 11.2912 14.9453 11.6259C15.28 11.9606 15.28 12.5034 14.9453 12.8381L11.3693 16.414C10.8833 16.9 10.8551 17.7181 11.3693 18.2323C11.8835 18.7465 12.7016 18.7183 13.1876 18.2323L19.8546 11.5653C21.2274 10.1925 21.1523 8.01428 19.8546 6.71657C18.5569 5.41886 16.3787 5.34374 15.0059 6.71657L8.33886 13.3836C6.15252 15.5699 6.19329 19.1172 8.33886 21.2628C10.4844 23.4083 14.0317 23.4491 16.218 21.2628L19.794 17.6868C20.1287 17.3521 20.6714 17.3521 21.0062 17.6868C21.3409 18.0216 21.3409 18.5643 21.0062 18.899L17.4302 22.4749C14.5681 25.3371 9.93555 25.2838 7.12667 22.4749C4.3178 19.6661 4.26455 15.0335 7.12667 12.1714L13.7937 5.50438C15.8554 3.44269 19.1176 3.55518 21.0668 5.50438C23.016 7.45359 23.1285 10.7158 21.0668 12.7775L14.3998 19.4445C13.2623 20.582 11.3602 20.6476 10.1571 19.4445C8.95407 18.2414 9.01965 16.3393 10.1571 15.2018L13.7331 11.6259Z"
        fill={color}
      />
    </svg>
  )
}

export default Attach
