import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { UserWSBase } from '../../api/model/userWSBase'

type CompetenceDocsState = {
  experts: Array<UserWSBase>
  loading: boolean
  currentId?: number
}

export const initialCompetenceExpertsState: CompetenceDocsState = {
  experts: [],
  loading: false,
}

const fetchCompetenceExperts = createAsyncThunk(
  '/fetchCompetenceExperts',
  async (payload: GetCompetenceExpertsPayload) => {
    return await appAPI.competences.getDivisionExperts(payload.id.toString())
  },
)

const competenceExpertsSlice = createSlice({
  name: 'competenciesExperts',
  initialState: initialCompetenceExpertsState,
  reducers: {
    setCurrentId: (state: CompetenceDocsState, { payload }: PayloadAction<number | undefined>) => {
      state.currentId = payload
    },
    clearCompetenceExperts: () => initialCompetenceExpertsState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetenceExperts.pending, (state: CompetenceDocsState) => {
        state.loading = true
      })
      .addCase(fetchCompetenceExperts.fulfilled, (state: CompetenceDocsState, { payload }) => {
        state.experts = payload.data || []
        state.loading = false
      })
      .addCase(fetchCompetenceExperts.rejected, (state: CompetenceDocsState) => {
        state.loading = false
      })
  },
})

export default competenceExpertsSlice.reducer

export const competenceExpertsSliceActions = {
  ...competenceExpertsSlice.actions,
  fetchCompetenceExperts,
}
