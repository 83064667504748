import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const CommentCrossedOutOutlined: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={color} />
      <path
        d="M13.0101 16.021C13.2137 15.8422 13.4751 15.7436 13.7456 15.7436H16.7872C17.5875 15.7436 18.0638 15.2655 18.0638 14.4623V8.05605C18.0638 7.25291 17.5875 6.77479 16.7872 6.77479H7.21277C6.41254 6.77479 5.93617 7.25291 5.93617 8.05605V14.4623C5.93617 15.2655 6.41254 15.7436 7.21277 15.7436H9.20665C9.7646 15.9051 9.7646 15.9051 9.92553 16.4651V18.7302L13.0101 16.021ZM9.0758 20.0939C8.69919 19.8104 8.48936 19.3102 8.48936 18.8667V17.185H7.21277C5.61937 17.185 4.5 16.0616 4.5 14.4623V8.05605C4.5 6.45683 5.61937 5.33337 7.21277 5.33337H16.7872C18.3806 5.33337 19.5 6.45683 19.5 8.05605V14.4623C19.5 16.0616 18.3806 17.185 16.7872 17.185H13.8656L10.8937 19.7949C10.309 20.3705 9.62578 20.5079 9.0758 20.0939Z"
        fill="white"
      />
      <rect
        x="19.4199"
        y="3.90479"
        width="2.5"
        height="21.1984"
        rx="1.25"
        transform="rotate(51.4857 19.4199 3.90479)"
        fill={color}
        stroke="white"
      />
    </svg>
  )
}

CommentCrossedOutOutlined.defaultProps = {
  size: 24,
  className: '',
  color: '#309BEC',
}

export default CommentCrossedOutOutlined
