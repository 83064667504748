import React, { FC } from 'react'

type CardArrowProps = {
  className?: string
  arrowColor?: string
  size?: number | string
}

const CardArrow: FC<CardArrowProps> = ({ className, arrowColor, size = 24 }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        className="arrow-background"
        d="M10 -4.37114e-07C4.47715 -1.95703e-07 1.95703e-07 4.47715 4.37114e-07 10L6.11959e-07 14C8.53371e-07 19.5228 4.47715 24 10 24L14 24C19.5228 24 24 19.5228 24 14L24 10C24 4.47715 19.5228 -8.53371e-07 14 -6.11959e-07L10 -4.37114e-07Z"
      />
      <rect x="24" rx="5" transform="rotate(90 24 0)" />
      <path d="M11 7L15 12L11 17" stroke={arrowColor} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

//width="24" height="24"
//fill="#E6EFF6"

CardArrow.defaultProps = {
  arrowColor: '#4392CE',
}

export default CardArrow
