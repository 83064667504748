import { BadgeProps as AntBadgeProps } from 'antd/lib/badge'
import { Badge as AntBadge } from 'antd'
import React, { FC } from 'react'
import './badge.ant.scss'

type BadgeProps = AntBadgeProps

const Badge: FC<BadgeProps> = ({ children, ...rest }) => {
  return <AntBadge {...rest}>{children}</AntBadge>
}

export default Badge
