import React, { useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

// TODO: May be exclude this in hook
const ScrollToTop: React.FC = ({ children }) => {
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen((location) => {
      // If path is root or only one level then regular expression valid
      // Examples:
      // / - true
      // /news - true
      // /news/ - true
      // /news/<id> - false
      // /news/<id>/ - false
      const regex = new RegExp('^(\\/[^\\/]+)?\\/?$')
      if (regex.exec(location.pathname)) {
        window.scrollTo(0, 0)
      }
    })
    return () => {
      unlisten()
    }
  }, [history])

  return <>{children}</>
}

export default withRouter(ScrollToTop)
