import { VotingListItemApi, votingMap } from './to-votings-list-map'
import { ResultVoting } from '../types'

const sum = (options: OptionResult[]) => {
  return options.reduce((accumulator: number, s) => {
    return accumulator + s.votesCount
  }, 0)
}

const pickedOption = (data: ResultVotingApi, x: ResultVotingQuestion): Array<number> => {
  if (data.userVote === null) return []
  const result = data.userVote.userVotes.find((f) => f.pollQuestion === x.id)
  if (result) return result.option
  else return []
}

export const resultVotingMap = (data: ResultVotingApi): ResultVoting => {
  return {
    votingView: votingMap(data),
    questions: data.questionsId
      .map((x) => {
        return {
          id: x.id,
          name: x.text,
          multiply: x.maxVoteOptions > 1,
          options: x.questionOptions
            .map((o) => {
              return {
                id: o.id,
                name: o.text,
                file: o.file
                  ? { name: 'Видео', description: o.fileDescription ? o.fileDescription : '', source: o.file }
                  : null,
                percent: (isNaN((o.votesCount / sum(x.questionOptions)) * 100)
                  ? 0
                  : (o.votesCount / sum(x.questionOptions)) * 100
                ).toFixed(2),
              }
            })
            .sort((a, b) => a.id - b.id),
          picked: pickedOption(data, x),
        }
      })
      .reverse(),
  }
}

export interface ResultVotingApi extends VotingListItemApi {
  totalVotesCount: number
  questionsId: Array<ResultVotingQuestion>
  userVote: ResultUserVote | null
}

type ResultVotingQuestion = {
  id: number
  text: string
  maxVoteOptions: number
  questionOptions: Array<OptionResult>
}

type ResultUserVote = {
  userVotes: Array<ResultVotingUserVotes>
}

type ResultVotingUserVotes = {
  pollQuestion: number
  option: Array<number>
}

type OptionResult = {
  id: number
  text: string
  file: null | string
  fileDescription: null | string
  votesCount: number
}
