import React, { FC } from 'react'
import { Title } from '../../../common/text'
import styles from './statistic-popover-title.module.scss'
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue'

interface StatisticPopoverTitleProps {
  title: React.ReactNode | RenderFunction
  subtitle?: string
}

const StatisticPopoverTitle: FC<StatisticPopoverTitleProps> = (props) => {
  const { title, subtitle } = props

  return (
    <>
      <Title level={2}>{title}</Title>
      <p className={styles.content}>{subtitle}</p>
    </>
  )
}

export default StatisticPopoverTitle
