import React from 'react'

const Results: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3L19 3C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V11.675C20.6833 11.525 20.3583 11.4 20.025 11.3C19.6917 11.2 19.35 11.125 19 11.075V5L5 5L5 19.05H11.075C11.1583 19.5667 11.2877 20.0583 11.463 20.525C11.5234 20.6863 11.5903 20.8447 11.6636 21H5C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19L3 5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 23C16.6167 23 15.4377 22.5127 14.463 21.538C13.4877 20.5627 13 19.3833 13 18C13 16.6167 13.4877 15.4373 14.463 14.462C15.4377 13.4873 16.6167 13 18 13C19.3833 13 20.5627 13.4873 21.538 14.462C22.5127 15.4373 23 16.6167 23 18C23 19.3833 22.5127 20.5627 21.538 21.538C20.5627 22.5127 19.3833 23 18 23ZM20.75 16.8L17.275 20.25L15.25 18.225L16.3 17.15L17.275 18.125L19.7 15.75L20.75 16.8Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9L6 9L6 7L18 7V9ZM14.463 21.538C15.4377 22.5127 16.6167 23 18 23C19.3833 23 20.5627 22.5127 21.538 21.538C22.5127 20.5627 23 19.3833 23 18C23 16.6167 22.5127 15.4373 21.538 14.462C20.5627 13.4873 19.3833 13 18 13C16.6167 13 15.4377 13.4873 14.463 14.462C13.4877 15.4373 13 16.6167 13 18C13 19.3833 13.4877 20.5627 14.463 21.538ZM11.075 17H6V15L11.675 15C11.525 15.3167 11.4 15.6417 11.3 15.975C11.2 16.3083 11.125 16.65 11.075 17ZM13.1 13L6 13V11L18 11C17.05 11 16.154 11.179 15.312 11.537C14.4707 11.8957 13.7333 12.3833 13.1 13ZM19.7 15.75L20.75 16.8L17.275 20.25L15.25 18.225L16.3 17.15L17.275 18.125L19.7 15.75Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Results
