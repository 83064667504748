import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import appAPI from '../../api/api'
import { ForumGroup } from '../../api/model/forumGroup'
import { RootState } from '../store'

export type ForumGroupState = {
  groups: Array<ForumGroup>
  loading: boolean
}

export const initialForumGroupState: ForumGroupState = {
  groups: [],
  loading: false,
}

const fetchForumGroups = createAsyncThunk(
  'fetchForumGroups',
  async (payload: GetForumGroupsPayload, { rejectWithValue }) => {
    try {
      return await appAPI.forumGroup.getGroups(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const forumGroupsSlice = createSlice({
  name: 'forumGroups',
  initialState: initialForumGroupState,
  reducers: {
    clearForumGroups: () => initialForumGroupState,
  },
  extraReducers: {
    [fetchForumGroups.pending.type]: (state) => {
      state.loading = true
    },
    [fetchForumGroups.fulfilled.type]: (state, { payload: { data } }) => {
      state.loading = false
      state.groups = data.results || []
    },
  },
})

export default forumGroupsSlice.reducer

export const forumGroupsSliceActions = {
  ...forumGroupsSlice.actions,
  fetchForumGroups,
}

export const selectForumGroupsWithoutDefault = (state: RootState): Array<ForumGroup> =>
  state.forumGroups.groups.filter((group) => !group.isDefault)

export const selectDefaultGroup = (state: RootState): ForumGroup | undefined =>
  state.forumGroups.groups.find((group) => group.isDefault)
