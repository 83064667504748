import React, { FC } from 'react'
import { SearchIcon } from '../common/icons'
import Modal from '../common/modal/modal'
import GlobalInputSearch from './components/input-search'
import ResultsField from './components/results-field'
import './global-search-styles.ant.scss'
import styles from './global-search.module.scss'
import { useGlobalSearch } from './hooks/useGlobalSearch'

const GlobalSearch: FC = () => {
  const { state, methods } = useGlobalSearch()
  return (
    <>
      <SearchIcon onClick={methods.showModal} representation="header" size={24} />

      <Modal
        visible={state.isShowModal}
        footer={null}
        onCancel={methods.hideModal}
        className={styles.modal + ' global-search'}
        destroyOnClose={true}
        closable={false}
        maskClosable={true}
      >
        <GlobalInputSearch />
        <ResultsField />
      </Modal>
    </>
  )
}

export default GlobalSearch
