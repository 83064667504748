import React, { FC } from 'react'

type Props = {
  size?: string | number
  color?: string
  className?: string
  onClick?: () => void
}

const Play: FC<Props> = (props) => {
  const { size = '24', className = '', color = '#333333', ...restProps } = props

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M18 10.2679C19.3333 11.0377 19.3333 12.9623 18 13.7321L9 18.9282C7.66667 19.698 6 18.7358 6 17.1962L6 6.80385C6 5.26425 7.66667 4.302 9 5.0718L18 10.2679Z"
        fill={color}
      />
    </svg>
  )
}

export default Play
