import React, { FC } from 'react'
import { Rule } from 'antd/lib/form'

import Form from '../../common/form/form'
import Select, { Option } from '../../common/select/select'

const { Item } = Form

interface Props {
  name: string
  label: string | React.ReactNode
  value: 'id'
  content: 'name'
  data: Record<'id' | 'name', string | number>[]
  placeholder?: string
  rules?: Rule[]
  showSearch?: boolean
  onChange?: () => void
}

const FieldSelect: FC<Props> = (props) => {
  const { placeholder, showSearch = false, value, content, data, onChange, ...itemProps } = props
  return (
    <Item {...itemProps}>
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        getPopupContainer={(trigger) => trigger.parentElement}
        filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0}
        onChange={onChange}
      >
        {data.map((item) => (
          <Option value={item[value].toString()} key={item[value]}>
            {item[content]}
          </Option>
        ))}
      </Select>
    </Item>
  )
}

export default FieldSelect
