import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'

type AttachmentsState = {
  attachments: AttachmentInfo[]
  attachmentsToRemove: string[]
  loading: boolean
}

const initialAttachmentsState: AttachmentsState = {
  attachments: [],
  attachmentsToRemove: [],
  loading: false,
}

const createAttachments = createAsyncThunk(
  'createAttachments',
  async (payload: AttachmentsPayload, { rejectWithValue }) => {
    try {
      return await appAPI.forumComments.postAttachments(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const removeAttachments = createAsyncThunk('removeAttachments', async (payload: number, { rejectWithValue }) => {
  try {
    return await appAPI.forumComments.deleteAttachment(payload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const AttachmentsSlice = createSlice({
  name: 'attachments',
  initialState: initialAttachmentsState,
  reducers: {
    setAttachments: (state, { payload }: PayloadAction<AttachmentInfo[]>) => {
      state.attachments = payload
    },
    appendAttachmentsToRemove: (state, { payload }: PayloadAction<string>) => {
      state.attachmentsToRemove = [...state.attachmentsToRemove, payload]
    },
    clearAttachments: () => {
      return initialAttachmentsState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAttachments.pending, (state) => {
        state.loading = true
      })
      .addCase(createAttachments.fulfilled, (state, { payload, meta }) => {
        state.loading = false
      })
      .addCase(removeAttachments.pending, (state) => {
        state.loading = true
      })
      .addCase(removeAttachments.fulfilled, (state, { payload, meta }) => {
        state.loading = false
      })
  },
})

export default AttachmentsSlice.reducer

export const attachmentsActions = {
  ...AttachmentsSlice.actions,
  createAttachments,
  removeAttachments,
}
