import React, { FC } from 'react'
import { Radio as AntRadio } from 'antd'
import { RadioGroupProps, RadioProps } from 'antd/lib/radio'
import { RadioButtonProps } from 'antd/lib/radio/radioButton'
import './radio-group.ant.scss'

interface RadioGroupExt {
  Radio: FC<RadioProps>
  Button: FC<RadioButtonProps>
}

export type IRadioGroupProps = RadioGroupProps

const RadioGroup: RadioGroupExt & FC<IRadioGroupProps> = (props) => {
  const { children } = props
  return <AntRadio.Group {...props}>{children}</AntRadio.Group>
}

export const Button = AntRadio.Button
export const Radio = AntRadio

RadioGroup.Button = Button
RadioGroup.Radio = Radio

export default RadioGroup
