import React from 'react'

const Tags: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20L3 6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4L6 4V3C6 2.44772 6.44772 2 7 2C7.55228 2 8 2.44772 8 3V4L16 4V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6L21 20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22L5 22ZM5 20L19 20L19 10L5 10L5 20ZM5 8L19 8V6L5 6L5 8Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Tags
