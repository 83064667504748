import React, { FC } from 'react'

const Notification: FC = () => {
  return (
    <svg width="47" height="53" viewBox="0 0 47 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.5036 53C22.2421 53 18.951 50.6218 18.951 46.2051H34.0562C34.0562 50.6218 30.7651 53 26.5036 53ZM41.5936 31.8262C41.5936 36.4856 47 37.6504 47 39.9801C47 42.3097 45.9211 43.4746 41.6053 43.4746H11.3947C7.07895 43.4746 6 42.3097 6 39.9801C6 37.6504 11.3947 36.4856 11.3947 31.8262V23.6724C11.3947 12.024 15.7105 2.32967 24.3421 2.32967C24.3421 0.582418 25.4211 0 26.5 0C27.5789 0 28.6579 0.582418 28.6579 2.32967C37.2895 2.32967 41.5936 12.024 41.5936 23.6724V31.8262Z"
        fill="#309BEC"
      />
      <path
        d="M19.6842 3.32967H20.6842V2.32967C20.6842 1.69446 20.8833 1.41597 21.0503 1.27595C21.2486 1.10976 21.5709 1 22 1C22.4291 1 22.7514 1.10976 22.9497 1.27595C23.1167 1.41597 23.3158 1.69446 23.3158 2.32967V3.32967H24.3158C28.5296 3.32967 31.7031 5.50703 33.8716 9.14493C36.0624 12.8199 37.1981 17.9601 37.1981 23.6724V31.8262C37.1981 34.6449 38.9847 36.3613 40.4729 37.5565C40.7661 37.792 41.0355 37.9986 41.2841 38.1895C41.7162 38.5211 42.0858 38.8047 42.4097 39.1082C42.9131 39.5798 43 39.8365 43 39.9801C43 40.9718 42.7658 41.448 42.2851 41.7704C41.6826 42.1745 40.5012 42.4746 38.2105 42.4746H5.78947C3.49878 42.4746 2.31741 42.1745 1.71489 41.7704C1.23418 41.448 1 40.9718 1 39.9801C1 39.8357 1.08714 39.5787 1.5891 39.1074C1.91227 38.804 2.28098 38.5204 2.71212 38.1889C2.96023 37.9981 3.22902 37.7914 3.52171 37.5558C5.00722 36.3603 6.78947 34.644 6.78947 31.8262V23.6724C6.78947 17.9605 7.92815 12.8205 10.1218 9.14543C12.2935 5.50729 15.4702 3.32967 19.6842 3.32967ZM22.0039 52C19.878 52 18.0893 51.4461 16.8553 50.4735C15.8698 49.6967 15.1888 48.6166 14.9723 47.2051H29.0354C28.819 48.6166 28.1379 49.6967 27.1524 50.4735C25.9185 51.4461 24.1297 52 22.0039 52Z"
        stroke="#003274"
      />
    </svg>
  )
}

export default Notification
