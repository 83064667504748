import { useEffect, useRef, useState } from 'react'

const useIsSticky = (initialState: boolean, stickedClassName = '', scrollY = 0) => {
  const [sticked, setSticked] = useState<boolean>(initialState)

  const stickyRef = useRef<HTMLDivElement>(null)
  const widthRef = useRef<number>(0)

  const getInitialWidth = (element: HTMLDivElement, stickedClassName: string): number => {
    element.style.width = 'auto'
    const hasClass = element.classList.contains(stickedClassName)
    hasClass && element.classList.remove(stickedClassName)
    const width = element.clientWidth
    hasClass && element.classList.add(stickedClassName)
    return width
  }

  const setWidth = (element: HTMLDivElement, width?: number) => {
    element.style.width = width ? width + 'px' : 'auto'
  }

  useEffect(() => {
    const onResize = () => {
      if (window.scrollY > scrollY && stickyRef.current) {
        widthRef.current = getInitialWidth(stickyRef.current, stickedClassName)
        setWidth(stickyRef.current, widthRef.current)
      }
    }

    const onScroll = () => {
      if (stickyRef.current) {
        if (window.scrollY > scrollY && widthRef.current !== 0) {
          setWidth(stickyRef.current, widthRef.current)
        } else {
          if (window.scrollY > scrollY) {
            widthRef.current = getInitialWidth(stickyRef.current, stickedClassName)
            setWidth(stickyRef.current, widthRef.current)
          } else {
            setWidth(stickyRef.current)
            widthRef.current = getInitialWidth(stickyRef.current, stickedClassName)
          }
        }
      }

      setSticked(window.scrollY > scrollY)
    }

    window.addEventListener('resize', onResize)
    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onResize)
    }
  }, [setSticked, scrollY, stickedClassName])

  return { stickedClassName: sticked ? stickedClassName : '', sticked, stickyRef }
}

export default useIsSticky
