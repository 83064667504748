import React from 'react'
import { Collapse as AntCollapse } from 'antd'

import CollapseItem from './collapse-item/collapse-item'
import { ExpandIconPosition } from 'antd/es/collapse/Collapse'

interface CollapseInterface<P> extends React.FC<P> {
  Panel: typeof CollapseItem
}
interface CustomPanelProps {
  isActive?: boolean
}

type CollapseProps = {
  expandIcon?: ((props: CustomPanelProps) => React.ReactNode) | undefined
  expandIconPosition?: ExpandIconPosition
}

const Collapse: CollapseInterface<CollapseProps> = (props) => {
  const { children, expandIconPosition = 'right', expandIcon } = props

  return (
    <AntCollapse ghost expandIconPosition={expandIconPosition} expandIcon={expandIcon}>
      {children}
    </AntCollapse>
  )
}

Collapse.Panel = CollapseItem

export default Collapse
