import { RuleObject } from 'antd/lib/form'

export const MIN_LEN_PASSWORD = 8

export const onlyRuOrEnCharsValidator = (rule: RuleObject, value: string): Promise<void> => {
  const regex = new RegExp(/^[а-яА-ЯёЁa-zA-Z-]*$/)
  return regex.test(value) ? Promise.resolve() : Promise.reject('Нельзя использовать спецсимволы и цифры')
}

export const emailValidator = (rule: RuleObject, value: string): Promise<void> => {
  const regex = new RegExp(/^.+@([A-zА-Яа-я0-9][-A-zА-Яа-я0-9]+\.)+[A-zА-я]{2,4}$/)
  return regex.test(value)
    ? Promise.resolve()
    : Promise.reject('Электронный адрес введен некорректно (например name@domain.ru)')
}

export const emailRecoveryValidator = async (rule: RuleObject, value: string): Promise<void> => {
  await emailValidator(rule, value)
  if (value.substring(value.length - 4) === '.com') {
    return Promise.reject(
      'Для пользователей с доменной зоной «.com» (например gmail.com, egypt.com и др) необходимо заполнить обращение через форму обратной связи. Мы обработаем в течение одного рабочего дня.',
    )
  }
}

export const emailRuValidator = async (rule: RuleObject, value: string): Promise<void> => {
  await emailValidator(rule, value)
  if (value.substring(value.length - 3) !== '.ru') {
    return Promise.reject('Используйте доменную зону «.ru» (например, rosenergoatom.ru, yandex.ru, mail.ru и пр.)')
  }
}

export const oneRuOrEnLowerCharValidator = (rule: RuleObject, value: string): Promise<void> => {
  const oneLower = /[a-zа-я]/.test(value)
  if (value.length >= MIN_LEN_PASSWORD && !oneLower) {
    return oneLower ? Promise.resolve() : Promise.reject('Минимум одна строчная буква a-z или а-я.')
  }

  return Promise.resolve()
}

export const oneRuOrEnUpperCharValidator = (rule: RuleObject, value: string): Promise<void> => {
  const oneUpper = /[A-ZА-Я]/.test(value)
  if (value.length >= MIN_LEN_PASSWORD && !oneUpper) {
    return Promise.reject('Минимум одна заглавная буква A-Z или А-Я.')
  }

  return Promise.resolve()
}

export const oneDigitCharValidator = (rule: RuleObject, value: string): Promise<void> => {
  const oneDigit = /\d/.test(value)
  if (value.length >= MIN_LEN_PASSWORD && !oneDigit) {
    return Promise.reject('Минимум одна цифра.')
  }

  return Promise.resolve()
}

export const validatorForBirthDate = (rule: RuleObject, value: string): Promise<void> => {
  if (value) {
    const pathDate = value.split('-')
    const day = pathDate[0]
    const month = pathDate[1]
    const year = pathDate[2]
    if (parseInt(day) > 31) return Promise.reject('Некорректно указан день')
    if (parseInt(month) > 12) return Promise.reject('Некорректно указан месяц')

    const isNotFutureAndNotLess1900 = () => {
      const currDate = new Date(`${year}-${month}-${day}`)
      const dateInFuture = currDate > new Date()
      const dateLess1900Year = currDate < new Date('1900-01-01')
      return dateInFuture || dateLess1900Year
    }

    if (isNotFutureAndNotLess1900()) return Promise.reject('Некорректно указана дата')
  }
  return Promise.resolve()
}

export const validatorForBirthDateWithDot = (rule: RuleObject, value: string): Promise<void> => {
  if (value) {
    const date = new Date(value.toString())

    const day = date.getDate()
    const month = date.getMonth() + 1

    if (day > 31) return Promise.reject('Некорректно указан день')
    if (month > 12 || month === 0) return Promise.reject('Некорректно указан месяц')

    if (date > new Date()) return Promise.reject('День рождения не может быть в будущем')
    if (date < new Date('01.01.1900')) return Promise.reject('Вам слишком много лет')
  } else {
    return Promise.reject('Это поле не должно быть пустым')
  }
  return Promise.resolve()
}
