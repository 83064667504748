import { useSelector } from 'react-redux'
import { SearchGroup } from '../../../../../api/model/models'
import { RootState } from '../../../../../store/store'

type UseResultsFieldGlobalSearch = () => {
  state: {
    groups: SearchGroup[] | undefined
  }
}

const useResultsFieldGlobalSearch: UseResultsFieldGlobalSearch = () => {
  const resultSearch = useSelector<RootState, SearchGroup[] | undefined>((state) => state.globalSearch?.resultSearch)
  const resultSearchByGroup = useSelector<RootState, SearchGroup | undefined>(
    (state) => state.globalSearch.resultSearchByGroup,
  )

  return {
    state: {
      groups: resultSearchByGroup ? [resultSearchByGroup] : resultSearch,
    },
  }
}

export default useResultsFieldGlobalSearch
