import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'

export type Participant = {
  registerDate: string
  id: number
  fullName: string
  avatar?: string
  birthDate: string
  phone: string
  email: string
  competence: string
  competenceRole: string
  company: string
  position: string
}

export type ParticipantsState = {
  participants: Array<Participant>
  resultSearch?: Array<Participant>
  loading: boolean
}

export const initialParticipantsState: ParticipantsState = { participants: [], resultSearch: undefined, loading: false }

const fetchParticipants = createAsyncThunk(
  'fetchParticipants',
  async (payload: GetParticipantsPayload, { rejectWithValue }) => {
    try {
      return appAPI.participants.getParticipants(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const searchParticipants = createAsyncThunk(
  'searchParticipants',
  async (payload: SearchParticipantsPayload, { rejectWithValue }) => {
    try {
      return appAPI.participants.searchParticipants(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const participantsSlice = createSlice({
  name: 'participants',
  initialState: initialParticipantsState,
  reducers: {
    clearResultSearch: (state) => {
      state.resultSearch = initialParticipantsState.resultSearch
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParticipants.pending, (state: ParticipantsState) => {
        state.loading = true
      })
      .addCase(fetchParticipants.fulfilled, (state: ParticipantsState, { payload }) => {
        state.participants = payload.data
        state.loading = false
      })
      .addCase(fetchParticipants.rejected, (state: ParticipantsState) => {
        state.loading = false
      })

      .addCase(searchParticipants.pending, (state: ParticipantsState) => {
        state.loading = true
      })
      .addCase(searchParticipants.fulfilled, (state: ParticipantsState, { payload }) => {
        state.resultSearch = payload.data
        state.loading = false
      })
      .addCase(searchParticipants.rejected, (state: ParticipantsState) => {
        state.loading = false
      })
  },
})

export default participantsSlice.reducer

export const participantsSliceActions = {
  ...participantsSlice.actions,
  fetchParticipants,
  searchParticipants,
}
