import React, { FC, ReactInstance, ReactNode, ReactText } from 'react'
import { Button } from '../index'
import { Dropdown as AntDropdown } from 'antd'
import { Menu as AntMenu } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import Option, { IOptionProps } from './option'
import { MenuProps } from 'antd/lib/menu'
import './dropdown.ant.scss'
import { DropDownProps as AntDropdownProps } from 'antd/lib/dropdown'

type _DropdownProps = {
  icon?: ReactNode
  title?: string
  type: ButtonProps['type']
  onItemClick?: (key: string, value: string) => void
  className?: string
  classNameButton?: string
} & Partial<AntDropdownProps>

const Dropdown: FC<_DropdownProps> & { Option: FC<IOptionProps> } = (props) => {
  const { icon, title, type, children, onItemClick, className = '', classNameButton = '', ...rest } = props

  const handleChange = (e: { key: ReactText; item: (ReactInstance & { props?: MenuProps }) | undefined }) => {
    onItemClick && onItemClick(e.key.toString(), (e.item?.props as IOptionProps).value || '')
  }

  return (
    <AntDropdown
      overlayClassName={[className, 'reaskills-dropdown'].join(' ')}
      overlay={<AntMenu onClick={handleChange}>{children}</AntMenu>}
      {...rest}
    >
      <Button type={type} className={classNameButton}>
        {title}
        {icon}
      </Button>
    </AntDropdown>
  )
}

Dropdown.Option = Option

export default Dropdown
