import React, { FC } from 'react'
import { Popover } from '../../common'
import Statistic from '../../common/icons/statistic'
import { PopoverProps } from 'antd'
import StatisticPopoverTitle from './statistic-popover-title/statistic-popover-title'
import StatisticPopoverContent from './statistic-popover-content/statistic-popover-content'
import { DataTypeArr, statisticTypes } from './types/statisticType'
import { useColumns } from './hooks/useColumns'
import './statistic-popover.ant.scss'
import { useSubtitle } from './hooks/useSubtitle'

interface StatisticPopoverProps {
  statisticType: statisticTypes
  statisticName?: string
  data?: DataTypeArr
}

const StatisticPopover: FC<PopoverProps & StatisticPopoverProps> = (props) => {
  const { className, title, statisticType, statisticName, data } = props

  const columns = useColumns({ statisticType })
  const subtitle = useSubtitle({ statisticType, statisticName, count: data?.length ?? 0 })

  return (
    <Popover
      overlayClassName={statisticType}
      title={<StatisticPopoverTitle title={title} subtitle={subtitle} />}
      content={<StatisticPopoverContent columns={columns} data={data} />}
      trigger="click"
      placement="leftTop"
      className={className}
    >
      <Statistic />
    </Popover>
  )
}

export default StatisticPopover
