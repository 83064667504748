import { useSelector } from 'react-redux'
import { useAppDispatch, RootState } from '../../../store/store'
import { globalSearchActions } from '../../../store/slices/globalSearchSlice'

type UseGlobalSearchProps = () => {
  state: { isShowModal: boolean }
  methods: {
    hideModal: () => void
    showModal: () => void
  }
}

export const useGlobalSearch: UseGlobalSearchProps = () => {
  const dispatch = useAppDispatch()
  const isShowModal = useSelector<RootState, boolean>((state) => state.globalSearch.isShowModal)

  const hideModal = () => dispatch(globalSearchActions.hideModal())
  const showModal = () => dispatch(globalSearchActions.showModal())

  return {
    state: {
      isShowModal,
    },
    methods: {
      hideModal,
      showModal,
    },
  }
}
