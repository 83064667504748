import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ForumTopicCreate } from '../../api/model/forumTopicCreate'
import { TopicUpdateByAuthor } from '../../api/model/topicUpdateByAuthor'
import { CommentAttachmentList } from '../../api/model/commentAttachmentList'
import { ForumGroup } from '../../api/model/forumGroup'
import appAPI from '../../api/api'

export type SectionType = 'file' | 'privacy' | 'all'

export type TopicCRUDState = {
  title: string
  description: string
  attachments: Array<CommentAttachmentList>
  pinToTop: boolean
  closedDiscussion: boolean
  activeOptions: Array<SectionType>
  sendingData: boolean
  firstCommentId?: number
  fetchingData: boolean
  successOperation: boolean
  createdCommentId?: number
  mobileMenu: boolean
  checkedForumGroups: Array<number>
  currentOption: string
  fetchedForumGroups: Array<ForumGroup>
}

export const initialTopicCRUDState: TopicCRUDState = {
  title: '',
  description: '',
  attachments: [],
  pinToTop: false,
  closedDiscussion: false,
  activeOptions: [],
  sendingData: false,
  firstCommentId: undefined,
  fetchingData: false,
  successOperation: false,
  mobileMenu: false,
  checkedForumGroups: [],
  currentOption: '',
  fetchedForumGroups: [],
}

const fetchTopicData = createAsyncThunk('fetchTopicData', async (payload: { id: number }, { rejectWithValue }) => {
  try {
    return await appAPI.forumTopic.getTopic(payload.id)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const createTopic = createAsyncThunk('createTopic', async (payload: Partial<ForumTopicCreate>, { rejectWithValue }) => {
  try {
    return await appAPI.forumTopic.postTopic(payload)
  } catch (err: any) {
    console.error('ERROR IN CREATE TOPIC:', err.response)
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const updateTopic = createAsyncThunk(
  'updateTopic',
  async (payload: { idTopic: number; firstCommentId: number } & TopicUpdateByAuthor, { rejectWithValue }) => {
    try {
      const { idTopic, ...restPayload } = payload
      return await appAPI.forumTopic.putTopic(idTopic, restPayload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const fetchGroups = createAsyncThunk('fetchGroups', async (payload: GetForumGroupsPayload, { rejectWithValue }) => {
  try {
    return await appAPI.forumGroup.getGroups(payload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const topicCRUDSlice = createSlice({
  name: 'topicCRUD',
  initialState: initialTopicCRUDState,
  reducers: {
    setTitle: (state: TopicCRUDState, { payload }: PayloadAction<string>) => {
      if (payload.length <= 150) state.title = payload
    },
    setDescription: (state: TopicCRUDState, { payload }: PayloadAction<string>) => {
      state.description = payload
    },
    setPinToTop: (state: TopicCRUDState, { payload }: PayloadAction<boolean>) => {
      state.pinToTop = payload
    },
    setClosedDiscussion: (state: TopicCRUDState, { payload }: PayloadAction<boolean>) => {
      state.closedDiscussion = payload
    },
    setActiveOptions: (state: TopicCRUDState, { payload }: PayloadAction<Array<SectionType>>) => {
      state.activeOptions = payload
    },
    addActiveOption: (state: TopicCRUDState, { payload }: PayloadAction<SectionType>) => {
      if (!state.activeOptions.includes(payload)) {
        state.activeOptions = [...state.activeOptions, payload]
      }
    },
    setFirstCommentID: (state: TopicCRUDState, { payload }: PayloadAction<number>) => {
      state.firstCommentId = payload
    },
    setMobileMenu: (state: TopicCRUDState, { payload }: PayloadAction<boolean>) => {
      state.mobileMenu = payload
    },
    setForumGroup: (state: TopicCRUDState, { payload }: PayloadAction<Array<number>>) => {
      state.checkedForumGroups = payload
    },
    addForumGroup: (state: TopicCRUDState, { payload }: PayloadAction<number>) => {
      state.checkedForumGroups = [...state.checkedForumGroups, payload]
    },
    removeForumGroup: (state: TopicCRUDState, { payload }: PayloadAction<number>) => {
      state.checkedForumGroups = state.checkedForumGroups.filter((group: number) => group !== payload)
    },
    clearForumGroup: (state: TopicCRUDState) => {
      state.checkedForumGroups = []
    },
    setCurrentOption: (state: TopicCRUDState, { payload }: PayloadAction<string>) => {
      state.currentOption = payload
    },
    clearCreatedCommentId: (state) => {
      state.createdCommentId = undefined
    },
    clearTopicCRUD: () => initialTopicCRUDState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTopic.pending, (state: TopicCRUDState) => {
        state.sendingData = true
        state.createdCommentId = undefined
      })
      .addCase(createTopic.fulfilled, (state: TopicCRUDState, { payload }) => {
        state.sendingData = false
        state.successOperation = true
        state.createdCommentId = payload.data.firstComment.id
      })
      .addCase(createTopic.rejected, (state: TopicCRUDState) => {
        state.sendingData = false
        state.createdCommentId = undefined
      })
      .addCase(updateTopic.pending, (state: TopicCRUDState) => {
        state.sendingData = true
        state.createdCommentId = undefined
      })
      .addCase(updateTopic.fulfilled, (state: TopicCRUDState, { meta }) => {
        state.sendingData = false
        state.successOperation = true
        state.createdCommentId = meta.arg.firstCommentId
      })
      .addCase(updateTopic.rejected, (state: TopicCRUDState) => {
        state.sendingData = false
        state.createdCommentId = undefined
      })
      .addCase(fetchTopicData.pending, (state: TopicCRUDState) => {
        state.fetchingData = true
      })
      .addCase(fetchTopicData.fulfilled, (state: TopicCRUDState, { payload }) => {
        state.fetchingData = false
        const data = payload.data
        state.title = data.title
        state.attachments = data.firstComment.attachments
        state.firstCommentId = data.firstComment.id
        state.description = data.firstComment?.message || ''
        state.pinToTop = data.isPinned || false
        state.closedDiscussion = data.isClosed || false
        state.fetchedForumGroups = data.forumGroups.filter((group: ForumGroup) => group.name !== 'public')
        state.currentOption = data.forumGroups.find((group) => group.name === 'public') ? 'public' : 'optional'
      })
      .addCase(fetchTopicData.rejected, (state: TopicCRUDState) => {
        state.fetchingData = false
      })
  },
})

export default topicCRUDSlice.reducer

export const topicCRUDSliceActions = {
  ...topicCRUDSlice.actions,
  createTopic,
  updateTopic,
  fetchTopicData,
  fetchGroups,
}
