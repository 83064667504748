import React from 'react'

const BarChart: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 8C7.10457 8 8 8.89543 8 10V18C8 19.1046 7.10457 20 6 20C4.89543 20 4 19.1046 4 18V10C4 8.89543 4.89543 8 6 8ZM18 11C19.1046 11 20 11.8954 20 13V18C20 19.1046 19.1046 20 18 20C16.8954 20 16 19.1046 16 18V13C16 11.8954 16.8954 11 18 11ZM12 2C13.1046 2 14 2.89543 14 4V18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18V4C10 2.89543 10.8954 2 12 2Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default BarChart
