import { useState } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { captchaActions } from '../store/slices/captchaSlice'

const useNeedCaptcha = () => {
  const [needCaptcha, setNeedCaptcha] = useState(false)
  const dispatch = useDispatch()

  const checkCaptchaResponse = (
    response: PayloadAction<unknown, string, { payload: void; type: string }, { message: string }>,
    callback?: () => void,
  ) => {
    if (!response.type.endsWith('/rejected')) {
      setNeedCaptcha(false)
      dispatch(captchaActions.clear())
      return false
    }

    if (needCaptcha && response.type.endsWith('/rejected')) {
      dispatch(captchaActions.fetchCaptcha())
      return true
    }

    if (!response.payload) return
    if ((response.payload as { status: number }).status === 429) {
      setNeedCaptcha(true)
      callback && callback()
      return true
    }

    return false
  }

  return { needCaptcha, checkCaptchaResponse }
}

export default useNeedCaptcha
