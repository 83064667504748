import React from 'react'

const Briefcase: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4 21C3.45 21 2.97933 20.8043 2.588 20.413C2.196 20.021 2 19.55 2 19L2 8C2 7.45 2.196 6.97933 2.588 6.588C2.97933 6.196 3.45 6 4 6L8 6V4C8 3.45 8.196 2.979 8.588 2.587C8.97933 2.19567 9.45 2 10 2L14 2C14.55 2 15.021 2.19567 15.413 2.587C15.8043 2.979 16 3.45 16 4V6L20 6C20.55 6 21.021 6.196 21.413 6.588C21.8043 6.97933 22 7.45 22 8L22 19C22 19.55 21.8043 20.021 21.413 20.413C21.021 20.8043 20.55 21 20 21L4 21ZM10 6H14V4L10 4V6ZM20 15L15 15V17H9L9 15L4 15L4 19L20 19V15ZM11 15H13V13H11V15ZM4 13L9 13V11H15V13L20 13V8L4 8L4 13Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Briefcase
