import React, { FC, useState } from 'react'
import { Select as AntSelect } from 'antd'
import { SelectProps } from 'antd/lib/select'

import { ArrowDown, SearchIcon } from '../../common/icons'
import './select.ant.scss'

const Select: FC<SelectProps<string>> = (props) => {
  const [icon, setIcon] = useState('arrow')

  // default filter for selector if search is available
  const filterOption = (input: string, option: Record<string, string> | undefined): boolean => {
    return option?.children?.toLowerCase().includes(input.toLowerCase()) || false
  }

  const setIconSearch = () => {
    setIcon('search')
  }

  const setIconArrow = () => {
    setIcon('arrow')
  }

  return (
    <AntSelect
      filterOption={filterOption}
      className={props.className}
      onInputKeyDown={setIconSearch}
      onDropdownVisibleChange={setIconSearch}
      onBlur={setIconArrow}
      suffixIcon={icon === 'arrow' ? ArrowDown : <SearchIcon size={12} />}
      notFoundContent={<div>Совпадений не найдено</div>}
      {...props}
    />
  )
}

export const { OptGroup, Option } = AntSelect
export default Select
