import React from 'react'

const Plus: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0001 12.4999C20.0001 13.0522 19.5523 13.5 19.0001 13.5L13 13.4999L13 19.5C13 20.0523 12.5523 20.5 12 20.5C11.4477 20.5 11 20.0523 11 19.5L11 13.4999L5.00005 13.4999C4.44777 13.4999 4.00005 13.0522 4.00005 12.4999C4.00005 11.9477 4.44777 11.4999 5.00005 11.4999L11 11.4999L11 5.49997C11 4.94768 11.4477 4.49997 12 4.49997C12.5523 4.49997 13 4.94768 13 5.49997L13 11.4999L19.0001 11.4999C19.5523 11.4999 20.0001 11.9477 20.0001 12.4999Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Plus
