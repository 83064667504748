import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SerializedError } from '@reduxjs/toolkit'

export type AlertState = {
  message: string[] | string | undefined
  severity: AlertSeverity | undefined
}

export declare type ActionAddMatcher = PayloadAction<
  unknown,
  string,
  { arg: string; requestId: string; aborted: boolean; condition: boolean },
  SerializedError
>

const initialStateAlert: AlertState = {
  message: undefined,
  severity: undefined,
}

const MESSAGES = {
  tooManyRequests: 'Вы превысили допустимое число запросов. Пожалуйста, введите код с картинки.',
  wrongCredentials: 'Активная учетная запись с указанными учетными данными не найдена.',
  notFound: 'Данные не найдено.',
}

const alertSlice = createSlice({
  name: 'alert',
  initialState: initialStateAlert,
  reducers: {
    pushAlert: (state, action: PayloadAction<Alert>) => {
      state.message = action.payload.message
      state.severity = action.payload.severity
    },
    clearAlert: () => {
      return initialStateAlert
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action): action is ActionAddMatcher => {
          return (
            action.type === 'registrationApplication/rejected' ||
            action.type === 'fetchResetPassword/rejected' ||
            action.type === 'fetchPasswordConfirm/rejected' ||
            action.type === 'sendFeedback/rejected' ||
            action.type === 'sendDivisionExpertFeedback/rejected' ||
            action.type === 'fetchLogin/rejected' ||
            action.type === 'setTopic/rejected' ||
            action.type === 'fetchTopicsAccumulate/rejected' ||
            action.type === 'fetchTopics/rejected' ||
            action.type === 'createTopic/rejected' ||
            action.type === 'updateTopic/rejected' ||
            action.type === 'fetchInfo/rejected' ||
            action.type === 'createAttachments/rejected' ||
            action.type === 'setUserProfile/rejected' ||
            action.type === 'fetchUserProfile/rejected' ||
            action.type === 'setPasswordUserProfile/rejected' ||
            action.type === 'setAvatarUserProfile/rejected' ||
            action.type === 'fetchForumSearch/rejected' ||
            action.type === 'fetchProfileCardById/rejected' ||
            action.type === 'createTopicPoll/rejected' ||
            action.type === 'createTopicPollVote/rejected'
          )
        },
        (state, { payload }) => {
          if (!payload) return
          const { status, messages } = payload as { messages: unknown; status: number }
          if (status === 429) {
            state.severity = 'warning'
            state.message = MESSAGES.tooManyRequests
            return
          }
          if (
            status === 401 &&
            ((messages as Record<string, string>['detail']) === 'No active account found with the given credentials' ||
              (messages as Record<string, string>['detail']) === 'User is inactive')
          ) {
            state.severity = 'error'
            state.message = MESSAGES.wrongCredentials
            return
          }
          const alertMessages = []
          if (Array.isArray(messages)) {
            alertMessages.push(...messages)
          } else if (typeof messages === 'object') {
            Object.values(messages as Record<string, string>).forEach((message) => {
              if (Array.isArray(message)) {
                alertMessages.push(...message)
              } else {
                alertMessages.push(message)
              }
            })
          }
          state.severity = 'error'
          state.message = alertMessages
        },
      )
      .addMatcher(
        (action): action is ActionAddMatcher => {
          return action.type === 'setTopic/fulfilled'
        },
        (state) => {
          state.severity = 'success'
          state.message = `Тема успешно изменена`
        },
      )
  },
})

export default alertSlice.reducer

export const alertActions = alertSlice.actions
