import React, { FC } from 'react'

type FileProps = {
  format?: string
  className?: string
}

const File: FC<FileProps> = ({ format, className }) => {
  if (format)
    return (
      <svg role="img" className={className} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.5 28C21.0523 28 21.5 28.4477 21.5 29C21.5 29.5523 21.0523 30 20.5 30L20.5 28ZM21 7L21 6C21.2652 6 21.5196 6.10536 21.7071 6.29289L21 7ZM27 13L27.7071 12.2929C27.8946 12.4804 28 12.7348 28 13L27 13ZM28 16C28 16.5523 27.5523 17 27 17C26.4477 17 26 16.5523 26 16L28 16ZM20.5 30L10 30L10 28L20.5 28L20.5 30ZM7 27L7 9L9 9L9 27L7 27ZM10 6L21 6L21 8L10 8L10 6ZM21.7071 6.29289L27.7071 12.2929L26.2929 13.7071L20.2929 7.70711L21.7071 6.29289ZM28 13L28 16L26 16L26 13L28 13ZM27 14L23 14L23 12L27 12L27 14ZM20 11L20 7L22 7L22 11L20 11ZM7 9C7 7.34315 8.34314 6 10 6L10 8C9.44771 8 9 8.44772 9 9L7 9ZM23 14C21.3431 14 20 12.6569 20 11L22 11C22 11.5523 22.4477 12 23 12L23 14ZM10 30C8.34315 30 7 28.6569 7 27L9 27C9 27.5523 9.44772 28 10 28L10 30Z"
          fill="black"
        />
        <text x={30} y={25} fill="black" fontSize={8} fontWeight={'bold'} textAnchor={'end'}>
          .{format.length <= 6 ? (format === 'pdf' ? 'PDF' : format) : format.slice(0, 6)}
        </text>
      </svg>
    )
  else
    return (
      <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.25 3.75294H7.47035C6.2693 3.75294 5.30116 4.68053 5.30116 5.81765V17.5824C5.30116 18.7195 6.2693 19.6471 7.47035 19.6471H16.6797C17.8807 19.6471 18.8488 18.7195 18.8488 17.5824V10.9H16.575C14.7387 10.9 13.25 9.41135 13.25 7.575V3.75294ZM15.025 7.95C15.025 8.59893 15.5511 9.125 16.2 9.125H18.5165L15.025 5.15485V7.95ZM3.5 5.81765C3.5 3.70603 5.28062 2 7.47035 2H13.391C14.1708 2 14.9118 2.33022 15.4167 2.90423L20.0209 8.13974C20.4268 8.60129 20.65 9.1869 20.65 9.79265V17.5824C20.65 19.694 18.8694 21.4 16.6797 21.4H7.47035C5.28062 21.4 3.5 19.694 3.5 17.5824V5.81765Z"
          fill="#309BEC"
        />
      </svg>
    )
}

export default File
