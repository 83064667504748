import React, { FC } from 'react'

const Emoji: FC = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14C26 20.6274 20.6274 26 14 26ZM14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24ZM9.612 18.0915C9.23534 17.6876 9.25744 17.0548 9.66135 16.6781C10.0653 16.3015 10.698 16.3236 11.0747 16.7275C12.5813 18.3432 15.1125 18.4315 16.7281 16.9249C16.7962 16.8614 16.8619 16.7959 16.925 16.7284C17.3022 16.3251 17.9351 16.3039 18.3384 16.6812C18.7417 17.0585 18.7629 17.6913 18.3856 18.0946C18.2914 18.1953 18.1935 18.293 18.0921 18.3876C15.6686 20.6476 11.8719 20.515 9.612 18.0915ZM17.375 13.25C16.4775 13.25 15.75 12.5225 15.75 11.625C15.75 10.7275 16.4775 10 17.375 10C18.2725 10 19 10.7275 19 11.625C19 12.5225 18.2725 13.25 17.375 13.25ZM10.625 13.25C9.72754 13.25 9 12.5225 9 11.625C9 10.7275 9.72754 10 10.625 10C11.5225 10 12.25 10.7275 12.25 11.625C12.25 12.5225 11.5225 13.25 10.625 13.25Z"
        fill="#99A2AD"
      />
    </svg>
  )
}

export default Emoji
