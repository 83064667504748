import React from 'react'

const Globe: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0218 20.2686C8.94273 19.4313 8.06014 17.9297 7.53304 16.05H9.53304C10.0565 17.9167 10.9305 19.4105 11.9994 20.2511C13.0683 19.4105 13.9423 17.9167 14.4658 16.05H16.4658C15.9386 17.93 15.0558 19.4318 13.9765 20.269C16.351 19.7035 18.3392 18.1418 19.475 16.05H4.52501C5.66048 18.1413 7.64801 19.7028 10.0218 20.2686ZM3.93777 14.7H7.22829C7.07957 13.8476 6.9994 12.9407 6.9994 12C6.9994 11.2231 7.05409 10.4691 7.15692 9.74995H3.801C3.60477 10.4666 3.5 11.2211 3.5 12C3.5 12.9438 3.65383 13.8517 3.93777 14.7ZM4.29779 8.39995H7.41545C7.92267 6.31038 8.85794 4.6345 10.0218 3.73141C7.48071 4.33711 5.38225 6.08377 4.29779 8.39995ZM20.0622 14.7C20.3462 13.8517 20.5 12.9438 20.5 12C20.5 11.2211 20.3952 10.4666 20.199 9.74995H16.8419C16.9447 10.4691 16.9994 11.2231 16.9994 12C16.9994 12.9407 16.9192 13.8476 16.7705 14.7H20.0622ZM19.7022 8.39995C18.6175 6.08324 16.5183 4.33631 13.9765 3.731C15.1406 4.63398 16.076 6.31006 16.5833 8.39995H19.7022ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM14.7705 14.7C14.9192 13.8476 14.9994 12.9407 14.9994 12C14.9994 11.2231 14.9447 10.4691 14.8419 9.74995H9.15692C9.05409 10.4691 8.9994 11.2231 8.9994 12C8.9994 12.9407 9.07957 13.8476 9.22829 14.7H14.7705ZM11.9994 3.74888C10.846 4.656 9.91941 6.32378 9.41545 8.39995H14.5833C14.0794 6.32378 13.1528 4.656 11.9994 3.74888Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Globe
