export const validateMessages = {
  /* eslint-disable */
  required: '${label} обязательное поле!',
  types: {
    email: '${label} введен некорректно (например name@domain.ru)',
    number: '${label} должен быть числом',
  },
  whitespace: '${label} не может быть пустым!',
  string: {
    max: 'Поле "${label}" не может быть длиннее ${max} символов',
    min: 'Поле "${label}" должно быть не менее ${min} символов',
  },
}
