import React, { FC, useState, useEffect, useRef, SyntheticEvent, ReactElement } from 'react'
import { Carousel, Modal, Skeleton } from 'antd'

import styles from './popup-gallery.module.scss'
import './popup-gallery.ant.scss'
import ImageGallery, { PropsImageGallery } from './image/image-gallery'
import VideoGallery from './video/video-gallery'

import { SliderArrowRight, SliderArrowLeft, Close } from '../../common/icons'

interface PopupGalleryProps {
  label?: string
  visible: boolean
  startSlide?: number
  totalSlides?: number
  onCancel?: () => void
  onLoad?: (flagOnLoad?: string) => void | undefined
  inAlbum?: boolean
}

interface IPopupGallery extends FC<PopupGalleryProps> {
  Image: typeof ImageGallery
  Video: typeof VideoGallery
}

const PopupGallery: IPopupGallery = (props) => {
  const { label, visible, onCancel, startSlide = 0, onLoad, totalSlides, children, inAlbum } = props
  const boxForSliderRef = useRef<HTMLElement | null>()

  const [currentSlide, setCurrentSlide] = useState(startSlide)
  const [downloadRequest, setDownloadRequest] = useState(false)
  const [countSlide, setCountSlide] = useState(React.Children.count(children))

  useEffect(() => {
    // we update the start page with the state. Needed when re-rendering and changing the start
    setCurrentSlide(startSlide)
  }, [startSlide, setCurrentSlide])

  useEffect(() => {
    const countChildren = React.Children.count(children)
    if (countSlide !== countChildren) {
      setDownloadRequest(false)
      setCountSlide(countChildren)
    }
  }, [children, setDownloadRequest, countSlide])

  useEffect(() => {
    if (
      onLoad &&
      totalSlides &&
      countSlide &&
      countSlide < totalSlides &&
      currentSlide + 4 >= countSlide &&
      !downloadRequest
    ) {
      setDownloadRequest(true)
      onLoad()
    }
  }, [setDownloadRequest, onLoad, totalSlides, countSlide, currentSlide, downloadRequest])

  const handleChangeSlide = (from: number, to: number) => setCurrentSlide(to)

  const handleClickPrev = (e: SyntheticEvent) => {
    if (currentSlide === 0) e.stopPropagation()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'ArrowLeft' && currentSlide === 0) e.stopPropagation()
  }

  const handleTouchStart = (event: React.TouchEvent<HTMLElement>) => {
    const startPointX = event.changedTouches[0].pageX
    event.target.addEventListener(
      'touchend',
      (event: any) => {
        const endPointX = event.changedTouches[0].pageX
        if (endPointX - startPointX > 0 && currentSlide === 0) {
          event.stopPropagation()
        }
      },
      { once: true, capture: true },
    )
  }

  // solving the problem with timeline and volume sound in firefox
  useEffect(() => {
    let slickListHTMLElement: HTMLElement | null
    const callback = (e: MouseEvent) => {
      e.stopPropagation()
    }

    if (boxForSliderRef.current && visible) {
      slickListHTMLElement = boxForSliderRef.current.querySelector('.slick-list')
      slickListHTMLElement?.addEventListener('mouseup', callback)
    }

    return () => {
      slickListHTMLElement?.removeEventListener('mouseup', callback)
    }
  }, [visible, totalSlides, countSlide, currentSlide, downloadRequest])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={null}
      className={'popup-gallery'}
      wrapClassName={styles.popupGalleryMask}
      maskClosable={false}
      closeIcon={<Close color={'#ffffff'} size={44} />}
    >
      <header className={styles.header}>
        <div className={`${styles.boxSpan} ${styles.headerTitle} ${inAlbum ? '' : styles.headerTitleFullwidth}`}>
          <span className={`${styles.album} ${inAlbum ? '' : styles.albumFullwidth}`}>{label}</span>
          {inAlbum && (
            <>
              <span className={styles.delimiter} />
              <span className={styles.total}>{totalSlides} медиа</span>
            </>
          )}
        </div>
        {inAlbum && (
          <div className={styles.boxSpan}>
            <span className={styles.navigation}>
              {currentSlide + 1} из {totalSlides}
            </span>
          </div>
        )}
      </header>
      <section
        ref={(section) => (boxForSliderRef.current = section)}
        onTouchStartCapture={handleTouchStart}
        onKeyDownCapture={handleKeyDown}
      >
        <Carousel
          className={currentSlide === 0 ? 'removePrevArrow' : ''}
          initialSlide={countSlide ? currentSlide : 0}
          dots={false}
          arrows={true}
          fade={true}
          swipe={true}
          beforeChange={handleChangeSlide}
          nextArrow={
            <SlickButton>
              <SliderArrowRight color="#ffffff" />
            </SlickButton>
          }
          prevArrow={
            <SlickButton>
              <SliderArrowLeft color="#ffffff" onClick={handleClickPrev} />
            </SlickButton>
          }
        >
          {countSlide ? (
            React.Children.map(children, (child, index) => {
              if (child) {
                return React.cloneElement(child as ReactElement<PropsImageGallery>, {
                  visible: currentSlide === index,
                })
              }
            })
          ) : (
            <div className={styles.boxSkeletonImage}>
              <Skeleton.Image />
            </div>
          )}
        </Carousel>
      </section>
    </Modal>
  )
}

type PropsSlickButton = {
  children: JSX.Element
  currentSlide?: number
  slideCount?: number
}

const SlickButton: FC<PropsSlickButton> = ({ currentSlide, slideCount, children, ...props }) => {
  // in Ant, HTML attribute is filled incorrectly due to which an error occurs
  // error: React does not recognize the `slideCount` prop on a DOM element
  // so we create a wrapper and pass only the required properties
  // the conflict field is not passed and therefore does not appear in the DOM
  return <div {...props}>{children}</div>
}

PopupGallery.defaultProps = {
  startSlide: 0,
  totalSlides: 0,
  visible: false,
  label: 'Альбом',
}

PopupGallery.Image = ImageGallery
PopupGallery.Video = VideoGallery
export default PopupGallery
