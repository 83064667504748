import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { Page } from '../../api/model/models'

type pageState = {
  page: Page | null
  loading: boolean
}

const initialStaticPageState: pageState = {
  page: null,
  loading: false,
}

const fetchStaticPage = createAsyncThunk('/fetchStaticPage', async (payload: { slug: string }, { rejectWithValue }) => {
  try {
    return await appAPI.main.getPage(payload.slug)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const staticPageSlice = createSlice({
  name: 'page',
  initialState: initialStaticPageState,
  reducers: {
    clearStaticPage: () => initialStaticPageState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStaticPage.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchStaticPage.fulfilled, (state, { payload }) => {
        state.page = payload.data
        state.loading = false
      })
  },
})

export default staticPageSlice.reducer

export const pageSliceActions = {
  ...staticPageSlice.actions,
  fetchStaticPage,
}
