import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, RootState } from '../../../../../store/store'
import { globalSearchActions } from '../../../../../store/slices/globalSearchSlice'
import { SearchGroup } from '../../../../../api/model/searchGroup'

type UseInputGlobalSearch = () => {
  loading: boolean
  hideModal: () => void
  submit: (value: string) => void
  clearResultSearch: () => void
}

const useInputGlobalSearch: UseInputGlobalSearch = () => {
  const dispatch = useAppDispatch()
  const loading = useSelector<RootState, boolean>((state) => state.globalSearch.loading)
  const resultSearch = useSelector<RootState, SearchGroup[] | undefined>((state) => state.globalSearch.resultSearch)
  const submit = (value: string) => {
    dispatch(globalSearchActions.setCurrentExpression(value))
    dispatch(globalSearchActions.fetchSearch({ expr: value }))
  }
  const hideModal = () => dispatch(globalSearchActions.hideModal())

  useEffect(() => {
    return () => {
      dispatch(globalSearchActions.clearAll())
    }
  }, [dispatch])

  const clearResultSearch = () => {
    if (resultSearch && resultSearch.length === 0) {
      dispatch(globalSearchActions.clearResultSearch())
    }
  }

  return {
    loading,
    hideModal,
    submit,
    clearResultSearch,
  }
}

export default useInputGlobalSearch
