import React, { FC } from 'react'

type Props = {
  size?: string | number
  color?: string
  className?: string
  onClick?: () => void
}

const Document: FC<Props> = (props) => {
  const { size = '24', className = '', color = '#333333', ...restProps } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M13.25 3.75294H7.47035C6.2693 3.75294 5.30116 4.68053 5.30116 5.81765V17.5824C5.30116 18.7195 6.2693 19.6471 7.47035 19.6471H16.6797C17.8807 19.6471 18.8488 18.7195 18.8488 17.5824V10.9H16.575C14.7387 10.9 13.25 9.41135 13.25 7.575V3.75294ZM15.025 7.95C15.025 8.59893 15.5511 9.125 16.2 9.125H18.5165L15.025 5.15485V7.95ZM3.5 5.81765C3.5 3.70603 5.28062 2 7.47035 2H13.391C14.1708 2 14.9118 2.33022 15.4167 2.90423L20.0209 8.13974C20.4268 8.60129 20.65 9.1869 20.65 9.79265V17.5824C20.65 19.694 18.8694 21.4 16.6797 21.4H7.47035C5.28062 21.4 3.5 19.694 3.5 17.5824V5.81765Z"
        fill={color}
      />
    </svg>
  )
}

export default Document
