import React from 'react'

const House: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 14H14V20H18C18.5523 20 19 19.5523 19 19V12H20.697C20.9732 12 21.197 11.7761 21.197 11.5C21.197 11.3582 21.1369 11.2232 21.0315 11.1283L12.3345 3.30102C12.1443 3.12988 11.8557 3.12988 11.6655 3.30102L2.9685 11.1283C2.76325 11.3131 2.74661 11.6292 2.93134 11.8345C3.02616 11.9398 3.16124 12 3.30298 12H5V19C5 19.5523 5.44772 20 6 20H10V14Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default House
