import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const Pushpin: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1583 0.724265C10.2992 0.0193989 11.1689 -0.243913 11.6772 0.264374L17.7364 6.32359C18.2447 6.83188 17.9814 7.70154 17.2765 7.84251L15.4759 8.20263C15.4132 8.21518 15.3556 8.24602 15.3103 8.29127L12.4302 11.1714C12.3755 11.2261 12.3421 11.2986 12.3362 11.3757L11.9892 15.8872C11.9381 16.5513 11.3583 17.0483 10.6942 16.9972C10.4071 16.9751 10.1374 16.8511 9.93388 16.6475L6.28 12.993L1.5364 17.7372C1.18492 18.0886 0.615076 18.0886 0.263604 17.7372C-0.0585786 17.415 -0.0854272 16.9093 0.183058 16.5565L0.263604 16.4644L5.007 11.72L1.35324 8.06689C0.913652 7.6273 0.884346 6.9328 1.26532 6.45922L1.35324 6.36129C1.55681 6.15772 1.82649 6.03369 2.11354 6.01161L6.62502 5.66457C6.70217 5.65864 6.77465 5.6253 6.82937 5.57059L9.70949 2.69046C9.75474 2.64521 9.78559 2.58758 9.79814 2.52483L10.1583 0.724265ZM11.593 2.72577L11.5632 2.87784C11.4809 3.28905 11.2788 3.66672 10.9823 3.96326L8.10216 6.84338C7.74362 7.20192 7.26864 7.42038 6.76308 7.45927L3.539 7.70677L10.293 14.4608L10.5415 11.2377C10.5755 10.7953 10.747 10.3764 11.0296 10.0383L11.1574 9.8986L14.0375 7.01848C14.334 6.72195 14.7117 6.51982 15.1229 6.43758L15.274 6.40677L11.593 2.72577Z"
        fill={color}
      />
    </svg>
  )
}

Pushpin.defaultProps = {
  size: 18,
  className: '',
  color: '#818C99',
}

export default Pushpin
