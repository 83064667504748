import React, { FC } from 'react'
import { ModalProps } from 'antd/lib/modal'
import { Modal as AntModal } from 'antd'
import './modal.ant.scss'
import { ModalFunc } from 'antd/lib/modal/confirm'

interface ModalComponent extends FC<ModalProps> {
  success: ModalFunc
  info: ModalFunc
  warning: ModalFunc
  error: ModalFunc
  confirm: ModalFunc
  useModal: typeof AntModal.useModal
  destroyAll: typeof AntModal.destroyAll
}

const Modal: ModalComponent = (props) => {
  return <AntModal {...props} />
}

Modal.success = AntModal.success
Modal.info = AntModal.info
Modal.warning = AntModal.warning
Modal.error = AntModal.error
Modal.useModal = AntModal.useModal
Modal.confirm = AntModal.confirm
Modal.destroyAll = AntModal.destroyAll
export default Modal
