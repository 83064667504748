import React, { FC } from 'react'

const Lock: FC = () => {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0.75C4.067 0.75 2.5 2.317 2.5 4.25V5.52399C2.00973 5.55392 1.66191 5.62121 1.36502 5.77248C0.894619 6.01217 0.512167 6.39462 0.272484 6.86502C0 7.3998 0 8.09987 0 9.5V10.5C0 11.9001 0 12.6002 0.272484 13.135C0.512167 13.6054 0.894619 13.9878 1.36502 14.2275C1.8998 14.5 2.59987 14.5 4 14.5H8C9.40013 14.5 10.1002 14.5 10.635 14.2275C11.1054 13.9878 11.4878 13.6054 11.7275 13.135C12 12.6002 12 11.9001 12 10.5V9.5C12 8.09987 12 7.3998 11.7275 6.86502C11.4878 6.39462 11.1054 6.01217 10.635 5.77248C10.3381 5.62121 9.99026 5.55392 9.5 5.52399V4.25C9.5 2.317 7.933 0.75 6 0.75ZM8 5.5V4.25C8 3.14543 7.10457 2.25 6 2.25C4.89543 2.25 4 3.14543 4 4.25V5.5H8ZM2.48015 7.02908C2.82178 7.00117 3.27518 7 4 7H8C8.72482 7 9.17822 7.00117 9.51985 7.02908C9.84397 7.05556 9.9333 7.09845 9.95399 7.10899C10.1422 7.20487 10.2951 7.35785 10.391 7.54601C10.4015 7.5667 10.4444 7.65603 10.4709 7.98015C10.4988 8.32178 10.5 8.77518 10.5 9.5V10.5C10.5 11.2248 10.4988 11.6782 10.4709 12.0198C10.4444 12.344 10.4015 12.4333 10.391 12.454C10.2951 12.6422 10.1422 12.7951 9.95399 12.891C9.9333 12.9015 9.84397 12.9444 9.51985 12.9709C9.17822 12.9988 8.72482 13 8 13H4C3.27518 13 2.82178 12.9988 2.48015 12.9709C2.15603 12.9444 2.0667 12.9015 2.04601 12.891C1.85785 12.7951 1.70487 12.6422 1.60899 12.454C1.59845 12.4333 1.55556 12.344 1.52908 12.0198C1.50117 11.6782 1.5 11.2248 1.5 10.5V9.5C1.5 8.77518 1.50117 8.32178 1.52908 7.98015C1.55556 7.65603 1.59845 7.5667 1.60899 7.54601C1.70487 7.35785 1.85785 7.20487 2.04601 7.10899C2.0667 7.09845 2.15603 7.05556 2.48015 7.02908Z"
        fill="#818C99"
      />
    </svg>
  )
}

export default Lock
