import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { Contact } from '../../api/model/contact'

type ContactsState = {
  contacts: Contact[]
  page: number
  pages: number
  perPage: number
  loading: boolean
}

const initialContactsState: ContactsState = {
  contacts: [],
  page: 1,
  pages: 1,
  perPage: 10,
  loading: false,
}

const fetchContacts = createAsyncThunk('/fetchContacts', async (payload: Paginated) => {
  return await appAPI.contacts.getContacts(payload)
})

const ContactsSlice = createSlice({
  name: 'feedback',
  initialState: initialContactsState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    clearFeedback: (state) => {
      state = initialContactsState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContacts.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchContacts.fulfilled, (state, { payload, meta }) => {
        state.loading = false
        if (meta.arg?.offset !== 0) {
          state.contacts = [...state.contacts, ...(payload.data.results || [])]
        } else {
          state.contacts = payload.data.results || []
        }
        state.pages = payload.data.pages || 1
      })
  },
})

export default ContactsSlice.reducer

export const contactsActions = {
  ...ContactsSlice.actions,
  fetchContacts,
}
