import { useEffect, useState } from 'react'
import { subtitles } from '../constants/subtitles'
import { statisticTypes } from '../types/statisticType'

interface PropsTypes {
  statisticType: statisticTypes
  statisticName: string | undefined
  count: number
}

export const useSubtitle = (props: PropsTypes) => {
  const { statisticType, statisticName, count } = props

  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    const res = subtitles[statisticType](statisticName, count)
    setTitle(res)
  }, [statisticType, statisticName, count])

  return title
}
