import React, { useRef } from 'react'
import { Divider } from 'antd'

import styles from './styles.module.scss'
import { Input } from '../index'
import { ArrowRight, Close, SearchIcon } from '../icons'

type InputSearchProps = {
  value: string | undefined
  placeholder: string
  disabled: boolean
  onSubmit: () => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickClear: () => void
  onClickBackward: () => void
}

const InputSearch: React.FC<InputSearchProps> = (props) => {
  const { value, placeholder, disabled, onSubmit, onChange, onClickClear, onClickBackward } = props

  const searchRef = useRef<HTMLDivElement | null>(null)

  const handleSubmit = () => {
    if (searchRef.current) searchRef.current.focus()
    if (!disabled) {
      onSubmit()
    }
  }

  return (
    <div className={styles.container} ref={searchRef} tabIndex={0}>
      <Input
        className={styles.input}
        disabled={disabled}
        autoFocus
        onPressEnter={handleSubmit}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        maxLength={80}
        prefix={
          <>
            <DisabledOnCondition className={styles.iconHideWrapper} onClick={onClickBackward} disabled={disabled}>
              <ArrowRight className={styles.iconHide} color="#818C99" />
            </DisabledOnCondition>
            <Divider className={styles.divider} type={'vertical'} />
          </>
        }
        suffix={
          <>
            {!!value && (
              <DisabledOnCondition className={styles.iconCloseWrapper} onClick={onClickClear} disabled={disabled}>
                <Close />
              </DisabledOnCondition>
            )}
            <Divider className={styles.divider} type={'vertical'} />
            <SearchIcon className={styles.searchIcon} onClick={handleSubmit} />
          </>
        }
        bordered={false}
      />
    </div>
  )
}

const DisabledOnCondition: React.FC<{ disabled: boolean } & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { disabled, children, className, onClick } = props
  return (
    <div className={[disabled ? styles.disabled : '', className].join(' ')} onClick={disabled ? undefined : onClick}>
      {children}
    </div>
  )
}

export default InputSearch
