import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const LockOutlined: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={size} height={size} rx="12" fill="#309BEC" />
      <path
        d="M12.0002 4.5C14.1779 4.5 16.1668 6.53467 16.1668 8.6912L16.1668 10.3624C17.5803 10.5646 18.6668 11.7802 18.6668 13.2495V17.4162C18.6668 19.027 17.361 20.3329 15.7502 20.3329H8.25016C6.63933 20.3329 5.3335 19.027 5.3335 17.4162V13.2495C5.3335 11.7804 6.41963 10.565 7.8327 10.3625L7.83349 8.80959C7.83131 6.56436 9.80084 4.5 12.0002 4.5ZM15.7502 11.9995H8.25016C7.55981 11.9995 7.00016 12.5592 7.00016 13.2495V17.4162C7.00016 18.1066 7.55981 18.6662 8.25016 18.6662H15.7502C16.4405 18.6662 17.0002 18.1066 17.0002 17.4162V13.2495C17.0002 12.5592 16.4405 11.9995 15.7502 11.9995ZM12.0002 6.16667C10.7396 6.16667 9.49886 7.46711 9.50016 8.80878L9.49933 10.3325H14.4993L14.5002 8.6912C14.5002 7.49121 13.3366 6.25806 12.1339 6.1715L12.0002 6.16667Z"
        fill="white"
      />
    </svg>
  )
}

LockOutlined.defaultProps = {
  size: 24,
  className: '',
  color: '#818C99',
}

export default LockOutlined
