import { VotingStatuses, VotingView } from '../types'
import moment from 'moment'

export const votingsListMap = (data: Array<VotingListItemApi>): Array<VotingView> => {
  return data.map((x) => votingMap(x))
}

const getStatus = (date: string, voted: boolean): VotingStatuses => {
  const milD = moment.parseZone(date).unix()
  const curD = moment().unix()

  return voted ? VotingStatuses.completed : milD < curD ? VotingStatuses.ended : VotingStatuses.active
}

export const votingMap = (data: VotingListItemApi): VotingView => {
  return {
    id: data.id,
    dataRun:
      moment.parseZone(data.startDate).format('DD.MM.YYYY') +
      ' - ' +
      moment.parseZone(data.endDate).format('DD.MM.YYYY'),
    name: data.title,
    description: data.description,
    members: data.totalVotesCount,
    status: getStatus(data.endDate, data.userIsVoted),
  }
}

export interface VotingListItemApi {
  description: string
  endDate: string
  id: number
  isAuth: boolean
  startDate: string
  title: string
  totalVotesCount: number
  userIsVoted: boolean
}
