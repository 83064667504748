import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Album } from '../../api/model/album'
import appAPI from '../../api/api'
import albumGrid from '../../utils/albumGrid'

export type GalleryState = {
  albums: Album[]
  colSizes: number[]
  page: number
  pages: number
  loading: boolean
}

const initialGalleryState: GalleryState = {
  albums: [],
  colSizes: [],
  page: 0,
  pages: 1,
  loading: false,
}

const fetchAlbums = createAsyncThunk('/fetchAlbums', async (payload: GetAlbumsPayload = { limit: 0, offset: 0 }) => {
  return await appAPI.gallery.getAlbums(payload)
})

const gallerySlice = createSlice({
  name: 'gallery',
  initialState: initialGalleryState,
  reducers: {
    setPage: (state: GalleryState, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    clearAlbums: () => initialGalleryState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlbums.pending, (state: GalleryState) => {
        state.loading = true
      })
      .addCase(fetchAlbums.fulfilled, (state: GalleryState, { payload }) => {
        state.colSizes = albumGrid([...state.albums, ...(payload.data.results || [])].length)
        state.albums = [...state.albums, ...(payload.data.results || [])]
        state.pages = payload.data.pages || 1
        state.loading = false
      })
      .addCase(fetchAlbums.rejected, (state: GalleryState) => {
        state.loading = false
      })
  },
})

export default gallerySlice.reducer

export const gallerySliceActions = {
  ...gallerySlice.actions,
  fetchAlbums,
}
