import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const UnLock: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5307 2.02681C14.1163 1.75748 16.4693 3.54496 16.9812 6.14466C17.0879 6.68654 16.7351 7.21232 16.1932 7.31902C15.6513 7.42571 15.1255 7.07293 15.0188 6.53105C14.7046 4.9351 13.2829 3.85511 11.738 4.01605C10.1885 4.17745 8.99841 5.53629 9 7.17119L8.999 10L16.5 10.0001C18.433 10.0001 20 11.5671 20 13.5001V18.5001C20 20.4331 18.433 22.0001 16.5 22.0001H7.5C5.567 22.0001 4 20.4331 4 18.5001V13.5001C4 11.7372 5.30337 10.2787 6.99905 10.0357L7 7.17216C6.99742 4.52481 8.9497 2.29567 11.5307 2.02681ZM16.5 12.0001H7.5C6.67157 12.0001 6 12.6717 6 13.5001V18.5001C6 19.3285 6.67157 20.0001 7.5 20.0001H16.5C17.3284 20.0001 18 19.3285 18 18.5001V13.5001C18 12.6717 17.3284 12.0001 16.5 12.0001Z"
        fill={color}
      />
    </svg>
  )
}

UnLock.defaultProps = {
  size: 24,
  className: '',
  color: '#818C99',
}

export default UnLock
