import React, { FC } from 'react'

import { ArrowSwitcherRight, ArrowSwitcherLeft } from '../icons'
import styles from './left-right-switcher.module.scss'

type LeftRightSwitcherProps = {
  enabledForward: boolean
  enabledPrevious: boolean
  onPreviousClick: () => void
  onForwardClick: () => void
}

const LeftRightSwitcher: FC<LeftRightSwitcherProps> = (props) => {
  const { enabledForward, enabledPrevious, onPreviousClick, onForwardClick } = props

  return (
    <div className={styles.switcherContainer}>
      <button className={styles.switcher} onClick={() => onPreviousClick()} disabled={!enabledPrevious}>
        <ArrowSwitcherLeft color={enabledPrevious ? '#309BEC' : '#B8C1CC'} />
      </button>
      <button className={styles.switcher} onClick={() => onForwardClick()} disabled={!enabledForward}>
        <ArrowSwitcherRight color={enabledForward ? '#309BEC' : '#B8C1CC'} />
      </button>
    </div>
  )
}

export default LeftRightSwitcher
