import React, { FC } from 'react'

const Burger: FC = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.44772 0.44772 0 1 0H15C15.5523 0 16 0.44772 16 1C16 1.55228 15.5523 2 15 2H1C0.44772 2 0 1.55228 0 1ZM0 7C0 6.4477 0.44772 6 1 6H15C15.5523 6 16 6.4477 16 7C16 7.5523 15.5523 8 15 8H1C0.44772 8 0 7.5523 0 7ZM1 12C0.44772 12 0 12.4477 0 13C0 13.5523 0.44772 14 1 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H1Z"
        fill="#025EA1"
      />
    </svg>
  )
}

export default Burger
