import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { CompetenceDetail } from '../../api/model/competenceDetail'
import { VisitsDataType } from '../../components/popovers/statistic-popover/types/statisticType'
import { CompetenceVisitStatistics } from '../../api/model/models'
import { dateFormat } from '../../utils/date-format'
import { timeFormat } from '../../utils/time-format'

export type CompetenceDetailState = {
  competence: CompetenceDetail | null
  loading: boolean
  competenceStatistic: VisitsDataType[] | undefined
  loadingStatistic: boolean
  thereNewDocument: boolean
}

const initialCompetenceDetailState: CompetenceDetailState = {
  competence: null,
  loading: false,
  competenceStatistic: undefined,
  loadingStatistic: false,
  thereNewDocument: false,
}

const fetchCompetence = createAsyncThunk('/fetchCompetency', async (payload: { id: string }, { rejectWithValue }) => {
  try {
    return await appAPI.competences.getCompetence(payload.id)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const fetchCompetenceStatistic = createAsyncThunk('/fetchCompetencyStatistic', async (payload: { id: string }) => {
  return await appAPI.competences.getCompetenceStatistic(payload.id)
})

const addCompetenceStatistic = createAsyncThunk('/addCompetencyStatistic', async (payload: { id: string }) => {
  return await appAPI.competences.addCompetenceStatistic(payload.id)
})

const checkNewDocumentsInCompetence = createAsyncThunk(
  '/checkNewDocumentsInCompetence',
  async (payload: { id: string }) => {
    return await appAPI.competences.checkNewDocuments(payload.id)
  },
)

const competenceDetailSlice = createSlice({
  name: 'competencies',
  initialState: initialCompetenceDetailState,
  reducers: {
    clearCompetence: (state) => {
      state.competence = initialCompetenceDetailState.competence
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetence.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchCompetence.fulfilled, (state, { payload }) => {
        state.competence = payload.data
        state.loading = false
      })
      .addCase(fetchCompetence.rejected, (state) => {
        state.loading = false
      })

      .addCase(fetchCompetenceStatistic.pending, (state) => {
        state.loadingStatistic = true
      })
      .addCase(fetchCompetenceStatistic.fulfilled, (state, { payload }) => {
        const formattingForStatistics = (payloadData: CompetenceVisitStatistics[]): VisitsDataType[] | undefined => {
          const newData = payloadData?.map((item) => ({
            key: item.id,
            name: item.fullName ?? 'Пользователь не зарегистрирован',
            date: dateFormat(item.dateCreated),
            time: timeFormat(item.dateCreated),
          }))

          return newData.length > 0 ? newData : undefined
        }

        state.competenceStatistic = formattingForStatistics(payload.data)
        state.loadingStatistic = false
      })
      .addCase(fetchCompetenceStatistic.rejected, (state) => {
        state.loadingStatistic = false
      })

      .addCase(checkNewDocumentsInCompetence.fulfilled, (state, { payload }) => {
        state.thereNewDocument = payload.data.statusNew
      })
  },
})

export default competenceDetailSlice.reducer

export const competenceDetailSliceActions = {
  ...competenceDetailSlice.actions,
  fetchCompetence,
  fetchCompetenceStatistic,
  addCompetenceStatistic,
  checkNewDocumentsInCompetence,
}
