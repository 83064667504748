import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'

type CaptchaState = {
  captcha: Captcha | undefined
  loading: boolean
}

const initialCaptchaState: CaptchaState = {
  captcha: undefined,
  loading: false,
}

export const fetchCaptcha = createAsyncThunk('fetchCaptcha', async () => {
  return await appAPI.captcha.getCaptcha()
})

const captchaSlice = createSlice({
  name: 'captcha',
  initialState: initialCaptchaState,
  reducers: {
    clear: () => {
      return initialCaptchaState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCaptcha.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchCaptcha.fulfilled, (state, { payload }) => {
        state.loading = false
        state.captcha = payload.data
        state.captcha.captchaBase64 =
          `data:${state.captcha.imageType};base64,` +
          String.fromCharCode.apply(null, Array.from(new Uint8Array(state.captcha.captchaImage).values()) as number[])
      })
      .addCase(fetchCaptcha.rejected, (state) => {
        state.loading = false
        state.captcha = initialCaptchaState.captcha
      })
  },
})

export const captchaActions = {
  fetchCaptcha,
  ...captchaSlice.actions,
}

export default captchaSlice.reducer
