import React, { FC } from 'react'

type Props = {
  width?: string | number
  height?: string | number
  className?: string
  color?: string
}

const ArrowDown: FC<Props> = (props) => {
  const { width = 12, height = 6, className = '', color = '#333333' } = props

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L6 5L11 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowDown
