import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { NewsDetail } from '../../api/model/models'

export type NewsDetailState = {
  news: NewsDetail | null
  loading: boolean
}

const initialNewsDetailState: NewsDetailState = {
  news: null,
  loading: false,
}

const fetchNewsDetail = createAsyncThunk('/fetchNewsDetail', async (payload: { id: number }, { rejectWithValue }) => {
  try {
    return await appAPI.news.getNewsDetail(payload.id)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const newsDetailSlice = createSlice({
  name: 'newsDetail',
  initialState: initialNewsDetailState,
  reducers: {
    clearNewsDetail: (state) => {
      state.news = initialNewsDetailState.news
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsDetail.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchNewsDetail.fulfilled, (state, { payload }) => {
        state.news = payload.data
        state.loading = false
      })
      .addCase(fetchNewsDetail.rejected, (state) => {
        state.loading = false
      })
  },
})

export default newsDetailSlice.reducer

export const newsDetailSliceActions = {
  ...newsDetailSlice.actions,
  fetchNewsDetail,
}
