import React, { FC, SVGProps } from 'react'

interface IArrowProps extends SVGProps<any> {
  color?: string
}

const ArrowRight: FC<IArrowProps> = ({ color, ...rest }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle fill={'transparent'} cx={12} cy={12} r={12} strokeWidth={0} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7071 17.7072C14.3166 18.0977 13.6834 18.0977 13.2929 17.7072C12.9024 17.3166 12.9024 16.6835 13.2929 16.2929L16.5858 13L5 13C4.44771 13 4 12.5523 4 12C4 11.4478 4.44771 11 5 11L16.5858 11L13.2929 7.70715C12.9024 7.31663 12.9024 6.68346 13.2929 6.29294C13.6834 5.90242 14.3166 5.90242 14.7071 6.29294L19.7071 11.2929C20.0976 11.6835 20.0976 12.3166 19.7071 12.7072L14.7071 17.7072Z"
        fill={color}
      />
    </svg>
  )
}

ArrowRight.defaultProps = {
  color: '#333333',
}

export default ArrowRight
