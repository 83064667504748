import React, { FC } from 'react'
import { List as AntList } from 'antd'
import { ListProps } from 'antd/lib/list'
import Item, { ItemType } from './item/item'
import './list.ant.scss'

const List: FC<ListProps<unknown>> & { Item: ItemType } = (props) => {
  return <AntList {...props}>{props.children}</AntList>
}

List.Item = Item

export default List
