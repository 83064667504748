import Countly from 'countly-sdk-web'

export const statistics = async () => {
  const fconfigJson = await fetch('/config.json')
  const config = await fconfigJson.json()

  Countly.init({
    app_key: config.countlyKey,
    url: config.countlyUrl,
    // Время простоя, после которого пользователь считается неактивным (минуты)
    inactivity_time: 10,
  })

  Countly.q.push(['track_sessions'])
  Countly.q.push(['track_pageview'])
}

export const addCountlyEvent = (keyEvent, segmentation) => {
  Countly.q.push([
    'add_event',
    {
      key: keyEvent,
      count: 1,
      segmentation: segmentation,
    },
  ])
}

export const addStartCountlyEvent = (keyEvent) => {
  Countly.q.push(['start_event', keyEvent])
}

export const addEndCountlyEvent = (keyEvent, segmentation) => {
  Countly.q.push([
    'end_event',
    {
      key: keyEvent,
      count: 1,
      segmentation: segmentation,
    },
  ])
}

export const addCountlyUserDetails = (userId) => {
  Countly.q.push(['user_details', { id: userId }])
}
