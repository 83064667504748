import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '../../api/api'
import { SetPassword } from '../../api/model/setPassword'
import { UserWSDetail } from '../../api/model/userWSDetail'

export const UserProfileSection = {
  MainInfo: {
    short: 'Профиль',
    long: 'Профиль',
    isHidden: false,
  },
  AddRating: {
    short: 'Оценка',
    long: 'Оценка',
    isHidden: false,
  },
  Championships: {
    short: 'Чемпионаты',
    long: 'Участие в чемпионатах',
    isHidden: false,
  },
  SkillPassport: {
    short: 'Скилл паспорт',
    long: 'Скилл паспорт',
    isHidden: false,
  },
  Competences: {
    short: 'Компетенции',
    long: 'Компетенции',
    isHidden: false,
  },
  About: {
    short: 'О себе',
    long: 'О себе',
    isHidden: false,
  },
  BodySizes: {
    short: 'Размеры',
    long: 'Размеры',
    isHidden: false,
  },
  Notifications: {
    short: 'Уведомления',
    long: 'Уведомления',
    isHidden: false,
  }
}

export type UserProfileSectionKey = keyof typeof UserProfileSection

export type UserProfileState = {
  loadingMain: boolean
  loadingForm: boolean
  needToUpdate: boolean
  profile: UserWSDetail | undefined
  goToSection: UserProfileSectionKey | undefined
  activeSection: UserProfileSectionKey | undefined
  loadingChangeEmailNotify: boolean
}

const initialUserProfileState: UserProfileState = {
  loadingMain: false,
  loadingForm: false,
  needToUpdate: false,
  profile: undefined,
  goToSection: undefined,
  activeSection: 'MainInfo',
  loadingChangeEmailNotify: false,
}

export const fetchUserProfile = createAsyncThunk(
  'fetchUserProfile',
  async (payload: GetUserProfilePayload, { rejectWithValue }) => {
    try {
      return await api.userProfile.get(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const setUserProfile = createAsyncThunk(
  'setUserProfile',
  async (payload: UserWSDetail & { id?: number }, { rejectWithValue }) => {
    try {
      const id = payload.id
      if (!id) return Promise.resolve()
      delete payload.id
      return await api.userProfile.put(id, payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const setPasswordUserProfile = createAsyncThunk(
  'setPasswordUserProfile',
  async (payload: SetPassword, { rejectWithValue }) => {
    try {
      return await api.userProfile.postSetPassword(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const setAvatarUserProfile = createAsyncThunk(
  'setAvatarUserProfile',
  async (payload: PutUserProfilePayloadChangeAvatar, { rejectWithValue }) => {
    try {
      return await api.userProfile.putCreateAvatar(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const setNotifySetting = createAsyncThunk('setNotifySetting', async () => {
  return await api.userProfile.putNotifySetting();
})

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: initialUserProfileState,
  reducers: {
    clearAll: () => {
      return initialUserProfileState
    },
    goToSection: (state, { payload }: PayloadAction<UserProfileSectionKey>) => {
      state.goToSection = payload
      // state.activeSection = payload
    },
    scrolledTo: (state, { payload }: PayloadAction<UserProfileSectionKey>) => {
      state.activeSection = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loadingMain = true
      })
      .addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
        state.loadingMain = false
        state.needToUpdate = false
        state.profile = payload.data
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.loadingMain = false
      })

      .addCase(setUserProfile.pending, (state) => {
        state.loadingForm = true
      })
      .addCase(setUserProfile.fulfilled, (state) => {
        state.loadingForm = false
        state.needToUpdate = true
      })
      .addCase(setUserProfile.rejected, (state) => {
        state.loadingForm = false
        state.needToUpdate = false
      })

      .addCase(setPasswordUserProfile.pending, (state) => {
        state.loadingForm = true
      })
      .addCase(setPasswordUserProfile.fulfilled, (state) => {
        state.loadingForm = false
        state.needToUpdate = true
      })
      .addCase(setPasswordUserProfile.rejected, (state) => {
        state.loadingForm = false
        state.needToUpdate = false
      })

      .addCase(setAvatarUserProfile.pending, (state) => {
        state.loadingForm = true
      })
      .addCase(setAvatarUserProfile.fulfilled, (state) => {
        state.loadingForm = false
        state.needToUpdate = true
      })
      .addCase(setAvatarUserProfile.rejected, (state) => {
        state.loadingForm = false
        state.needToUpdate = false
      })

      .addCase(setNotifySetting.pending, (state) => {
        state.loadingChangeEmailNotify = true
      })
      .addCase(setNotifySetting.fulfilled, (state) => {
        state.loadingChangeEmailNotify = false
      })
      .addCase(setNotifySetting.rejected, (state) => {
        state.loadingChangeEmailNotify = false
      })
  },
})

export const userProfileActions = {
  ...userProfileSlice.actions,
  fetchUserProfile,
  setUserProfile,
  setPasswordUserProfile,
  setAvatarUserProfile,
  setNotifySetting,
}

export default userProfileSlice.reducer
