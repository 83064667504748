import React, { FC } from 'react'

const Error404: FC = () => {
  return (
    <>
      <div style={{ fontSize: 'x-large', textAlign: 'center', paddingTop: '230px' }}>Страница не найдена (404)</div>
    </>
  )
}

export default Error404
