import { declineWord } from "../../../../utils/declineWord"

const users = ['человек', 'человека', 'человек'];
const visits = ['посетил', 'посетили', 'посетили'];
const download = ['скачал', 'скачали', 'скачали'];

export const subtitles = {
  visits(pageName: string | undefined, count: number) {
    return `Раздел ${pageName ? `«${pageName}»` : ''} ${declineWord(count, visits)} ${count} ${declineWord(count, users)}`
  },
  downloads(docName: string | undefined, count: number) {
    return `Документ ${docName ? `«${docName}»` : ''} ${declineWord(count, download)} ${count} ${declineWord(count, users)}`
  },
}
