import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { FeedbackMsg, DivisionExpertFeedbackMsg } from '../../api/model/models'
export type FeedbackState = {
  loading: boolean
  successSend: boolean
}

const initialFeedbackState: FeedbackState = {
  loading: false,
  successSend: false,
}

const sendFeedback = createAsyncThunk('sendFeedback', async (payload: FeedbackMsg, { rejectWithValue }) => {
  try {
    return await appAPI.feedback.postFeedback(payload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const sendDivisionExpertFeedback = createAsyncThunk(
  'sendDivisionExpertFeedback',
  async (payload: DivisionExpertFeedbackMsg, { rejectWithValue }) => {
    try {
      return await appAPI.feedback.postDivisionExpertFeedback(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const FeedbackSlice = createSlice({
  name: 'feedback',
  initialState: initialFeedbackState,
  reducers: {
    clearFeedback: () => initialFeedbackState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendFeedback.pending, (state) => {
        state.loading = true
        state.successSend = false
      })
      .addCase(sendFeedback.fulfilled, (state) => {
        state.loading = false
        state.successSend = true
      })
      .addCase(sendFeedback.rejected, (state) => {
        state.loading = false
        state.successSend = false
      })
      .addCase(sendDivisionExpertFeedback.pending, (state) => {
        state.loading = true
        state.successSend = false
      })
      .addCase(sendDivisionExpertFeedback.fulfilled, (state) => {
        state.loading = false
        state.successSend = true
      })
      .addCase(sendDivisionExpertFeedback.rejected, (state) => {
        state.loading = false
        state.successSend = false
      })
  },
})

export default FeedbackSlice.reducer

export const feedbackActions = {
  ...FeedbackSlice.actions,
  sendFeedback,
  sendDivisionExpertFeedback,
}
