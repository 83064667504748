import React, { FC } from 'react'
import { Form as AntForm } from 'antd'
import { FormProps, FormItemProps, FormListProps, ErrorListProps } from 'antd/lib/form'
import useForm from 'antd/lib/form/hooks/useForm'

import Item from './item/item'
import Captcha, { CaptchaProps } from './captcha/captcha'
import './form.ant.scss'

interface FormComponent extends FC<FormProps> {
  Item: FC<FormItemProps>
  Captcha: FC<CaptchaProps>
  List: FC<FormListProps>
  ErrorList: FC<ErrorListProps>
  useForm: typeof useForm
}

const Form: FormComponent = (props) => {
  return <AntForm {...props} />
}

Form.Item = Item
Form.Captcha = Captcha
Form.List = AntForm.List
Form.ErrorList = AntForm.ErrorList
Form.useForm = AntForm.useForm
export default Form
