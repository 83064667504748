import React, { FC, SVGProps } from 'react'

type Props = SVGProps<any> & {
  color?: string
  size?: string
}
const Settings: FC<Props> = ({ color = '#309BEC', size = '24', ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1202 15.0055L4.92149 18.0504C5.02756 18.2266 5.19444 18.3596 5.39185 18.4253C5.49609 18.4589 5.6064 18.4704 5.7155 18.459C5.82459 18.4476 5.92996 18.4135 6.02463 18.359L7.87443 17.5305C8.26494 17.8027 8.68009 18.0392 9.11467 18.2368L9.30239 20.2543C9.30161 20.2688 9.30161 20.2833 9.30239 20.2978C9.33769 20.4908 9.43958 20.666 9.59093 20.7938C9.74227 20.9217 9.93385 20.9945 10.1334 21H13.7339C13.9461 21.0007 14.1508 20.9229 14.3072 20.782C14.4635 20.6411 14.5601 20.4475 14.5776 20.2398L14.7632 18.2368C15.2003 18.046 15.6165 17.8121 16.0056 17.5388L17.8638 18.3797H17.8828C18.0741 18.4597 18.2885 18.469 18.4863 18.4058C18.684 18.3426 18.8518 18.2112 18.9585 18.0359L20.7492 15.0097C20.8585 14.8281 20.8953 14.6132 20.8524 14.4066C20.8096 14.1999 20.6901 14.0162 20.5172 13.8911L18.9585 12.725C19.0294 12.2497 19.0294 11.7669 18.9585 11.2916L20.6459 10.1171C20.8191 9.99234 20.939 9.80881 20.9822 9.60215C21.0255 9.39548 20.9891 9.18041 20.88 8.99862L19.0323 5.95788C18.9828 5.86484 18.9147 5.78252 18.8322 5.71583C18.7496 5.64914 18.6542 5.59944 18.5517 5.56968C18.4492 5.53992 18.3416 5.53071 18.2354 5.5426C18.1292 5.55449 18.0265 5.58724 17.9334 5.6389L16.0836 6.46744C15.693 6.19598 15.2778 5.96025 14.8434 5.76318L14.6578 3.74569V3.70219C14.6225 3.50921 14.5206 3.33405 14.3692 3.20616C14.2179 3.07828 14.0263 3.00548 13.8267 3H10.2199C10.0074 2.99934 9.80239 3.07747 9.64597 3.21879C9.48955 3.36011 9.39321 3.5542 9.37621 3.76226L9.1906 5.76318C8.75115 5.95496 8.33277 6.19023 7.94193 6.46536L6.09212 5.63061H6.07314C5.88157 5.5523 5.66764 5.54459 5.47075 5.60892C5.27385 5.67324 5.10726 5.80527 5.00164 5.98067L3.20668 8.99862C3.09652 9.18226 3.06033 9.39974 3.10521 9.60827C3.1501 9.8168 3.27283 10.0013 3.44924 10.1254L5.12398 11.2937C5.05431 11.7635 5.05431 12.2407 5.12398 12.7105L3.35011 13.8911C3.17827 14.0161 3.05968 14.1992 3.01722 14.405C2.97476 14.6108 3.01145 14.8247 3.1202 15.0055Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle cx="12" cy="12" r="3" stroke={color} strokeWidth="2" />
    </svg>
  )
}

export default Settings
