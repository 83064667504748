import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { SearchGroup, SearchRow } from '../../../../../api/model/models'
import { globalSearchActions } from '../../../../../store/slices/globalSearchSlice'
import { RootState, useAppDispatch } from '../../../../../store/store'

type UseGroupForGlobalSearch = (
  id: string,
) => {
  state: {
    title: string
    items: SearchRow[]
    isDetailed: boolean
    loading: boolean
    totalCount: number
  }
  methods: {
    switchView: () => void
  }
}

const useGroupForGlobalSearch: UseGroupForGlobalSearch = (groupType) => {
  const dispatch = useAppDispatch()
  const [isDetailed, setIsDetailed] = useState(false)
  const [loading, setLoading] = useState(false)

  const loadingGroup = useSelector<RootState, boolean>((state) => state.globalSearch.loadingGroup)
  const groups = useSelector<RootState, SearchGroup[] | undefined>((state) => state.globalSearch.resultSearch)
  const getGroupFromGeneralSearch = useCallback(() => {
    return groups ? groups.find((group) => group.modelName === groupType) : null
  }, [groups, groupType])

  const resultSearchByGroup = useSelector<RootState, SearchGroup | undefined>(
    (state) => state.globalSearch.resultSearchByGroup,
  )

  const currentExpression = useSelector<RootState, string | undefined>((state) => state.globalSearch.currentExpression)

  useEffect(() => {
    if (!loadingGroup) {
      setLoading(false)
      const group = getGroupFromGeneralSearch()
      if (group?.modelName === resultSearchByGroup?.modelName) setIsDetailed(true)
    }
  }, [loadingGroup, getGroupFromGeneralSearch, resultSearchByGroup])

  useEffect(() => {
    dispatch(globalSearchActions.clearResultSearchByGroup())
  }, [groups, dispatch])

  useEffect(() => {
    if (isDetailed && resultSearchByGroup === undefined) setIsDetailed(false)
  }, [resultSearchByGroup, isDetailed])

  const getItems = () => {
    const group = getGroupFromGeneralSearch()
    if (isDetailed && resultSearchByGroup) return resultSearchByGroup?.list_ || []
    if (!isDetailed && group) return group?.list_ || []
    return []
  }

  const getTotalCount = () => {
    return getGroupFromGeneralSearch()?.totalCount || 0
  }

  const switchView = () => {
    const group = getGroupFromGeneralSearch()
    if (!isDetailed && group?.modelName && currentExpression) {
      dispatch(globalSearchActions.fetchSearchByGroup({ expr: currentExpression, modelName: group.modelName }))
      setLoading(true)
    }
    if (isDetailed) {
      dispatch(globalSearchActions.clearResultSearchByGroup())
      setIsDetailed(false)
    }
  }

  return {
    state: {
      title: getGroupFromGeneralSearch()?.name || '',
      items: getItems(),
      isDetailed,
      loading,
      totalCount: getTotalCount(),
    },
    methods: {
      switchView,
    },
  }
}

export default useGroupForGlobalSearch
