import React, { FC, MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'
import { Button } from '../index'
import styles from './combo-box.module.scss'

type ComboBoxProps = {
  icon: ReactNode
  openedIcon?: ReactNode
  className?: string
  wrapperClassName?: string
  overlayClassName?: string
  comboBox?: React.MutableRefObject<IComboBoxManager | null>
  disabled?: boolean
}

interface IComboBoxManager {
  show: () => void
  hide: () => void
}

export type UseComboBox = () => { comboBox: MutableRefObject<IComboBoxManager | null> }

export const useComboBox: UseComboBox = () => {
  const comboBoxManager = useRef<IComboBoxManager | null>(null)
  return {
    comboBox: comboBoxManager,
  }
}

const ComboBox: FC<ComboBoxProps> & { useComboBox: UseComboBox } = (props) => {
  const { children, icon, openedIcon, className, wrapperClassName, overlayClassName, comboBox, disabled } = props

  if (comboBox)
    comboBox.current = {
      show: () => setShow(true),
      hide: () => setShow(false),
    }

  const [show, setShow] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: { target: EventTarget | null }) => {
    if (wrapperRef && !wrapperRef.current?.contains(event.target as Node)) {
      setShow(false)
    }
  }

  const handleClick = () => {
    setShow(!show)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={[styles.wrapper, wrapperClassName || '', show ? styles.show : ''].join(' ')} ref={wrapperRef}>
      <div className={[styles.overlay, overlayClassName || ''].join(' ')}>{children}</div>
      <Button
        type={'ghost'}
        icon={show ? openedIcon || icon : icon}
        className={className}
        onClick={handleClick}
        disabled={disabled}
      />
    </div>
  )
}

ComboBox.useComboBox = useComboBox

export default ComboBox
