import { CompetenceType, EditGroup, ParticipantsPayloadType, SectionGroup } from '../../components/survey/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { ParticipantsSurveyType } from '../../components/survey/common/participants-list/participants-list'

type State = {
  groups: DataLoad<SectionGroup[]>
  editGroup: EditGroup
  participants: DataLoad<ParticipantsSurveyType[]>
}

export const initialState: State = {
  groups: { loading: false, data: [] },
  editGroup: { id: 0, name: '', competences: [], Members: [] },
  participants: { loading: false, data: [] },
}

const fetchGroup = createAsyncThunk('fetchGroupAdmin', (payload: Paginated) => {
  return appAPI.groups.get(payload)
})
const fetchParticipants = createAsyncThunk('fetchParticipantsGroup', (payload: ParticipantsPayloadType) => {
  return appAPI.groups.getParticipants(payload)
})
const updateGroup = createAsyncThunk('updateGroup', (payload: EditGroup) => {
  const output: ApiEditGroup = {
    id: payload.id,
    name: payload.name,
    competences: payload.competences,
    Members: payload.Members.map((x) => {
      return {
        id: x.id,
        fullName: '',
        avatar: null,
        competence: {
          id: 0,
          name: '',
          pictogram: '',
          description: '',
        },
        role: '',
        company: '',
        position: '',
      }
    }),
  }
  if (payload.id === 0) return appAPI.groups.create(output)
  else return appAPI.groups.update(output)
})

const fetchDetailGroup = createAsyncThunk('fetchDetailGroupAdmin', (payload: number) => {
  return appAPI.groups.getDetail(payload)
})
const deleteGroup = createAsyncThunk('deleteGroupAdmin', (id: number) => {
  return appAPI.groups.deleteGroup(id)
})

const group = createSlice({
  name: 'sectionGroupSlice',
  initialState,
  reducers: {
    clearGroups: (state) => {
      state.groups = initialState.groups
    },
    clearFullGroups: (state) => {
      state.editGroup = initialState.editGroup
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroup.pending, (state) => {
      state.groups.loading = true
    })
    builder.addCase(fetchGroup.fulfilled, (state, { payload }) => {
      state.groups.loading = false
      state.groups.data = state.groups.data.concat(payload.data)
    })
    builder.addCase(fetchGroup.rejected, (state) => {
      state.groups.loading = false
    })
    builder.addCase(fetchDetailGroup.fulfilled, (state, { payload }) => {
      state.editGroup = payload.data
    })
    builder.addCase(fetchDetailGroup.rejected, (state) => {
      state.editGroup = initialState.editGroup
    })
    builder.addCase(deleteGroup.fulfilled, (state, payload) => {
      state.groups.data = state.groups.data.filter((x) => x.id !== payload.meta.arg)
    })
    builder.addCase(fetchParticipants.pending, (state) => {
      state.participants.loading = true
    })
    builder.addCase(fetchParticipants.fulfilled, (state, { payload }) => {
      state.participants.loading = false
      state.participants.data = payload.data
        .map((x) => {
          return { ...x, fullName: `${x.lastName} ${x.firstName} ${x.middleName}` }
        })
        .sort((a, b) => (a.fullName > b.fullName ? 1 : -1))
    })
    builder.addCase(fetchParticipants.rejected, (state) => {
      state.participants = initialState.participants
    })
  },
})

export default group.reducer
export const groupActions = {
  ...group.actions,
  fetchGroup,
  deleteGroup,
  fetchDetailGroup,
  updateGroup,
  fetchParticipants,
}

export type ApiEditGroup = {
  id: number
  name: string
  competences: number[]
  Members: ApiMembers[]
}
type ApiMembers = {
  id: number
  fullName: string
  avatar: null
  competence: CompetenceType
  role: string
  company: string
  position: string
}
