import {
  EditVoting,
  IdentificationVoting,
  VotingView,
  CurrentVoting,
  VoteResult,
  UserVote,
  ResultVoting,
  VotingStatuses,
} from '../../components/voting/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { votingMap, votingsListMap } from '../../components/voting/maps/to-votings-list-map'
import currentVotingMap from '../../components/voting/maps/to-current-voting-map'
import { resultVotingMap } from '../../components/voting/maps/to-result-voting-map'

type StateType = {
  votings: VotingView[]
  lastActive: VotingView | null
  voting: CurrentVoting | null
  votingEdit: EditVoting
  identification: IdentificationVoting
  currentAnswer: VoteResult | null
  result: ResultVoting | null
  completeVoting: boolean
  votingsLoading: boolean
  file: { data: Blob; id: number } | null
}

const initialState: StateType = {
  lastActive: null,
  votings: [],
  votingsLoading: false,
  voting: null,
  votingEdit: { id: 0 },
  identification: { FIO: '', orgName: '' },
  currentAnswer: null,
  result: null,
  completeVoting: false,
  file: null,
}

const fetchActive = createAsyncThunk('/fetchActiveVotingList', async (paginated: Paginated) => {
  return await appAPI.voting.getActive(paginated)
})
const fetchEnded = createAsyncThunk('/fetchEndedVotingList', async (paginated: Paginated) => {
  return await appAPI.voting.getEnded(paginated)
})
const fetchAll = createAsyncThunk('/fetchAllVotingList', async (paginated: Paginated) => {
  return await appAPI.voting.getAll(paginated)
})

const fetchLastActive = createAsyncThunk('/fetchLastActiveVoting', async () => {
  return await appAPI.voting.getLastActive()
})
const fetchCurrent = createAsyncThunk('/fetchCurrentVoting', async (id: number) => {
  return await appAPI.voting.get(id)
})
const sendAnswer = createAsyncThunk('/sendCurrentVoting', async (currentAnswer: VoteResult) => {
  try {
    return await appAPI.voting.sendAnswer(currentAnswer)
  } catch (e: any) {
    if (e.response.data.detail) {
      if (e.response.data.detail === 'poll is not active') throw new Error('timeout')
      else if (e.response.data.detail === 'user already voted') throw new Error('voted')
      throw new Error('err')
    }
  }
})
const fetchResult = createAsyncThunk('/fetchVotingResult', async (id: number) => {
  return await appAPI.voting.getResult(id)
})
const fetchResultExport = createAsyncThunk('/fetchVotingResultExport', async (id: number) => {
  return await appAPI.voting.getResultExport(id)
})

const votingSlice = createSlice({
  name: 'voting',
  initialState,
  reducers: {
    setIdentification: (state, { payload }: PayloadAction<IdentificationVoting>) => {
      state.identification = payload
    },
    newVoting: (state, { payload }: PayloadAction<VoteResult>) => {
      state.currentAnswer = payload
    },
    setVote: (state, { payload }: PayloadAction<UserVote>) => {
      if (state.currentAnswer !== null) {
        const out = state.currentAnswer.userVotes.filter((x) => x.pollQuestion !== payload.pollQuestion)
        if (payload.option.length === 0) state.currentAnswer.userVotes = out
        else state.currentAnswer.userVotes = [...out, payload]
      }
    },
    cancelVoting: (state) => {
      state.currentAnswer = null
      state.completeVoting = false
    },
    setCompleteVoting: (state) => {
      state.completeVoting = true
    },
    clearVotingList: (state) => {
      state.votings = []
    },
    clearFile: (state) => {
      state.file = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActive.pending, (state) => {
      state.votingsLoading = true
    })
    builder.addCase(fetchActive.rejected, (state) => {
      state.votings = initialState.votings
      state.votingsLoading = false
    })
    builder.addCase(fetchEnded.pending, (state) => {
      state.votingsLoading = true
    })
    builder.addCase(fetchEnded.rejected, (state) => {
      state.votings = initialState.votings
      state.votingsLoading = false
    })
    builder.addCase(fetchAll.pending, (state) => {
      state.votingsLoading = true
    })
    builder.addCase(fetchAll.rejected, (state) => {
      state.votings = initialState.votings
      state.votingsLoading = false
    })
    builder.addCase(fetchActive.fulfilled, (state, { payload }) => {
      if (payload.data.results.length !== 0) state.votings = [...state.votings, ...votingsListMap(payload.data.results)]
    })
    builder.addCase(fetchEnded.fulfilled, (state, { payload }) => {
      if (payload.data.results.length !== 0) state.votings = [...state.votings, ...votingsListMap(payload.data.results)]
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }) => {
      if (payload.data.results.length !== 0) state.votings = [...state.votings, ...votingsListMap(payload.data.results)]
    })

    builder.addCase(fetchCurrent.pending, (state) => {
      state.voting = initialState.voting
    })
    builder.addCase(fetchCurrent.fulfilled, (state, { payload }) => {
      state.voting = currentVotingMap(payload.data)
    })
    builder.addCase(fetchCurrent.rejected, (state) => {
      state.voting = initialState.voting
    })
    builder.addCase(fetchResultExport.pending, (state) => {
      state.file = initialState.file
    })
    builder.addCase(fetchResultExport.fulfilled, (state, { payload }) => {
      const id = Number(payload.config.url?.split('/')[3])
      state.file = { data: payload.data, id: isNaN(id) ? 0 : id }
    })
    builder.addCase(fetchResultExport.rejected, (state) => {
      state.file = initialState.file
    })
    builder.addCase(sendAnswer.pending, (state) => {
      state.currentAnswer = null
      state.completeVoting = false
    })
    builder.addCase(fetchResult.pending, (state) => {
      state.result = null
    })
    builder.addCase(fetchResult.fulfilled, (state, { payload }) => {
      state.result = resultVotingMap(payload.data[0])
      state.result.votingView.status = VotingStatuses.completed
    })
    builder.addCase(fetchResult.rejected, (state) => {
      state.result = null
    })
    builder.addCase(fetchLastActive.pending, (state) => {
      state.lastActive = null
    })
    builder.addCase(fetchLastActive.fulfilled, (state, { payload }) => {
      try {
        state.lastActive = payload === null ? null : votingMap(payload.data)
      } catch (e) {
        state.lastActive = null
      }
    })
    builder.addCase(fetchLastActive.rejected, (state) => {
      state.lastActive = null
    })
  },
})

export const votingSliceActions = {
  ...votingSlice.actions,
  fetchActive,
  fetchEnded,
  fetchAll,
  fetchCurrent,
  sendAnswer,
  getResult: fetchResult,
  fetchResultExport,
  fetchLastActive,
}

export default votingSlice.reducer
