import React, { FC } from 'react'

const Tune: FC = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0001 3.90001C3.08055 3.90001 3.15854 3.88945 3.23275 3.86965C3.60217 5.04639 4.70145 5.90001 6.0001 5.90001C7.29875 5.90001 8.39803 5.04639 8.76745 3.86964C8.84166 3.88945 8.91964 3.90001 9.0001 3.90001H17.0001C17.4972 3.90001 17.9001 3.49706 17.9001 3.00001C17.9001 2.50295 17.4972 2.10001 17.0001 2.10001H9.0001C8.91964 2.10001 8.84166 2.11057 8.76745 2.13037C8.39802 0.953621 7.29875 0.100006 6.0001 0.100006C4.70145 0.100006 3.60217 0.953619 3.23275 2.13037C3.15854 2.11056 3.08055 2.10001 3.0001 2.10001H1.0001C0.503041 2.10001 0.100098 2.50295 0.100098 3.00001C0.100098 3.49706 0.503041 3.90001 1.0001 3.90001H3.0001ZM6.0001 1.90001C5.39258 1.90001 4.9001 2.39249 4.9001 3.00001C4.9001 3.60752 5.39258 4.10001 6.0001 4.10001C6.60761 4.10001 7.1001 3.60752 7.1001 3.00001C7.1001 2.39249 6.60761 1.90001 6.0001 1.90001Z"
        fill="#2D9CDB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.100098 11C0.100098 10.5029 0.503041 10.1 1.0001 10.1H9.0001C9.08055 10.1 9.15854 10.1106 9.23275 10.1304C9.60217 8.95362 10.7014 8.10001 12.0001 8.10001C13.2987 8.10001 14.398 8.95362 14.7674 10.1304C14.8417 10.1106 14.9196 10.1 15.0001 10.1H17.0001C17.4972 10.1 17.9001 10.5029 17.9001 11C17.9001 11.4971 17.4972 11.9 17.0001 11.9H15.0001C14.9196 11.9 14.8417 11.8894 14.7674 11.8696C14.398 13.0464 13.2987 13.9 12.0001 13.9C10.7014 13.9 9.60217 13.0464 9.23275 11.8696C9.15854 11.8894 9.08055 11.9 9.0001 11.9H1.0001C0.503041 11.9 0.100098 11.4971 0.100098 11ZM10.9001 11C10.9001 10.3925 11.3926 9.90001 12.0001 9.90001C12.6076 9.90001 13.1001 10.3925 13.1001 11C13.1001 11.6075 12.6076 12.1 12.0001 12.1C11.3926 12.1 10.9001 11.6075 10.9001 11Z"
        fill="#2D9CDB"
      />
    </svg>
  )
}

export default Tune
