import { CurrentVoting } from '../types'
import { VotingListItemApi, votingMap } from './to-votings-list-map'

const currentVotingMap = (data: CurrentVotingApi): CurrentVoting => {
  return {
    votingView: votingMap(data),
    questions: data.questionsId
      .map((x) => {
        return {
          id: x.id,
          name: x.text,
          multiply: x.maxVoteOptions > 1,
          userAnswer: x.allowCustomOption,
          maxAnswer: x.maxVoteOptions,
          options: x.questionOptions
            .map((o) => {
              return {
                id: o.id,
                name: o.text,
                file: o.file
                  ? { name: 'Видео', description: o.fileDescription ? o.fileDescription : '', source: o.file }
                  : null,
              }
            })
            .sort((a, b) => a.id - b.id),
          pollQuestion: x.questionOptions[0].pollQuestion,
        }
      })
      .reverse(),
  }
}

export interface CurrentVotingApi extends VotingListItemApi {
  questionsId: Array<VotingQuestion>
}

type VotingQuestion = {
  allowCustomOption: boolean
  id: number
  questionOptions: Array<Option>
  maxVoteOptions: number
  text: string
}

type Option = {
  file: string | null
  fileDescription: string | null
  id: number
  pollQuestion: number
  text: string
}

export default currentVotingMap
