import { Space } from 'antd'
import React, { FC } from 'react'
import { Button } from '../../../common'
import { ArrowDown } from '../../../common/icons'
import { Text } from '../../../common/text'
import ItemGlobalSearch from '../item'
import useGroupForGlobalSearch from './hooks/useGroupForGlobalSearch'
import styles from './styles.module.scss'

type GroupForGlobalSearchProps = {
  groupType: string
}

const GroupForGlobalSearch: FC<GroupForGlobalSearchProps> = ({ groupType }) => {
  const { state, methods } = useGroupForGlobalSearch(groupType)
  const { title, items, isDetailed, loading, totalCount } = state
  return (
    <div>
      <div className={isDetailed ? styles.headerFixed : styles.header}>
        <Text size="lg" className={styles.title}>
          {title}
        </Text>
        {totalCount > 3 && (
          <Button type="link" className={styles.button} onClick={methods.switchView} loading={loading}>
            <Space direction="horizontal" size="small">
              {isDetailed ? 'Свернуть' : 'Смотреть все'}{' '}
              <ArrowDown color="#309BEC" className={isDetailed ? styles.arrowButtonDetailed : styles.arrowButton} />
            </Space>
          </Button>
        )}
      </div>
      <div className={[styles.body, isDetailed && styles.modBody].join(' ')}>
        <Space direction="vertical" size="large">
          {items.map((item, index) => (
            <ItemGlobalSearch key={`${item.id_}${item.headline}${index}`} {...item} groupType={groupType} />
          ))}
        </Space>
      </div>
    </div>
  )
}

export default GroupForGlobalSearch
