export type VotingBase = {
  id: number
}

export enum VotingStatuses {
  active = 'active',
  completed = 'completed',
  ended = 'ended',
  all = 'all',
}

export type VotingView = {
  id: number
  dataRun: string
  name: string
  description: string
  members: number
  status: VotingStatuses
}

export type FileOption = {
  name: string
  source: string
  description: string
}

export interface QuestionOption {
  id: number
  name: string
  file: FileOption | null
}

export interface Questions {
  id: number
  name: string
  options: Array<QuestionOption>
  userAnswer: boolean
  multiply: boolean
  maxAnswer: number
  pollQuestion: number
}
export interface CurrentVoting {
  votingView: VotingView
  questions: Array<Questions>
}

export interface QuestionOptionResult extends QuestionOption {
  percent: string
}
export type QuestionsResult = {
  multiply: boolean
  id: number
  name: string
  picked: Array<number>
  options: Array<QuestionOptionResult>
}
export type ResultVoting = {
  votingView: VotingView
  questions: Array<QuestionsResult>
}

export type EditVoting = {
  id: number
}

export type IdentificationVoting = {
  FIO: string
  orgName: string
}

export type VoteResult = {
  user: number | null
  fullName: string | null
  company: string | null
  poll: number
  userVotes: Array<UserVote>
}
export type UserVote = {
  id?: number
  pollQuestion: number
  option: Array<number>
}
