import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { Company } from '../../api/model/company'
import appAPI from '../../api/api'

type CompaniesState = {
  companies: Company[]
  loading: boolean
}

const initialCompaniesState: CompaniesState = {
  companies: [],
  loading: false,
}

const fetchAllCompanies = createAsyncThunk('fetchAllCompanies', async (payload, { rejectWithValue }) => {
  try {
    return await appAPI.companies.get()
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const companiesSlice = createSlice({
  name: 'companies',
  initialState: initialCompaniesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllCompanies.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllCompanies.fulfilled, (state, { payload }) => {
      state.companies = payload.data
      state.loading = false
    })
    builder.addCase(fetchAllCompanies.rejected, (state) => {
      state.loading = false
    })
  },
})

export const companiesActions = {
  ...companiesSlice.actions,
  fetchAllCompanies,
}

export default companiesSlice.reducer
