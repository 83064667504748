import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AlbumMedia } from '../../api/model/models'
import appAPI from '../../api/api'

export type AlbumState = {
  name: string
  cover: string
  mediaContent: AlbumMedia[] | undefined
  videosCount: number
  imagesCount: number
  created: string
  isActive: boolean
  page: number
  pages: number
  perPage: number
  loading: boolean
  loadingMedia: boolean
  description: string
  contentType: 'image' | 'video' | undefined
}

const convertDateToRuFormat = (date: string): string => {
  const convertedDate = new Date(date).toLocaleDateString('ru-Ru')
  return convertedDate !== 'Invalid Date' ? convertedDate : ''
}

const initialAlbumState: AlbumState = {
  name: '',
  cover: '',
  mediaContent: undefined,
  videosCount: 0,
  imagesCount: 0,
  created: '',
  isActive: true,
  page: 0,
  pages: 1,
  perPage: 15,
  loading: false,
  loadingMedia: false,
  description: '',
  contentType: undefined,
}

const fetchAlbumData = createAsyncThunk('/fetchAlbumData', async (payload: GetAlbumPayload) => {
  return await appAPI.gallery.getAlbum(payload)
})

const fetchPaginatedMedia = createAsyncThunk('/fetchPaginatedMedia', async (payload: GetMediaPayload) => {
  return await appAPI.gallery.getMedia(payload)
})

const albumSlice = createSlice({
  name: 'album',
  initialState: initialAlbumState,
  reducers: {
    setPage: (state: AlbumState, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    setContentType: (state: AlbumState, { payload }: PayloadAction<'image' | 'video' | undefined>) => {
      if (payload) localStorage.setItem('contentType', payload)
      state.contentType = payload
    },
    setMediaContent: (state: AlbumState, { payload }: PayloadAction<AlbumMedia[] | undefined>) => {
      state.mediaContent = payload
    },
    clearPics: () => initialAlbumState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlbumData.pending, (state: AlbumState) => {
        state.loading = true
      })
      .addCase(fetchAlbumData.fulfilled, (state: AlbumState, { payload }) => {
        state.name = payload.data.name
        state.cover = payload.data.cover
        state.videosCount = payload.data.videosCount
        state.imagesCount = payload.data.imagesCount
        state.created = convertDateToRuFormat(payload.data.created)
        state.description = payload.data.description || ''
        state.loading = false
      })
      .addCase(fetchAlbumData.rejected, (state: AlbumState) => {
        state.loading = false
      })

    builder
      .addCase(fetchPaginatedMedia.pending, (state: AlbumState) => {
        state.loadingMedia = true
      })
      .addCase(fetchPaginatedMedia.fulfilled, (state: AlbumState, { payload }) => {
        state.pages = payload.data.pages || 1
        state.mediaContent = [...(state.mediaContent ? state.mediaContent : []), ...(payload.data.results || [])]
        state.loadingMedia = false
      })
      .addCase(fetchPaginatedMedia.rejected, (state: AlbumState) => {
        state.loadingMedia = false
      })
  },
})

export default albumSlice.reducer

export const albumSliceActions = {
  ...albumSlice.actions,
  fetchAlbumData,
  fetchPaginatedMedia,
}
