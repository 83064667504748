import React, { FC } from 'react'
import { TabPaneProps, TabsProps } from 'antd/lib/tabs'
import { Tabs as AntTabs } from 'antd'
import './tabs.ant.scss'

interface TabsComponent extends FC<TabsProps> {
  TabPane: (props: TabPaneProps) => JSX.Element
}

const Tabs: TabsComponent = (props) => {
  return (
    <AntTabs className={'ant-tabs-custom'} {...props}>
      {props.children}
    </AntTabs>
  )
}

Tabs.TabPane = AntTabs.TabPane
export default Tabs
