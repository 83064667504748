import React from 'react'

const Sections: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 20L3 8.7C2.71667 8.51667 2.47933 8.28333 2.288 8C2.096 7.71667 2 7.38333 2 7L2 4C2 3.45 2.196 2.979 2.588 2.587C2.97933 2.19567 3.45 2 4 2L20 2C20.55 2 21.021 2.19567 21.413 2.587C21.8043 2.979 22 3.45 22 4V7C22 7.38333 21.904 7.71667 21.712 8C21.5207 8.28333 21.2833 8.51667 21 8.7L21 20C21 20.55 20.8043 21.021 20.413 21.413C20.021 21.8043 19.55 22 19 22L5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20ZM5 9L5 20L19 20L19 9L5 9ZM20 7V4L4 4L4 7L20 7ZM9 14H15V12H9V14Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Sections
