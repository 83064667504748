import React, { FC } from 'react'
import { Link as RouteLink } from 'react-router-dom'

type LinkProps = {
  to: string
  className?: string
  external?: boolean
  download?: string
}

const Link: FC<LinkProps> = (props) => {
  const { to, className, children, external, download } = props
  const host = window.location.host
  return (
    <>
      {new RegExp(host).test(to) && !external ? (
        <RouteLink to={to.slice(to.indexOf(host) + host.length)} className={className}>
          {children}
        </RouteLink>
      ) : (
        <a rel="noopener noreferrer" target={'_blank'} href={to} download={download} className={className}>
          {children}
        </a>
      )}
    </>
  )
}

export default Link
