import React, { FC } from 'react'
import { Input as AntInput } from 'antd'
import { InputProps, PasswordProps } from 'antd/lib/input'

import './input.ant.scss'

interface InputComponent extends FC<InputProps> {
  Password: React.ForwardRefExoticComponent<PasswordProps & React.RefAttributes<unknown>>
  TextArea: typeof AntInput.TextArea
}

const Input: InputComponent = (props) => {
  return <AntInput {...props} className={props.className} />
}

Input.TextArea = AntInput.TextArea
Input.Password = AntInput.Password
export default Input
