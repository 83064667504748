import { PayloadAction } from '@reduxjs/toolkit'
import { Col, Row, Spin } from 'antd'
import { RuleObject } from 'antd/lib/form'
import { NamePath } from 'antd/lib/form/interface'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Models from '../../../api/model/models'
import { Company, Competence } from '../../../api/model/models'
import useNeedCaptcha from '../../../hooks/useNeedCaptcha'
import { accountActions } from '../../../store/slices/accountSlice'
import { companiesActions } from '../../../store/slices/companiesSlice'
import { competenciesSliceActions } from '../../../store/slices/competenciesSlice'
import { RootState } from '../../../store/store'
import {
  emailRuValidator,
  MIN_LEN_PASSWORD,
  oneDigitCharValidator,
  oneRuOrEnLowerCharValidator,
  oneRuOrEnUpperCharValidator,
  onlyRuOrEnCharsValidator,
} from '../../../utils/ant-form-validators'
import { validateMessages } from '../../../utils/constants'
import { addCountlyEvent } from '../../../utils/stats'
import { Button, Form, Link, Modal } from '../../common/index'
import Field from './field'
import FieldSelect from './field-select'
import { roles_mock } from './mock_data'
import styles from './registration.module.scss'

const { Item, useForm } = Form

interface GetFieldValue {
  getFieldValue: (name: NamePath) => string
}

export interface Props<T> {
  tab: T
  selectTab: (tab: T) => void
}

interface Fields extends Models.RegistrationApplicationPost {
  confirm?: string
}

const Registration: FC<Props<TabHeader>> = (props) => {
  const dispatch = useDispatch()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const [showCustomCompanyField, setShowCustomCompanyField] = useState(false)

  const { needCaptcha, checkCaptchaResponse } = useNeedCaptcha()

  const competenciesOptions = useSelector<RootState, Competence[]>((state) => state.competencies.competencies)

  const companies = useSelector<RootState, Company[]>((state) => state.companies.companies)
  const companyNotExistOption = { id: 0, name: 'Моей организации нет в списке' }
  const companiesOptions: Company[] = [companyNotExistOption, ...companies]

  const { tab, selectTab } = props

  useEffect(() => {
    //  if we show the registration tab
    if (tab === 'registration') {
      dispatch(competenciesSliceActions.fetchCompetencies())
      dispatch(companiesActions.fetchAllCompanies())
    }
    return () => {
      form.resetFields()
    }
  }, [form, dispatch, tab])

  const handleSubmit = async (fields: Fields) => {
    setLoading(true)
    delete fields.confirm

    // clear company if user set customCompany
    if (fields.company && parseInt(fields.company as unknown as string) === companyNotExistOption.id) {
      delete fields.company
    }

    // sending request
    const payload = { ...fields } as Models.RegistrationApplicationPost
    const response = (await dispatch<unknown>(accountActions.registrationApplication(payload))) as PayloadAction<
      unknown,
      string,
      { payload: void; type: string },
      { message: string }
    >
    setLoading(false)

    if (
      checkCaptchaResponse(response, () => {
        form.scrollToField('captchaValue')
      })
    ) {
      return
    }

    addCountlyEvent(`Регистрация`, { Действие: 'Выполнена попытка регистрации', Результат: 'Неудачно' })

    if (response.type === 'registrationApplication/fulfilled') {
      form.resetFields()
      selectTab('hidden')
      addCountlyEvent(`Регистрация`, { Действие: 'Выполнена попытка регистрации', Результат: 'Удачно' })
      Modal.success({
        content:
          'Заявка на регистрацию успешно отправлена. После подтверждения администратором Вам на почту придет уведомление.',
      })
    }
  }

  const onChangeCompany = () => {
    const { getFieldValue, resetFields } = form
    const companyNotExistOptionSelected = parseInt(getFieldValue('company')) === companyNotExistOption.id

    if (companyNotExistOptionSelected) {
      setShowCustomCompanyField(true)
    } else if (!companyNotExistOptionSelected && showCustomCompanyField) {
      setShowCustomCompanyField(false)
      resetFields(['customCompany'])
    }
  }

  return (
    <Spin spinning={loading} className={styles.spin}>
      <Row justify="start">
        <Col className={styles.form}>
          <Form
            form={form}
            name="registration"
            layout="vertical"
            validateMessages={validateMessages}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Field
              label="Фамилия"
              name="lastName"
              autoComplete={'family-name'}
              rules={[{ required: true, whitespace: true, max: 30, validator: onlyRuOrEnCharsValidator }]}
              placeholder="Ваша фамилия"
            />
            <Field
              label="Имя"
              name="firstName"
              autoComplete={'given-name'}
              rules={[{ required: true, whitespace: true, max: 30, validator: onlyRuOrEnCharsValidator }]}
              placeholder="Ваше имя"
            />
            <Field
              label="Отчество"
              name="middleName"
              autoComplete={'additional-name'}
              rules={[{ whitespace: true, max: 30, validator: onlyRuOrEnCharsValidator }]}
              placeholder="Ваше отчество"
            />
            <FieldSelect
              label="Место работы"
              name="company"
              placeholder="Выберите ваше место работы"
              data={companiesOptions}
              value="id"
              content="name"
              rules={[{ required: true, whitespace: true }]}
              showSearch
              onChange={onChangeCompany}
            />
            {showCustomCompanyField && (
              <Field
                label={'Название вашей организации'}
                name="customCompany"
                rules={[{ required: true, max: 150 }]}
                placeholder="Введите название вашей организации"
              />
            )}
            <Field
              label="Должность"
              name="position"
              autoComplete={'organization-title'}
              rules={[{ required: true, whitespace: true, max: 70 }]}
              placeholder="Ваша должность"
            />
            <Field
              label="Электронный адрес"
              name="email"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  max: 250,
                  validator: emailRuValidator,
                },
              ]}
              placeholder="Почта"
            />
            <FieldSelect
              label="Компетенция"
              name="competence"
              rules={[{ required: true, whitespace: true }]}
              showSearch
              placeholder="Выберите вашу компетенцию"
              data={competenciesOptions}
              content="name"
              value="id"
            />
            <FieldSelect
              label="Роль"
              showSearch
              placeholder="Выберите вашу роль"
              name="competenceRole"
              rules={[{ required: true, whitespace: true, message: 'Роль обязательное поле!' }]}
              content="name"
              data={roles_mock}
              value="id"
            />
            <Field
              label="Имя пользователя"
              name="username"
              rules={[{ required: true, whitespace: true, max: 30 }]}
              placeholder="Ваше имя"
              autoComplete={'username'}
            />
            <Field
              name="password"
              label="Пароль"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  min: MIN_LEN_PASSWORD,
                },
                {
                  validator: oneRuOrEnLowerCharValidator,
                },
                {
                  validator: oneRuOrEnUpperCharValidator,
                },
                {
                  validator: oneDigitCharValidator,
                },
              ]}
              type="password"
              placeholder="Пароль"
              autoComplete="new-password"
            />
            <Field
              name="confirm"
              label="Подтверждение пароля"
              dependencies={['password']}
              type="password"
              placeholder="Подтверждение пароля"
              autoComplete="new-password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
                ({ getFieldValue }: GetFieldValue) => ({
                  validator: (rule: RuleObject, value: string) => {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Два введенных вами пароля не совпадают!')
                  },
                }),
              ]}
            />

            {needCaptcha && <Form.Captcha form={form} />}
            <Item>
              Нажимая «ЗАРЕГИСТРИРОВАТЬСЯ», Вы соглашаетесь c условиями{' '}
              <Link to={'/terms-of-service'}>Пользовательского соглашения</Link> и{' '}
              <Link to={'/confidentiality-policy'}>Политика в отношении обработки персональных данных</Link>
            </Item>
            <Item>
              <Button type="primary" htmlType="submit" className={styles.submitButton}>
                ЗАРЕГИСТРИРОВАТЬСЯ
              </Button>
            </Item>
          </Form>
        </Col>
      </Row>
    </Spin>
  )
}

export default Registration
