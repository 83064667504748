import React, { FC } from 'react'
import { Form as AntForm } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import './Item.ant.scss'

const Item: FC<FormItemProps> = (props) => {
  return <AntForm.Item required={false} {...props} />
}

export default Item
