import React from 'react'

const Questions: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.538 14.462C20.5627 13.4873 19.3833 13 18 13C16.6167 13 15.4377 13.4873 14.463 14.462C13.4877 15.4373 13 16.6167 13 18C13 19.3833 13.4877 20.5627 14.463 21.538C15.4377 22.5127 16.6167 23 18 23C19.3833 23 20.5627 22.5127 21.538 21.538C22.5127 20.5627 23 19.3833 23 18C23 16.6167 22.5127 15.4373 21.538 14.462ZM18.45 19.1V18.85C18.45 18.6667 18.5 18.504 18.6 18.362C18.7 18.2207 18.8167 18.0833 18.95 17.95C19.1833 17.75 19.3667 17.5583 19.5 17.375C19.6333 17.1917 19.7 16.9333 19.7 16.6C19.7 16.1167 19.5417 15.7293 19.225 15.438C18.9083 15.146 18.5 15 18 15C17.6167 15 17.271 15.1123 16.963 15.337C16.6543 15.5623 16.4333 15.8667 16.3 16.25L17.1 16.6C17.15 16.4 17.2543 16.225 17.413 16.075C17.571 15.925 17.7667 15.85 18 15.85C18.25 15.85 18.4457 15.9123 18.587 16.037C18.729 16.1623 18.8 16.35 18.8 16.6C18.8 16.7833 18.75 16.9373 18.65 17.062C18.55 17.1873 18.4333 17.3167 18.3 17.45C18.2 17.55 18.096 17.65 17.988 17.75C17.8793 17.85 17.7833 17.9667 17.7 18.1C17.65 18.2 17.6123 18.3 17.587 18.4C17.5623 18.5 17.55 18.6167 17.55 18.75V19.1H18.45ZM18.463 20.812C18.3377 20.9373 18.1833 21 18 21C17.8167 21 17.6627 20.9373 17.538 20.812C17.4127 20.6873 17.35 20.5333 17.35 20.35C17.35 20.1667 17.4127 20.0123 17.538 19.887C17.6627 19.7623 17.8167 19.7 18 19.7C18.1833 19.7 18.3377 19.7623 18.463 19.887C18.5877 20.0123 18.65 20.1667 18.65 20.35C18.65 20.5333 18.5877 20.6873 18.463 20.812Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 9L18 9V7L6 7L6 9ZM18 23C16.6167 23 15.4377 22.5127 14.463 21.538C13.4877 20.5627 13 19.3833 13 18C13 16.6167 13.4877 15.4373 14.463 14.462C15.4377 13.4873 16.6167 13 18 13C19.3833 13 20.5627 13.4873 21.538 14.462C22.5127 15.4373 23 16.6167 23 18C23 19.3833 22.5127 20.5627 21.538 21.538C20.5627 22.5127 19.3833 23 18 23ZM6 17H11.075C11.125 16.65 11.2 16.3083 11.3 15.975C11.4 15.6417 11.525 15.3167 11.675 15L6 15V17ZM6 13L13.1 13C13.7333 12.3833 14.4707 11.8957 15.312 11.537C16.154 11.179 17.05 11 18 11L6 11V13ZM18.45 19.1V18.85C18.45 18.6667 18.5 18.504 18.6 18.362C18.7 18.2207 18.8167 18.0833 18.95 17.95C19.1833 17.75 19.3667 17.5583 19.5 17.375C19.6333 17.1917 19.7 16.9333 19.7 16.6C19.7 16.1167 19.5417 15.7293 19.225 15.438C18.9083 15.146 18.5 15 18 15C17.6167 15 17.271 15.1123 16.963 15.337C16.6543 15.5623 16.4333 15.8667 16.3 16.25L17.1 16.6C17.15 16.4 17.2543 16.225 17.413 16.075C17.571 15.925 17.7667 15.85 18 15.85C18.25 15.85 18.4457 15.9123 18.587 16.037C18.729 16.1623 18.8 16.35 18.8 16.6C18.8 16.7833 18.75 16.9373 18.65 17.062C18.55 17.1873 18.4333 17.3167 18.3 17.45C18.2 17.55 18.096 17.65 17.988 17.75C17.8793 17.85 17.7833 17.9667 17.7 18.1C17.65 18.2 17.6123 18.3 17.587 18.4C17.5623 18.5 17.55 18.6167 17.55 18.75V19.1H18.45ZM18.463 20.812C18.3377 20.9373 18.1833 21 18 21C17.8167 21 17.6627 20.9373 17.538 20.812C17.4127 20.6873 17.35 20.5333 17.35 20.35C17.35 20.1667 17.4127 20.0123 17.538 19.887C17.6627 19.7623 17.8167 19.7 18 19.7C18.1833 19.7 18.3377 19.7623 18.463 19.887C18.5877 20.0123 18.65 20.1667 18.65 20.35C18.65 20.5333 18.5877 20.6873 18.463 20.812Z"
          fill={color}
        />
        <path
          d="M3 5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3L19 3C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V11.675C20.6833 11.525 20.3583 11.4 20.025 11.3C19.6917 11.2 19.35 11.125 19 11.075V5L5 5L5 19.05H11.075C11.1583 19.5667 11.2877 20.0583 11.463 20.525C11.5234 20.6863 11.5903 20.8447 11.6636 21H5C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19L3 5Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Questions
