import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import appAPI from '../../api/api'
import {
  AnswerType,
  ArrayResultsType,
  CurrentAttemptsType,
  CurrentDataDetailType,
  CurrentTimerType,
  QuestionType,
  ResultsType,
  Statuses,
  TestDataType,
  TestMode,
  TestsDataType,
  UserAnswersType,
} from '../../components/survey/types'
import { declineWord } from '../../utils/declineWord'
import { message } from 'antd'

type Payload = { userId: number; attemptId: number }
type FetchDetailPayload = Payload & { surveyId: number; notStartTimer?: boolean }
type SendAnswersPayload = Payload & { resultsArray: ArrayResultsType }

export type SurveyState = {
  loading: boolean
  loadingAnswers: boolean
  tests: TestsDataType
  currentTest: TestDataType
  attempts: CurrentAttemptsType
  answers: UserAnswersType
  timer: CurrentTimerType | undefined
  surveyDetail: CurrentDataDetailType
  surveyPreLoadDetail: CurrentDataDetailType
  progress: Array<ResultsType>
  showQuestionList: boolean
  current: QuestionType | undefined | null
  oldCurrent: QuestionType | undefined | null
  loopQuestion: Array<QuestionType>
  isCompleted: boolean
  edited: boolean
  mode: TestMode
}

const initialSurveyState: SurveyState = {
  loading: false,
  loadingAnswers: false,
  tests: [],
  attempts: [],
  answers: [],
  timer: undefined,
  currentTest: {} as TestDataType,
  surveyDetail: {} as CurrentDataDetailType,
  surveyPreLoadDetail: {} as CurrentDataDetailType,
  progress: [],
  showQuestionList: true,
  current: null,
  oldCurrent: null,
  loopQuestion: [],
  isCompleted: false,
  edited: false,
  mode: TestMode.test,
}

const fetchTestsList = createAsyncThunk('/fetchTestsList', async (payload: FetchUserSyrveyType) =>
  appAPI.survey.getTestsForUser(payload),
)

const fetchAttempts = createAsyncThunk('/fetchAttempts', async (userId: number) =>
  appAPI.survey.getUserAttempts(userId),
)

const fetchUserAnswers = createAsyncThunk(
  '/fetchUserAnswers',
  async ({ userId, surveyId, attemptId }: FetchDetailPayload) => {
    const answers = (await appAPI.survey.getUserAnswers(userId, surveyId, attemptId)).data.results
    const questions = (await appAPI.survey.getQuestions(surveyId)).data.results
    return { answers, questions }
  },
)

const fetchTestDetail = createAsyncThunk(
  '/fetchTestDetail',
  async ({ userId, attemptId, surveyId, notStartTimer }: FetchDetailPayload) => {
    let resultTimer

    if (!notStartTimer) {
      resultTimer = (await appAPI.survey.getTimer(userId, attemptId)).data

      if (!resultTimer || resultTimer.detail)
        return { result: {} as CurrentDataDetailType, resultTimer: {} as CurrentTimerType }

      if (resultTimer.statusStart || resultTimer.timerState === 1) {
        await appAPI.survey.runTimer(userId, attemptId)
        resultTimer = (await appAPI.survey.getTimer(userId, attemptId)).data
      }
    }
    const answers = resultTimer?.userAnswers?.map((x) => ({ question: x[0] as number, answer: x[1] }))
    const result = await (await appAPI.survey.getTestDetail(userId, surveyId)).data

    return { result, resultTimer, answers }
  },
)

const sendSurveyResultAnswer = createAsyncThunk(
  '/sendSurveyResultAnswer',
  async ({ userId, attemptId, resultsArray }: SendAnswersPayload) => {
    try {
      await appAPI.survey.sendTestAnswers(resultsArray, userId, attemptId)
    } catch (err) {
      let error = ''
      if (err instanceof Error && err.message === 'Network Error') {
        error = `Детали ошибки: Ошибка соединения с сетью\n`
      } else if (axios.isAxiosError(err)) {
        error = `Детали ошибки: ${JSON.stringify(err.response?.data)}\nСодержимое запроса: ${JSON.stringify(
          err.response?.config.data,
        )}\n`
      } else {
        error = `Детали ошибки: ${JSON.stringify(err)}`
      }
      message.error(`Произошла ошибка, могли сохраниться не все результаты\n${error}`)
      throw err
    }
  },
)

const stopTimer = createAsyncThunk('sendStopTimer', async ({ userId, attemptId }: Payload) => {
  await appAPI.survey.stopTimer(userId, attemptId)
})

const surveySlice = createSlice({
  name: 'survey',
  initialState: initialSurveyState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loadingAnswers = false
      state.tests = []
      state.attempts = []
      state.answers = []
      state.timer = undefined
      state.currentTest = {} as TestDataType
      state.surveyDetail = {} as CurrentDataDetailType
      state.surveyPreLoadDetail = {} as CurrentDataDetailType
      state.progress = []
      state.showQuestionList = true
      state.current = { id: 0 } as QuestionType
      state.oldCurrent = { id: 0 } as QuestionType
      state.edited = false
      state.isCompleted = false
      state.loopQuestion = []
      state.mode = TestMode.test
    },
    setMode: (state, { payload }: PayloadAction<TestMode>) => {
      state.mode = payload
    },
    setSurveyDetail: (state, { payload }: PayloadAction<CurrentDataDetailType>) => {
      state.surveyDetail = payload
      if (!payload.allowPrevious && state.progress.length !== 0) {
        const loop = payload.questions.filter((x) => !state.progress.some((s) => s.question === x.id))
        state.loopQuestion = loop

        state.current = loop[0]
      } else {
        const firstUnaskedQuestion = payload.questions.findIndex(
          (x) => !state.progress?.some((s) => s.question === x.id),
        )
        state.current = payload.questions[firstUnaskedQuestion > 0 ? firstUnaskedQuestion : 0]
        state.loopQuestion = payload.questions
      }
    },
    addProgress: (state, { payload }: PayloadAction<ResultsType>) => {
      if (payload.question === state.surveyDetail.questions[state.surveyDetail.questions.length - 1].id)
        state.isCompleted = true

      state.progress = state.progress.filter((x) => x.question !== payload.question)
      state.loopQuestion = state.loopQuestion.filter((x) => x.id !== payload.question)

      state.progress.push(payload)
      state.edited = false
    },
    removeProgress: (state, { payload }: PayloadAction<ResultsType>) => {
      state.progress = state.progress.filter((x) => x.question !== payload.question)
    },
    toggleQuestionList: (state) => {
      state.showQuestionList = !state.showQuestionList
    },
    setCurrentQuestion: (state, { payload }: PayloadAction<QuestionType>) => {
      state.oldCurrent = state.current
      state.edited = false
      //if (state.progress.some((x) => x.question === payload.id)) state.edited = true
      state.current = payload
    },
    editedQuestion: (state) => {
      state.edited = true
    },
    surveyComplete: (state) => {
      state.current = initialSurveyState.current
    },
    setCurrentTest: (state, { payload }: PayloadAction<TestDataType>) => {
      state.currentTest = payload
    },
  },
  extraReducers: (builder) => {
    builder
      // -------------------- получение списка тестов
      .addCase(fetchTestsList.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchTestsList.fulfilled, (state, { payload: { data } }) => {
        const tests = data.map((test) => {
          if (Object.keys(test.userAttempt).length === 0) {
            test.userAttempt = {
              timer: 0,
              currentAttempt: 0,
              dateStarted: 0,
              testPeriodTimer: 0,
            }
          }
          // сколько времени осталось до запуска тестирования
          if (typeof test.dateToStart === 'string') {
            const moreThenDay = test.dateToStart.includes(' ')
            if (moreThenDay) {
              const days = test.dateToStart.split(' ')[0]
              const declineDay = declineWord(Number(days), ['день', 'дня', 'дней'])
              const time = test.dateToStart.split(',')[1].split('.')[0]
              test.dateToStart = `${days} ${declineDay} ${time}`
            } else {
              const time = test.dateToStart.split('.')[0]
              test.dateToStart = time
            }
          }
          // сколько осталось попыток
          const remainAttempt = test.rule.maxAttempts - test.userAttempt.currentAttempt
          test.remainAttempts = remainAttempt

          // проходит ли сейчас тестирование, идёт ли сейчас интервал между тестированиями
          const testNotStarted = remainAttempt > 0 && test.dateToStart === 0 && test.userAttempt.testPeriodTimer === 0
          const noAttempts =
            remainAttempt === 0 &&
            test.dateToStart === 0 &&
            test.userAttempt.testPeriodTimer === 0 &&
            test.userAttempt.timer !== 0
          const testPaused = test.userAttempt.testPeriodTimer !== 0
          if (testNotStarted || noAttempts) {
            test.active = 1
          } else if (testPaused) {
            test.userAttempt.testPeriodTimer = test.userAttempt.testPeriodTimer.toString().split('.')[0]
          } else {
            test.active = 0
          }
          // сколько времени из тестирования прошло
          const timerNotStarted = test.userAttempt.timer === 0 || test.userAttempt.timer === '0:00:00'
          const timerStarted =
            test.userAttempt.timer &&
            typeof test.userAttempt.timer === 'string' &&
            Number(test.userAttempt.timer) !== test.test.duration
          if (timerNotStarted) {
            test.userAttempt.timer = test.test.duration
          } else if (timerStarted) {
            const time = test.userAttempt.timer!.toString().split('.')[0].split(':')
            const timeMinutes = Number(time[0]) * 60 + Number(time[1])
            test.userAttempt.timer = timeMinutes
          }
          return test
        })
        state.tests = tests
        state.loading = false
      })
      .addCase(fetchTestsList.rejected, (state) => {
        state.loading = false
      })

      // -------------------- получение результатов тестирований
      .addCase(fetchAttempts.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAttempts.fulfilled, (state, { payload: { data } }) => {
        const attempt = data.map((item) => {
          item.fullAttempts = []
          let currentAttempt = 0
          if (item.userAttempt.length) {
            const participantAttempts = item.userAttempt.map((attempt) => attempt.participantsAttempts).flat()
            item.fullAttempts.push(participantAttempts)
            participantAttempts.forEach((attempt) => {
              currentAttempt++
              const date = new Date(attempt.dateCreated).toLocaleDateString('ru')
              attempt.testId = item.test.id
              attempt.testTitle = item.test.title
              attempt.successPercent = item.test.successPercent ?? 0
              attempt.maxPoints = item.testMaxPoints
              attempt.date = date
              attempt.currentAttempt = currentAttempt
            })
          }
          return item
        })
        state.attempts = attempt
        state.loading = false
      })
      .addCase(fetchAttempts.rejected, (state) => {
        state.loading = false
      })

      // -------------------- получение детальных результатов конкретной попытки
      .addCase(fetchUserAnswers.pending, (state) => {
        state.answers = []
        state.loadingAnswers = true
      })
      .addCase(fetchUserAnswers.fulfilled, (state, { payload: { answers, questions } }) => {
        const questionsAndAnswers = questions.map((currentQuestion) => {
          const questionAndAnswer = { question: '', answerText: '', isCorrect: 'Нет ответа' }
          questionAndAnswer.question =
            currentQuestion.text!.indexOf(' <img ') !== -1
              ? currentQuestion.text!.slice(0, currentQuestion.text!.indexOf(' <img '))
              : currentQuestion.text!

          if (!answers) {
            return questionAndAnswer
          }
          const answersToСurrentQuestion = answers.find((answer) => answer.question === currentQuestion.id)
          if (!answersToСurrentQuestion) {
            return questionAndAnswer
          }
          const answer = answersToСurrentQuestion.answer
          questionAndAnswer.answerText =
            typeof answer === 'string'
              ? answer
              : answer
                  .map(
                    (item) =>
                      currentQuestion.answers.find((currentAnswer) => currentAnswer.id === Number(item))!.answer,
                  )
                  .join(', ')
          questionAndAnswer.isCorrect = answersToСurrentQuestion.isCorrect
          return questionAndAnswer
        })
        state.answers = questionsAndAnswers
        state.loadingAnswers = false
      })
      .addCase(fetchUserAnswers.rejected, (state) => {
        state.loadingAnswers = false
      })

      // -------------------- получение данных тестирования
      .addCase(fetchTestDetail.pending, (state) => {
        state.timer = undefined
        state.loading = true
        state.progress = []
      })
      .addCase(fetchTestDetail.fulfilled, (state, { payload: { result, resultTimer, answers } }) => {
        if (result.status || result.active || result.error) {
          state.surveyPreLoadDetail = result
          state.timer = resultTimer
        }
        const questions = (result.questions ?? []).map((item: QuestionType) => {
          const { text, ...rest } = item
          const question = {
            ...rest,
            name: text,
            choices: item.answers.map((answer: AnswerType) => answer.answer),
          }
          if (item.type === 'range') {
            question.validators = [
              {
                type: 'regex',
                text: 'Требуется ввести корректное цифровое значение',
                regex: '^\\d*([\\.\\,]\\d+)?$',
              },
            ]
            question.type = 'text'
          }
          return question
        })

        if (answers && answers.length !== 0) state.progress = answers
        state.surveyPreLoadDetail = { ...result, questions }

        state.timer = resultTimer
        state.loading = false
      })
      .addCase(fetchTestDetail.rejected, (state) => {
        state.loading = false
      })
      // -------------------- отправка ответов
      .addCase(sendSurveyResultAnswer.pending, (state) => {
        state.loading = true
      })
      .addCase(sendSurveyResultAnswer.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(sendSurveyResultAnswer.rejected, (state) => {
        state.loading = false
      })
      .addCase(stopTimer.pending, (state) => {
        state.timer = undefined
        state.progress = initialSurveyState.progress
        state.current = initialSurveyState.current
        state.oldCurrent = initialSurveyState.oldCurrent
        state.edited = initialSurveyState.edited
      })
  },
})

export default surveySlice.reducer

export const surveySliceActions = {
  ...surveySlice.actions,
  fetchTestsList,
  fetchAttempts,
  fetchUserAnswers,
  fetchTestDetail,
  sendSurveyResultAnswer,
  stopTimer,
}

export type FetchUserSyrveyType = {
  status: Statuses
  userId: number
}
