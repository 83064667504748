import React, { FC } from 'react'
import { Switch as AntSwitch } from 'antd'
import { SwitchProps } from 'antd/lib/switch'
import './switch.ant.scss'

const Switch: FC<SwitchProps> = (props) => {
  return <AntSwitch {...props} />
}

export default Switch
