import appAPI from '../../api/api'
import { QuestionEditSection, SectionFull, SectionType, QuestionsTypes } from '../../components/survey/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EditSectionType } from '../../components/survey/survey-sections/edit-section/edit-section'

type SectionStateType = {
  sectionList: DataLoad<SectionType[]>
  fullSection: DataLoad<SectionFull>
}

const initialState: SectionStateType = {
  sectionList: {
    loading: false,
    data: [],
  },
  fullSection: {
    loading: false,
    data: {
      sectionQuestions: [],
      name: '',
      competences: [],
      id: 0,
    },
  },
}

const fetchSections = createAsyncThunk('/fetchSections', async (payload: Paginated) => {
  return await appAPI.sections.get(payload)
})
const updateSection = createAsyncThunk('/sendSection', async (payload: EditSectionType) => {
  const section: ApiSection = {
    id: payload.id,
    name: payload.name,
    questionsCount: 0,
    sectionQuestions: (payload.questions.filter((x) => x !== null) as number[]).map((x): QuestionEditSection => {
      return {
        id: x,
        competences: [payload.competence[0]!],
        file: null,
        text: '1',
        section: payload.id,
        sectionId: payload.id,
        competenceId: 0,
        score: 0,
        type: QuestionsTypes.text,
        answers: [],
        points: 0,
      }
    }),
    competences: payload.competence.filter((x) => x !== null) as number[],
  }
  if (payload.id === 0) return await appAPI.sections.create(section)
  else return await appAPI.sections.update(section)
})
const deleteSection = createAsyncThunk('/deleteSection', async (payload: number) => {
  return await appAPI.sections.deleteSection(payload)
})
const fetchDetailSection = createAsyncThunk('/fetchDetailSections', async (id: number) => {
  return await appAPI.sections.getFull(id)
})

const SectionSlice = createSlice({
  name: 'sectionSlice',
  initialState,
  reducers: {
    clearSection: (state) => {
      state.sectionList = initialState.sectionList
    },
    clearFullSection: (state) => {
      state.fullSection = initialState.fullSection
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSections.pending, (state) => {
        state.sectionList.loading = true
      })
      .addCase(fetchSections.fulfilled, (state, { payload }) => {
        state.sectionList.loading = false
        state.sectionList.data = payload.data
      })
      .addCase(fetchSections.rejected, (state) => {
        state.sectionList.loading = false
      })
      .addCase(fetchDetailSection.pending, (state) => {
        state.fullSection.loading = true
      })
      .addCase(fetchDetailSection.fulfilled, (state, { payload }) => {
        state.fullSection.loading = false
        state.fullSection.data = payload.data
      })
      .addCase(fetchDetailSection.rejected, (state) => {
        state.fullSection.loading = false
      })
      .addCase(deleteSection.fulfilled, (state, payload) => {
        state.sectionList.data = state.sectionList.data.filter((x) => x.id !== payload.meta.arg)
      })
  },
})

export default SectionSlice.reducer

export const sectionSliceActions = {
  ...SectionSlice.actions,
  fetchDetailSection,
  fetchSections,
  deleteSection,
  updateSection,
}

export type ApiSection = {
  id: number
  name: string
  competences: number[]
  sectionQuestions: QuestionEditSection[]
  questionsCount: number
}

type Competence = {
  id: number
  name: string
  pictogram: string
  description: string
}
