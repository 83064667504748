import React, { FC, useState, TouchEvent, useRef } from 'react'
import styles from './swipe-modal.module.scss'

type SwipeModalProps = {
  className?: string
  onClose?: () => void
}

const SwipeModal: FC<SwipeModalProps> = (props) => {
  const { children, className, onClose } = props

  const firstTouchY = useRef<number | null>(null)
  const [posY, setPosY] = useState(0)

  const onTouchCancel = () => {
    firstTouchY.current = null
    setPosY(0)
  }

  const onTouchEnd = () => {
    if (!firstTouchY.current) return
    firstTouchY.current = null
    setPosY(0)
  }

  const onTouchMove = (event: TouchEvent<HTMLDivElement>) => {
    if (!firstTouchY.current) return

    const yDiff = (firstTouchY.current || 0) - event.touches[0].clientY

    if (yDiff > 0) return

    if (yDiff < -100) {
      /* down swipe */
      setPosY(window.innerHeight)
      firstTouchY.current = null
      setTimeout(() => {
        onClose && onClose()
      }, 200)
    } else setPosY(100 - yDiff)

    event.stopPropagation()
  }

  const onTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    firstTouchY.current = event.touches[0].clientY
    event.stopPropagation()
  }

  return (
    <div className={styles.swipeBackdrop} onClick={onClose}>
      <div
        className={[styles.swipeContainer, className].join(' ')}
        style={{ bottom: -posY || 0 }}
        onTouchCancel={onTouchCancel}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}
        onClick={(event) => event.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
}

export default SwipeModal
