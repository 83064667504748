import React, { FC, useState, useEffect, useRef, useCallback } from 'react'
import { Spin } from 'antd'

import styles from './image.module.scss'
import './image.ant.scss'

export interface PropsImageGallery {
  src: string
  alt: string
  visible?: boolean
}

type Picture = {
  loading: boolean
  loaded: boolean
  error: boolean
  width: number | string
  height: number | string
  image: HTMLImageElement | undefined
}

const initPicture = {
  loading: false,
  loaded: false,
  error: false,
  width: 0,
  height: 0,
  image: undefined,
}

const ImageGallery: FC<PropsImageGallery> = ({ src, alt, visible = false }) => {
  const imgBoxRef = useRef<HTMLDivElement | null>()

  const [state, setState] = useState<Picture>(initPicture)

  const pictureSizeCalculation = useCallback((image: HTMLImageElement | undefined) => {
    let width = 0,
      height = 0
    if (!image) return
    const mainWidth = imgBoxRef?.current?.clientWidth || 0
    const mainHeight = imgBoxRef?.current?.clientHeight || 0
    if (window.innerWidth <= 768) return { width: 'auto', height: 'auto' }
    const proportions = image.width / image.height

    const calculatedWidth = mainHeight * proportions
    if (calculatedWidth <= mainWidth) {
      //  by container height and calculated width
      //setSize(link, calculatedWidth, mainHeight)
      width = calculatedWidth
      height = mainHeight
    } else {
      //  along the width of the container and the calculated height
      const calculatedHeight = (1 / proportions) * mainWidth
      //setSize(link, mainWidth, calculatedHeight)
      width = mainWidth
      height = calculatedHeight
    }

    return { width, height }
  }, [])

  const putOnLoad = useCallback(() => {
    const image = new Image()
    setState((prevState) => ({ ...prevState, loading: true, image }))
    image.onload = (e: Event) =>
      setState((prevState) => ({ ...prevState, loading: false, loaded: true, ...pictureSizeCalculation(image) }))
    image.onerror = () => {
      console.error('Error loading pictures:', src)
      setState((prevState) => ({ ...prevState, loading: false, error: true }))
    }
    image.src = src
  }, [setState, src, pictureSizeCalculation])

  useEffect(() => {
    if (visible && !state.loaded && !state.loading && !state.error) {
      putOnLoad()
    }
  }, [visible, state, putOnLoad])

  useEffect(() => {
    const callback = () => {
      setState((prevState) => ({ ...prevState, ...pictureSizeCalculation(state.image) }))
    }
    if (visible && state.image) {
      window.addEventListener('resize', callback)
    } else {
      window.removeEventListener('resize', callback)
    }
  }, [setState, pictureSizeCalculation, state.image, visible])

  return (
    <div className={[styles.boxImage, 'popup-gallery-image'].join(' ')} ref={(box) => (imgBoxRef.current = box)}>
      {state.loading && <Spin size="large" tip="Загрузка..." />}
      {state.loaded && !state.loading && (
        <img src={src} className={styles.img} style={{ width: state.width, height: state.height }} alt={alt} />
      )}
      {state.error && <div className={styles.brokenPicture} />}
    </div>
  )
}

export default ImageGallery
