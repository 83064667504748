import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { Competence } from '../../api/model/competence'

export type CompetenciesState = {
  competencies: Competence[]
  loading: boolean
}

const initialCompetenciesState: CompetenciesState = {
  competencies: [],
  loading: false,
}

const fetchCompetencies = createAsyncThunk('/fetchCompetencies', async () => {
  return await appAPI.competences.getCompetences<Competence[]>()
})

const competenciesSlice = createSlice({
  name: 'competencies',
  initialState: initialCompetenciesState,
  reducers: {
    clearCompetencies: (state: CompetenciesState) => {
      state.competencies = initialCompetenciesState.competencies
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetencies.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchCompetencies.fulfilled, (state: CompetenciesState, { payload }) => {
        state.competencies = payload.data
        state.loading = false
      })
      .addCase(fetchCompetencies.rejected, (state: CompetenciesState) => {
        state.loading = false
      })
  },
})

export default competenciesSlice.reducer

export const competenciesSliceActions = {
  ...competenciesSlice.actions,
  fetchCompetencies,
}
