import React from 'react'
import styles from './content-section.module.scss'

type ContentSectionProps = {
  children: React.ReactNode
  padding?: 10 | 12 | 14 | 16
  minHeight?: number
}

const ContentSection: React.FC<ContentSectionProps> = (props) => {
  const { children, padding = 10, minHeight = 0 } = props
  return (
    <section className={styles.section} style={{ padding: padding, minHeight: minHeight }}>
      {children}
    </section>
  )
}

export default ContentSection
