import React, { FC } from 'react'
import { Spin } from 'antd'
import { useSwipeModal } from '../../../hooks/use-swipe-modal'
import styles from './block-list-filter.module.scss'
import { Block, Button } from '..'
import { Tune } from '../icons'
import SwipeModal from '../swipe-modal/swipe-modal'
import { Title } from '../text'
import RadioGroup from '../radio-group/radio-group'

type PropsBlockListFilter = {
  title: string
  list: Array<string | { value: string; title: string }>
  currentItem?: string
  loading?: boolean
  hasMobileView?: boolean
  onChangeItem?: (item: string | undefined) => void
  className?: string
}

const BlockListFilter: FC<PropsBlockListFilter> = (props) => {
  const { title, list, onChangeItem, loading = false, currentItem, hasMobileView = true, className } = props
  const swipeModal = useSwipeModal()
  const handleChange = (item?: string) => onChangeItem && onChangeItem(item)

  return (
    <div className={`${styles.wrapper} ${hasMobileView ? styles.wrapper_mobile : ''} ${className || ''}`}>
      <Spin spinning={loading}>
        <div className={`${styles.dropdown} ${hasMobileView ? styles.dropdown_mobile : ''}`}>
          <Button icon={<Tune />} onClick={swipeModal.onShow} className={styles.tune} />
          {swipeModal.isShow && (
            <SwipeModal onClose={swipeModal.onClose}>
              <Title level={4}>{title}</Title>
              <RadioGroup value={currentItem} className={styles.radio}>
                {list?.map((item) =>
                  typeof item === 'string' ? (
                    <RadioGroup.Radio value={item} onClick={() => handleChange(item)}>
                      {item}
                    </RadioGroup.Radio>
                  ) : (
                    <RadioGroup.Radio
                      key={'key_radio_' + item.value}
                      value={item.value}
                      onClick={() => handleChange(item.value)}
                    >
                      {item.title}
                    </RadioGroup.Radio>
                  ),
                )}
              </RadioGroup>
              <Button className={styles.accept} type={'primary'} onClick={swipeModal.onClose}>
                Применить
              </Button>
            </SwipeModal>
          )}
        </div>
        <div className={`${styles.radio_buttons} ${hasMobileView ? styles.radio_buttons_mobile : ''}`}>
          <Block loading={loading} title={<Title level={4}>{title}</Title>} bodyClassName={styles.block_body}>
            <RadioGroup buttonStyle="solid" value={currentItem}>
              {list?.map((item) =>
                typeof item === 'string' ? (
                  <RadioGroup.Button value={item} onClick={() => handleChange(item)}>
                    {item}
                  </RadioGroup.Button>
                ) : (
                  <RadioGroup.Button
                    key={'key_radio_' + item.value}
                    value={item.value}
                    onClick={() => handleChange(item.value)}
                  >
                    {item.title}
                  </RadioGroup.Button>
                ),
              )}
            </RadioGroup>
          </Block>
        </div>
      </Spin>
    </div>
  )
}

export default BlockListFilter
