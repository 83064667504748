import React, { FC } from 'react'
import { List as AntList } from 'antd'
import { ListItemProps, ListItemMetaProps } from 'antd/lib/list'
import './item.ant.scss'

export type ItemType = FC<ListItemProps> & { Meta: FC<ListItemMetaProps> }

const Item: ItemType = (props) => {
  return <AntList.Item {...props}>{props.children}</AntList.Item>
}

Item.Meta = AntList.Item.Meta

export default Item
