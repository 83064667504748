import React from 'react'

const Group: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 18L0 16.425C0 15.6917 0.371 15.104 1.113 14.662C1.85433 14.2207 2.81667 14 4 14C4.21667 14 4.425 14.004 4.625 14.012C4.825 14.0207 5.01667 14.0417 5.2 14.075C4.96667 14.4083 4.79167 14.7667 4.675 15.15C4.55833 15.5333 4.5 15.9417 4.5 16.375V18H0ZM6 18V16.375C6 15.2917 6.55433 14.4167 7.663 13.75C8.771 13.0833 10.2167 12.75 12 12.75C13.8 12.75 15.25 13.0833 16.35 13.75C17.45 14.4167 18 15.2917 18 16.375L18 18L6 18ZM19.5 18L19.5 16.375C19.5 15.9417 19.4457 15.5333 19.337 15.15C19.229 14.7667 19.0667 14.4083 18.85 14.075C19.0333 14.0417 19.221 14.0207 19.413 14.012C19.6043 14.004 19.8 14 20 14C21.2 14 22.1667 14.2207 22.9 14.662C23.6333 15.104 24 15.6917 24 16.425L24 18H19.5ZM12 14.75C11.05 14.75 10.2 14.875 9.45 15.125C8.7 15.375 8.25833 15.6667 8.125 16H15.9C15.75 15.6667 15.304 15.375 14.562 15.125C13.8207 14.875 12.9667 14.75 12 14.75ZM4 13C3.45 13 2.97933 12.804 2.588 12.412C2.196 12.0207 2 11.55 2 11C2 10.4333 2.196 9.95833 2.588 9.575C2.97933 9.19167 3.45 9 4 9C4.56667 9 5.04167 9.19167 5.425 9.575C5.80833 9.95833 6 10.4333 6 11C6 11.55 5.80833 12.0207 5.425 12.412C5.04167 12.804 4.56667 13 4 13ZM20 13C19.45 13 18.979 12.804 18.587 12.412C18.1957 12.0207 18 11.55 18 11C18 10.4333 18.1957 9.95833 18.587 9.575C18.979 9.19167 19.45 9 20 9C20.5667 9 21.0417 9.19167 21.425 9.575C21.8083 9.95833 22 10.4333 22 11C22 11.55 21.8083 12.0207 21.425 12.412C21.0417 12.804 20.5667 13 20 13ZM12 12C11.1667 12 10.4583 11.7083 9.875 11.125C9.29167 10.5417 9 9.83333 9 9C9 8.15 9.29167 7.43733 9.875 6.862C10.4583 6.28733 11.1667 6 12 6C12.85 6 13.5623 6.28733 14.137 6.862C14.7123 7.43733 15 8.15 15 9C15 9.83333 14.7123 10.5417 14.137 11.125C13.5623 11.7083 12.85 12 12 12ZM12 8C11.7167 8 11.4793 8.09567 11.288 8.287C11.096 8.479 11 8.71667 11 9C11 9.28333 11.096 9.52067 11.288 9.712C11.4793 9.904 11.7167 10 12 10C12.2833 10 12.521 9.904 12.713 9.712C12.9043 9.52067 13 9.28333 13 9C13 8.71667 12.9043 8.479 12.713 8.287C12.521 8.09567 12.2833 8 12 8Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Group
