import React, { useEffect, useState } from 'react'
import { Col, Radio, Row } from 'antd'
import { RadioChangeEvent } from 'antd/es'
import { useSelector } from 'react-redux'

import './one-of-choice.ant.scss'
import styles from './one-of-choice.module.scss'
import { TopicPollOption } from '../../../../../../../api/model/topicPollOption'
import { Form, Input } from '../../../../../../common'
import PollOptionLine from '../shared/poll-option-line'
import { selectTopicPollResultsInPercent } from '../../../../../../../store/slices/topicPollVotesSlice'
import { RootState } from '../../../../../../../store/store'
import { selectUserVoteForOneOfChoice } from '../../../../../../../store/slices/topicSlice'

type OneOfChoiceProps = {
  options: Array<TopicPollOption>
  readOnly: boolean
}
export const CUSTOM_OPTION_ID = -1

const OneOfChoice: React.VFC<OneOfChoiceProps> = (props) => {
  const { options = [], readOnly = false } = props
  const [showCustomInput, setShowCustomInput] = useState(false)

  const allowCustomOption = useSelector((state: RootState) => state.forumTopic.poll?.allowCustomOption)
  const resultsInPercent = useSelector(selectTopicPollResultsInPercent)
  const userVote = useSelector(selectUserVoteForOneOfChoice)

  const handleChangeRadioGroup = ({ target }: RadioChangeEvent) => {
    if (target.value === CUSTOM_OPTION_ID) {
      setShowCustomInput(true)
    } else {
      setShowCustomInput(false)
    }
  }

  useEffect(() => {
    setShowCustomInput(false)
  }, [userVote])

  return (
    <>
      <Form.Item name={'options'} rules={[{ required: true, message: '' }]} initialValue={userVote}>
        <Radio.Group disabled={readOnly} className={styles.radioGroup} onChange={handleChangeRadioGroup}>
          <Row gutter={[0, 10]}>
            {options.map((option) => (
              <Col span={24} key={option.id}>
                <PollOptionLine isChoices={userVote === option.id} votePercent={resultsInPercent[option.id]}>
                  <Radio className={'poll-group-item-radio'} value={option.id}>
                    {option.text}
                  </Radio>
                </PollOptionLine>
              </Col>
            ))}
            {allowCustomOption && (
              <Col span={24}>
                <PollOptionLine
                  isChoices={userVote === CUSTOM_OPTION_ID}
                  votePercent={resultsInPercent[CUSTOM_OPTION_ID]}
                >
                  <Radio className={'poll-group-item-radio'} value={CUSTOM_OPTION_ID}>
                    Свой вариант
                  </Radio>
                </PollOptionLine>
              </Col>
            )}
          </Row>
        </Radio.Group>
      </Form.Item>
      {showCustomInput && (
        <Form.Item
          name={'customOption'}
          rules={[
            { required: true, message: '' },
            {
              max: 150,
              message: '"Свой вариант" не должен превышать 150 символов.',
            },
          ]}
        >
          <Col span={24}>
            <Input disabled={readOnly} placeholder={'Введите свой вариант'} className={styles.customOptionInput} />
          </Col>
        </Form.Item>
      )}
    </>
  )
}

export default OneOfChoice
