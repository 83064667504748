import {
  IdTitleType,
  QuestionAdminFilter,
  QuestionEdit,
  QuestionsList,
  QuestionsTypes,
} from '../../components/survey/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import {
  QuestionEditApi,
  questionEditMap,
  questionsEditMap,
} from '../../components/survey/survey-questions/map/question-edit-map'

export type SurveyAdminHistoryType = {
  id: QuestionEdit['id']
  keyof: keyof QuestionEdit
  oldData: QuestionEdit[keyof QuestionEdit]
  newData: QuestionEdit[keyof QuestionEdit]
  reverse: boolean
}

//default - обычный просмотр
//edit - работа с одним вопросом
//list- работа со списком вопросов
type StateType = {
  mode: 'default' | 'edit' | 'list'
  questions: DataLoad<QuestionEdit[]>
  question: DataLoad<QuestionEdit>
  filter: QuestionAdminFilter
  search: string
  lastIndex: number
  editId: number
  historyIndex: number
  history: Array<SurveyAdminHistoryType>
  historyCurrentAction: SurveyAdminHistoryType | undefined
  newItem: boolean
  list: IdTitleType[]
  file: File | null
  currentList: IdTitleType
  apiList: QuestionsList
}

const initialState: StateType = {
  filter: {
    competences: [],
    sections: [],
    types: [],
  },
  search: '',
  mode: 'default',
  questions: { data: [], loading: false },
  question: { data: {} as QuestionEdit, loading: false },
  newItem: false,
  lastIndex: 0,
  editId: 0,
  historyIndex: 0,
  history: [],
  historyCurrentAction: undefined,
  file: null,
  list: [],
  currentList: { id: 0, title: '-' },
  apiList: {} as QuestionsList,
}

const fetchQuestions = createAsyncThunk('/fetchSurveyEditQuestions', (payload: QuestionAdminFetchType) => {
  return appAPI.survey.getQuestionsEdit(payload)
})
const fetchQuestion = createAsyncThunk('/fetchSurveyEditQuestion', (id: number) => {
  return appAPI.survey.getQuestionEdit(id)
})
const fetchQuestionsLists = createAsyncThunk('/fetchListQuestions', () => {
  return appAPI.questionList.getQuestionsLists()
})
const fetchQuestionsList = createAsyncThunk('/fetchQuestionsList', (id: number) => {
  return appAPI.questionList.getQuestionsByList(id)
})
const sendDeleteQuestion = createAsyncThunk('/deleteEditQuestions', (id: number) => {
  return appAPI.survey.deleteQuestionEdit(id)
})
const sendQuestion = createAsyncThunk('/sendEditQuestions', (payload: QuestionEditApi) => {
  if (payload.id === 0) return appAPI.survey.sendQuestionEdit(payload)
  else return appAPI.survey.updateQuestionEdit(payload)
})
const importFile = createAsyncThunk('/importFileQuestions', (payload: File) => {
  return appAPI.questions.importFile(payload)
})

const questionsAdminSlice = createSlice({
  name: 'questionAdminSlice',
  initialState,
  reducers: {
    addNew: (state) => {
      state.lastIndex -= 1
      state.questions.data.unshift({
        id: state.lastIndex,
        competences: [],
        file: null,
        text: '',
        section: 0,
        type: QuestionsTypes.radioGroup,
        answers: [],
        points: 1,
      })
      state.newItem = true
    },
    setFilters: (state, { payload }: PayloadAction<QuestionAdminFilter>) => {
      state.filter = payload
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload
    },
    setCurrentList: (state, { payload }: PayloadAction<IdTitleType>) => {
      state.currentList = payload
    },
    updateQuestionLoadList: (state, { payload }: PayloadAction<{ sourceIndex: number; newData: QuestionEdit }>) => {
      const elem = state.questions.data.find((x) => x.id === payload.sourceIndex)
      if (elem) {
        elem.id = payload.newData.id
        elem.file = payload.newData.file
        elem.type = payload.newData.type
        elem.answers = payload.newData.answers.filter((x) => x.data)
        elem.points = payload.newData.points
        elem.text = payload.newData.text
        elem.competences = payload.newData.competences
        elem.section = payload.newData.section
      }
    },
    newItemOff: (state) => {
      state.newItem = false
    },
    addNewItem: (state, { payload }: PayloadAction<QuestionEdit>) => {
      state.lastIndex -= 1
      state.questions.data.unshift({ ...payload, id: state.lastIndex })
    },
    clear: (state) => {
      state.questions = initialState.questions
      state.lastIndex = initialState.lastIndex
    },
    setMode: (state, { payload }: PayloadAction<typeof initialState.mode>) => {
      state.mode = payload
    },
    replace: (state, { payload }: PayloadAction<QuestionEdit>) => {
      const item = state.questions.data.find((x) => x.id === payload.id)
      if (item) {
        Object.assign(item, payload)
      }
    },
    deleteItem: (state, { payload }: PayloadAction<number>) => {
      state.questions.data = state.questions.data.filter((x) => x.id !== payload)
    },
    setItemForEdit: (state, { payload }: PayloadAction<number>) => {
      state.editId = payload
      state.mode = 'edit'
    },
    pushActionHistory: (state, { payload }: PayloadAction<SurveyAdminHistoryType>) => {
      let lastIndex = state.history.length - 1
      let history = state.history
      if (lastIndex + 1 !== state.historyIndex) {
        history = state.history.slice(0, lastIndex)
        lastIndex = history.length - 1
      }
      if (state.history.length >= 100) state.history.pop()
      if (lastIndex >= 0 && history[lastIndex].id === payload.id && history[lastIndex].keyof === payload.keyof)
        history[state.history.length - 1].newData = payload.newData
      else history.push(payload)
      state.historyIndex = state.history.length
      state.history = history
    },
    deleteHistoryById: (state, { payload }: PayloadAction<number>) => {
      const newHistory = state.history.filter((x) => x.id !== payload)
      if (state.historyIndex !== state.history.length) {
        const betw = state.historyIndex - state.history.length
        if (newHistory[newHistory.length - 1] !== state.history[state.historyIndex]) {
          const oldItemCount = state.history.slice(betw).filter((x) => x.id === payload).length
          state.historyIndex = state.history.length + (betw + oldItemCount)
        } else {
          state.historyIndex = state.history.length + betw
        }
      }
      state.history = newHistory
    },
    nextHistoryIndex: (state) => {
      state.historyIndex += 1
      if (state.history[state.historyIndex - 1]) state.history[state.historyIndex - 1].reverse = true
      state.historyCurrentAction = state.history[state.historyIndex - 1]
    },
    prevHistoryIndex: (state) => {
      state.historyIndex -= 1
      if (state.history[state.historyIndex]) state.history[state.historyIndex].reverse = false
      state.historyCurrentAction = state.history[state.historyIndex]
    },
    startEditQuestion: (state, { payload }: PayloadAction<number>) => {
      const item = state.questions.data.find((x) => x.id === payload)
      if (item) {
        Object.assign(item, { isEditing: true })
      }
    },
    endEditQuestion: (state, { payload }: PayloadAction<number>) => {
      const item = state.questions.data.find((x) => x.id === payload)
      if (item) {
        Object.assign(item, { isEditing: false })
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        state.questions.loading = true
      })
      .addCase(fetchQuestions.fulfilled, (state, { payload }) => {
        state.questions.loading = false
        state.questions.data = state.questions.data.concat(questionsEditMap(payload.data.results))
        //state.lastIndex = state.questions.data.slice().sort((a, b) => b.id - a.id)[0].id
      })
      .addCase(fetchQuestions.rejected, (state) => {
        state.questions.loading = false
        state.questions.data = initialState.questions.data
      })
      .addCase(fetchQuestion.pending, (state) => {
        state.question.loading = true
        state.questions.loading = true
        state.question.data = initialState.question.data
      })
      .addCase(fetchQuestion.fulfilled, (state, { payload }) => {
        state.question.loading = false
        state.question.data = questionEditMap(payload.data)
        state.questions.data = [questionEditMap(payload.data)]
      })
      .addCase(fetchQuestion.rejected, (state) => {
        state.question.loading = false
        state.question.data = initialState.question.data
        state.questions.loading = false
        state.questions.data = initialState.questions.data
      })
      .addCase(fetchQuestionsLists.fulfilled, (state, { payload }) => {
        state.list = payload.data.map(({ id, name }) => ({ id, title: name }))
      })
      .addCase(fetchQuestionsLists.rejected, (state) => {
        state.list = []
      })
      .addCase(fetchQuestionsList.pending, (state) => {
        state.questions.loading = true
      })
      .addCase(fetchQuestionsList.fulfilled, (state, { payload }) => {
        state.questions.loading = false
        state.questions.data = questionsEditMap(payload.data.questions)
        state.apiList = payload.data
      })
      .addCase(fetchQuestionsList.rejected, (state) => {
        state.questions.loading = false
        state.questions.data = []
      })
  },
})

export default questionsAdminSlice.reducer

export const questionAdminSliceActions = {
  ...questionsAdminSlice.actions,
  fetchQuestions,
  fetchQuestionsLists,
  fetchQuestionsList,
  sendDeleteQuestion,
  sendQuestion,
  fetchQuestion,
  importFile,
}

export type QuestionAdminFetchType = {
  params: {
    section_id?: number[]
    competences?: number[]
    text?: string
    limit?: number
    offset?: number
  }
  type: QuestionsTypes[]
}
