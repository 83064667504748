import { useEffect, useRef, useCallback, useState } from 'react'

export type Coords = {
  x: number
  y: number
}

export type StateUseTouchEvent<T> = {
  start: T
  move: T
  end: T
  event: 'touchstart' | 'touchmove' | 'touchend' | undefined
}

type Callback = (state: StateUseTouchEvent<Coords>) => void

type ReturnHookTouchEvent = ((setElement: HTMLElement | null) => void)[]

type HookTouchEvent = (callback: Callback) => ReturnHookTouchEvent

const defaultStateUseTouchEvent = {
  start: { x: 0, y: 0 },
  move: { x: 0, y: 0 },
  end: { x: 0, y: 0 },
  event: undefined,
}

const useTouchEvent: HookTouchEvent = (callback) => {
  const state = useRef<StateUseTouchEvent<Coords> | null>(defaultStateUseTouchEvent)
  const [HTMLElement, setHTMLElement] = useState<HTMLElement | null>(null)

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (state?.current) {
        state.current.move = { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY }
        state.current.event = 'touchmove'
        callback(state.current)
      }
    },
    [callback],
  )

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      if (HTMLElement) {
        HTMLElement.removeEventListener('touchmove', handleTouchMove)
        HTMLElement.removeEventListener('touchend', handleTouchEnd)
      }

      if (state?.current) {
        state.current.end = { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY }
        state.current.event = 'touchend'
        callback(state.current)
      }
    },
    [HTMLElement, handleTouchMove, callback],
  )

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      if (HTMLElement) {
        HTMLElement.addEventListener('touchmove', handleTouchMove)
        HTMLElement.addEventListener('touchend', handleTouchEnd)
      }
      if (state?.current) {
        state.current = defaultStateUseTouchEvent
        state.current.start = { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY }
        state.current.event = 'touchstart'
        callback(state.current)
      }
    },
    [HTMLElement, handleTouchMove, handleTouchEnd, callback],
  )

  useEffect(() => {
    return () => {
      HTMLElement && HTMLElement.removeEventListener('touchstart', handleTouchStart)
    }
  }, [handleTouchStart, HTMLElement])

  const setElement = (HTMLElement: HTMLElement | null) => {
    setHTMLElement(HTMLElement)
    if (HTMLElement) {
      HTMLElement.addEventListener('touchstart', handleTouchStart)
    }
  }

  return [setElement]
}

export default useTouchEvent
