export default (elemNumber: number): number[] => {
  const colSizes: number[] = []
  let currentElemNum = 0

  for (let i = 0; i < elemNumber; i += 1) {
    if (currentElemNum === 3) {
      colSizes.push(16)
      currentElemNum += 1
    } else if (currentElemNum === 6) {
      colSizes.push(16)
      currentElemNum = 0
    } else {
      colSizes.push(8)
      currentElemNum += 1
    }
  }

  return colSizes
}
