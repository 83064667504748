import React, { FC } from 'react'

type ArrowSwitcherLeftProps = {
  width?: number | string
  height?: number | string
  color?: string
}

const ArrowSwitcherLeft: FC<ArrowSwitcherLeftProps> = ({ color = '#309BEC', width = 20, height = 28 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.56068 12.9395L13 4.50015C13.5523 3.94787 14.4477 3.94787 15 4.50015C15.5523 5.05244 15.5523 5.94787 15 6.50015L7.50002 14.0002L15 21.5002C15.5523 22.0524 15.5523 22.9479 15 23.5002C14.4477 24.0524 13.5523 24.0524 13 23.5002L4.56068 15.0608C3.97489 14.475 3.97489 13.5253 4.56068 12.9395Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowSwitcherLeft
