import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const Close: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6569 17.6569C17.2663 18.0474 16.6332 18.0474 16.2427 17.6569L12 13.4142L7.75734 17.6568C7.36682 18.0474 6.73365 18.0474 6.34313 17.6568C5.9526 17.2663 5.9526 16.6332 6.34312 16.2426L10.5858 12L6.34316 7.75736C5.95263 7.36683 5.95263 6.73367 6.34316 6.34314C6.73368 5.95262 7.36684 5.95262 7.75737 6.34314L12 10.5858L16.2426 6.34314C16.6331 5.95262 17.2663 5.95262 17.6568 6.34314C18.0474 6.73367 18.0474 7.36683 17.6568 7.75735L13.4142 12L17.6569 16.2426C18.0474 16.6332 18.0474 17.2663 17.6569 17.6569Z"
        fill={color}
      />
    </svg>
  )
}

Close.defaultProps = {
  size: 24,
  className: '',
  color: '#818C99',
}

export default Close
