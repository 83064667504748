import { useState } from 'react'

export type UseSwipeModal = {
  isShow: boolean
  onShow: () => void
  onClose: () => void
}

export const useSwipeModal = (initialState?: boolean): UseSwipeModal => {
  const [isShow, setIsShow] = useState<boolean>(initialState || false)

  const onShow = () => {
    setIsShow(true)
  }

  const onClose = () => {
    setIsShow(false)
  }

  return { isShow, onShow, onClose }
}
