import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { ForumTopicReadDetail } from '../../api/model/forumTopicReadDetail'
import { RootState } from '../store'
import { UserWSRetrieveForCommentForum } from '../../api/model/userWSRetrieveForCommentForum'
import { CUSTOM_OPTION_ID } from '../../components/forum/detail-view/topic-poll/poll-detail/poll-detail-questions-section/one-of-choice/one-of-choice'

type ForumTopicState = Partial<ForumTopicReadDetail> & {
  loading: boolean
}

export const initialForumTopicState: ForumTopicState = {
  loading: false,
}

const fetchForumTopic = createAsyncThunk('/fetchForumTopic', async (payload: { id: number }, { rejectWithValue }) => {
  try {
    return await appAPI.forumTopic.getTopic(payload.id)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const ForumTopicSlice = createSlice({
  name: 'topic',
  initialState: initialForumTopicState,
  reducers: {
    clearTopic: () => {
      return initialForumTopicState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchForumTopic.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchForumTopic.fulfilled, (state, { payload }) => {
        return {
          ...payload.data,
          loading: false,
        }
      })
  },
})

export default ForumTopicSlice.reducer

export const forumTopicActions = {
  ...ForumTopicSlice.actions,
  fetchForumTopic,
}

type selectTopicMetaForPoll = {
  title: string
  loading: boolean
  author: UserWSRetrieveForCommentForum
  createdAt: {
    date: string
    time: string
  }
}
export const selectTopicMetaForPoll = (state: RootState): selectTopicMetaForPoll => {
  const date = new Date(state.forumTopic.created || '').toLocaleDateString('ru-Ru')
  const time = new Date(state.forumTopic.created || '').toLocaleTimeString('ru-Ru', {
    hour: '2-digit',
    minute: '2-digit',
  })

  return {
    title: state.forumTopic.title || '',
    loading: state.forumTopic.loading,
    author: state.forumTopic.author as UserWSRetrieveForCommentForum,
    createdAt: {
      date,
      time,
    },
  }
}

export const selectUserVoteForOneOfChoice = (state: RootState): number | undefined => {
  const customOption = state.forumTopic.poll?.userVote?.customOption

  if (customOption) return CUSTOM_OPTION_ID
  const options = state.forumTopic.poll?.userVote?.options || []

  return options?.length > 0 ? options[0] : undefined
}
