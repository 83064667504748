import React, { FC } from 'react'
import { Empty, Table, TableColumnsType } from 'antd'
import { DataType, DataTypeArr } from '../types/statisticType'

interface PopoverPropsContent {
  columns: TableColumnsType<DataType> | undefined
  data: DataTypeArr | undefined
}

const StatisticPopoverContent: FC<PopoverPropsContent> = (props) => {
  const { columns, data } = props

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={{ y: 240 }}
      locale={{
        emptyText() {
          return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Нет данных</span>} />
        },
      }}
    />
  )
}

export default StatisticPopoverContent
