import React, { FC } from 'react'

const Reply: FC = () => {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" width={24}>
      <path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z" fill={'#309BEC'}></path>
    </svg>
  )
}

export default Reply
