import { columnsTypes } from '../types/statisticType'

// колонки для разных типов статистики
export const columns: Partial<columnsTypes> = {
  visits: [
    {
      title: 'Фамилия Имя Отчество',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: 150,
    },
    {
      title: 'Время',
      dataIndex: 'time',
      key: 'time',
      width: 150,
    },
  ],

  downloads: [
    {
      title: 'Фамилия Имя Отчество',
      dataIndex: 'name',
      key: 'name',
    },
  ],
}
