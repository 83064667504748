import React, { FC } from 'react'
import { ButtonProps } from 'antd/lib/button'
import { Button as AntButton } from 'antd'
import './button.ant.scss'

const Button: FC<ButtonProps> = (props) => {
  return <AntButton {...props} />
}

Button.defaultProps = {
  type: 'default',
}

export default Button
