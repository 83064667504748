export const declineWord = (number: number, text: Array<string>): string => {
  number = Math.abs(number) % 100

  const number1 = number % 10

  if (number > 10 && number < 20) {
    return text[2]
  }
  if (number1 > 1 && number1 < 5) {
    return text[1]
  }
  if (number1 === 1) {
    return text[0]
  }

  return text[2]
}
