import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const Admin: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 14C22 15.6569 20.6569 17 19 17C17.3431 17 16 15.6569 16 14C16 12.3431 17.3431 11 19 11C20.6569 11 22 12.3431 22 14ZM24 14C24 16.7614 21.7614 19 19 19C16.2386 19 14 16.7614 14 14C14 11.2386 16.2386 9 19 9C21.7614 9 24 11.2386 24 14ZM10.7213 27.6926C12.9448 25.3769 15.847 24 19 24C19.5523 24 20 23.5523 20 23C20 22.4477 19.5523 22 19 22C15.2378 22 11.832 23.6482 9.27868 26.3074C8.89617 26.7058 8.90903 27.3388 9.3074 27.7213C9.70578 28.1038 10.3388 28.091 10.7213 27.6926Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5585 19.4528C28.2856 17.5523 25.5421 17.5523 25.2692 19.4528C25.1162 20.5182 24.006 21.1592 23.0068 20.759C21.2245 20.0451 19.8527 22.421 21.3621 23.6076C22.2083 24.2729 22.2083 25.5548 21.3621 26.2201C19.8527 27.4067 21.2244 29.7826 23.0068 29.0687C24.006 28.6685 25.1162 29.3095 25.2692 30.375C25.5421 32.2754 28.2856 32.2754 28.5585 30.375C28.7115 29.3095 29.8218 28.6685 30.821 29.0687C32.6033 29.7826 33.9751 27.4067 32.4657 26.2201C31.6194 25.5548 31.6194 24.2729 32.4657 23.6076C33.9751 22.421 32.6033 20.0451 30.821 20.759C29.8218 21.1592 28.7115 20.5182 28.5585 19.4528ZM26.9138 27.7328C28.4707 27.7328 29.7328 26.4707 29.7328 24.9138C29.7328 23.357 28.4707 22.0949 26.9138 22.0949C25.357 22.0949 24.0949 23.357 24.0949 24.9138C24.0949 26.4707 25.357 27.7328 26.9138 27.7328Z"
        fill={color}
      />
    </svg>
  )
}

Admin.defaultProps = {
  size: 24,
  className: '',
  color: '#3F8AE0',
}

export default Admin
