import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const Poll: FC<Props> = ({ size = 16, color = '#309BEC', ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d="M16 7.50006C16 8.94817 15.4975 10.2936 14.6367 11.41L16 15.5001L12.406 13.3437C11.1425 14.0745 9.62804 14.5001 8 14.5001C3.58172 14.5001 0 11.3661 0 7.50006C0 3.63407 3.58172 0.500061 8 0.500061C12.4183 0.500061 16 3.63407 16 7.50006ZM6 7.05562C6 6.5647 5.55228 6.16673 5 6.16673C4.44772 6.16673 4 6.5647 4 7.05562V10.6112C4 11.1021 4.44772 11.5001 5 11.5001C5.55228 11.5001 6 11.1021 6 10.6112V7.05562ZM12 8.38895C12 7.89803 11.5523 7.50006 11 7.50006C10.4477 7.50006 10 7.89803 10 8.38895V10.6112C10 11.1021 10.4477 11.5001 11 11.5001C11.5523 11.5001 12 11.1021 12 10.6112V8.38895ZM9 4.38895C9 3.89803 8.55229 3.50006 8 3.50006C7.44772 3.50006 7 3.89803 7 4.38895V10.6112C7 11.1021 7.44772 11.5001 8 11.5001C8.55229 11.5001 9 11.1021 9 10.6112V4.38895Z"
        fill={color}
      />
    </svg>
  )
}

export default Poll
