import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChampionshipDocument } from '../../api/model/championshipDocument'
import appAPI from '../../api/api'

export type ChampionshipDocsState = {
  docs: Array<ChampionshipDocument>
  page: number
  pages: number
  loading: boolean
}

const initialChampionshipDocsState: ChampionshipDocsState = {
  docs: [],
  page: 0,
  pages: 0,
  loading: false,
}

const fetchChampionshipDocs = createAsyncThunk(
  '/fetchChampionshipDocs',
  async (payload: GetChampionshipDocsPayload) => {
    return await appAPI.championships.getChampionshipDocs(payload)
  },
)

const championshipDocsSlice = createSlice({
  name: 'championshipDocs',
  initialState: initialChampionshipDocsState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    clearChampionshipDocs: () => initialChampionshipDocsState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChampionshipDocs.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchChampionshipDocs.fulfilled, (state, { payload }) => {
        state.docs = [...state.docs, ...(payload.data.results || [])]
        state.pages = payload.data.pages || 0
        state.loading = false
      })
      .addCase(fetchChampionshipDocs.rejected, (state) => {
        state.loading = false
      })
  },
})

export default championshipDocsSlice.reducer

export const championshipDocsSliceActions = {
  ...championshipDocsSlice.actions,
  fetchChampionshipDocs,
}
