import { QuestionEdit, QuestionsTypes } from '../../types'

export const questionsEditMap = (data: Array<QuestionEditApi>): Array<QuestionEdit> => {
  return data.map((x) => questionEditMap(x))
}

export const questionEditMap = (x: QuestionEditApi): QuestionEdit => {
  return {
    id: x.id,
    file: x.file,
    text: x.text,
    competences: x.competences,
    type: x.type as QuestionsTypes,
    section: x.sectionId,
    answers: x.answers.map((a) => {
      return {
        idB: a.id,
        data: a.answer,
        correct: a.correct,
        order: a.position !== null ? a.position : undefined,
      }
    }),
    points: x.score,
  }
}

export const questionsApiEditMap = (data: Array<QuestionEdit>): Array<QuestionEditApi> => {
  return data.map((x) => questionApiEditMap(x))
}

export const questionApiEditMap = (x: QuestionEdit): QuestionEditApi => {
  return {
    id: x.id,
    file: null,
    text: x.text,
    competences: x.competences,
    type: x.type,
    sectionId: x.section,
    answers: x.answers.map((a) => ({
      id: a.idB,
      answer: a.data,
      correct: a.correct,
      position: a.order === undefined ? null : a.order,
    })),
    score: x.points,
    active: true,
  }
}

export type QuestionEditApi = {
  id: number
  competences: number[]
  file: string | null
  text: string
  type: string
  score: number
  active: boolean
  sectionId: number
  answers: Array<answerEditApi>
}

type answerEditApi = {
  id: number
  answer: string
  correct: boolean
  position?: number | null
}
