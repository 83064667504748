import React, { FC } from 'react'

type IconProps = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const Ellipsis: FC<IconProps> = ({ size, className, color = '#309BEC', onClick }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <ellipse cx="20" cy="20" rx="2" ry="2" transform="rotate(-180 20 20)" fill={color} />
      <ellipse cx="27" cy="20" rx="2" ry="2" transform="rotate(-180 27 20)" fill={color} />
      <ellipse cx="13" cy="20" rx="2" ry="2" transform="rotate(-180 13 20)" fill={color} />
    </svg>
  )
}

export default Ellipsis
