import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import * as Models from '../../api/model/models'

export type TopicsState = Pick<Models.PaginatedForumTopicReadList, 'pages' | 'total'> & {
  list: Models.ForumTopicRead[]
  page: number
  loading: boolean
  limit: number
  isModerator: boolean
}

export const initialTopicsState: TopicsState = {
  list: [],
  pages: 0,
  page: 0,
  total: 0,
  loading: false,
  limit: 20,
  isModerator: false,
}

const fetchInfo = createAsyncThunk('fetchInfo', async (id: number, { rejectWithValue }) => {
  try {
    return await appAPI.forumTopic.getInfo(id)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const fetchTopicsAccumulate = createAsyncThunk(
  'fetchTopicsAccumulate',
  async (payload: GetTopicsPayload, { rejectWithValue }) => {
    try {
      return await appAPI.forum.getTopics(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const fetchTopics = createAsyncThunk('fetchTopics', async (payload: GetTopicsPayload, { rejectWithValue }) => {
  try {
    return await appAPI.forum.getTopics(payload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const setTopic = createAsyncThunk('setTopic', async (payload: PutTopicPayload, { rejectWithValue }) => {
  try {
    const { idTopic, ...restPayload } = payload
    return await appAPI.forumTopic.putTopic(idTopic, restPayload)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const deleteTopic = createAsyncThunk('deleteTopic', async (id: number, { rejectWithValue }) => {
  try {
    return await appAPI.forumTopic.deleteTopic(id)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const topicsSlice = createSlice({
  name: 'topics',
  initialState: initialTopicsState,
  reducers: {
    changeCloseStatus: (state: TopicsState, { payload }: PayloadAction<{ id: number; isClosed: boolean }>) => {
      state.list.forEach((topic) => {
        if (topic.id === payload.id) topic.isClosed = payload.isClosed
      })
    },
    clearTopics: () => {
      return { ...initialTopicsState }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopicsAccumulate.pending, (state: TopicsState) => {
        state.loading = true
      })
      .addCase(fetchTopicsAccumulate.fulfilled, (state: TopicsState, { payload }) => {
        state.list.push(...(payload.data.results || []))
        state.pages = payload.data.pages
        state.page++
        state.total = payload.data.total
        state.loading = false
      })
      .addCase(fetchTopicsAccumulate.rejected, (state: TopicsState) => {
        state.loading = false
      })
      .addCase(fetchTopics.fulfilled, (state: TopicsState, { payload }) => {
        state.list = payload.data.results || []
        state.total = payload.data.total
      })
      .addCase(fetchInfo.fulfilled, (state: TopicsState, { payload }) => {
        state.isModerator = payload.data.isModerator
      })
  },
})

export default topicsSlice.reducer

export const topicsSliceAction = {
  ...topicsSlice.actions,
  fetchTopics,
  fetchInfo,
  fetchTopicsAccumulate,
  setTopic,
  deleteTopic,
}
