import React, { FC } from 'react'
import styles from './burger-menu.module.scss'
import { Menu } from '../../index'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { Button } from '../../../common'
import { Close } from '../../../common/icons'
import TopProfile from '../top-profile/top-profile'
import { addCountlyEvent } from '../../../../utils/stats'

type BurgerMenuProps = {
  show: boolean
  selectTab: (tab: LoginOrRegisterModalTab) => void
  onClose: () => void
}

const BurgerMenu: FC<BurgerMenuProps> = (props) => {
  const { show, selectTab, onClose } = props
  const authorized = useSelector((state: RootState) => state.account.user)

  return (
    <div className={[styles.wrapper, show ? styles.active : ''].join(' ')}>
      <div className={styles.block}>
        <Button type={'ghost'} onClick={onClose} className={styles.close}>
          <Close />
        </Button>
        {!authorized && (
          <div className={styles.authBtnWrapper}>
            <Button
              type={'primary'}
              size={'small'}
              onClick={() => {
                selectTab('login')
                addCountlyEvent(`Авторизация`, { Действие: 'Форма открыта' })
              }}
            >
              Вход
            </Button>
            <Button
              type={'link'}
              size={'small'}
              onClick={() => {
                selectTab('registration')
                addCountlyEvent(`Регистрация`, { Действие: 'Форма открыта' })
              }}
            >
              Регистрация
            </Button>
          </div>
        )}
        {authorized && (
          <div className={styles.authBtnWrapper}>
            <TopProfile />
          </div>
        )}
        <div className={styles.menuWrapper}>
          <Menu />
        </div>
      </div>
    </div>
  )
}

export default BurgerMenu
