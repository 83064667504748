import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import React, { FC } from 'react'
import useIsSticky from '../../../hooks/useIsSticky'
import styles from './styles.module.scss'

type PropsStickyBox = {
  classNameSticky?: string
  scrollY?: number
}

const StickyBox: FC<PropsStickyBox> = (props) => {
  const { classNameSticky = styles.sticky, scrollY } = props
  const _scrollY = 120 - (!useBreakpoint().xl ? 80 : 0)
  const { stickedClassName, stickyRef } = useIsSticky(false, classNameSticky, scrollY ? scrollY : _scrollY)
  return (
    <div className={stickedClassName ? stickedClassName.toString() : ''} ref={stickyRef}>
      {props.children}
    </div>
  )
}

export default StickyBox
