import 'antd/dist/antd.min.css'
import React, { FC } from 'react'
import { useIcoMenu } from './hooks/useIcoMenu'
import DefaultApp from './app/defaultApp/default-app'
import SurveyApp from './app/surveyApp/survey-app'

const App: FC = (props) => {
  const { children } = props
  const isIcoMenu = useIcoMenu()

  return <>{isIcoMenu ? <SurveyApp>{children}</SurveyApp> : <DefaultApp>{children}</DefaultApp>}</>
}

export default App
