import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  onClick?: () => void
}

const LogOut: FC<Props> = (props) => {
  const { size = '40', className = '', ...restProps } = props

  return (
    <svg
      className={className}
      style={{ cursor: 'pointer' }}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M13 20L23 20" stroke="#4392CE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 24L23 20L19 16" stroke="#4392CE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M24 28L25 28C27.2091 28 29 26.2091 29 24L29 16C29 13.7909 27.2091 12 25 12L24 12"
        stroke="#4392CE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LogOut
