import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import appAPI from '../../api/api'
import { UserPublic } from '../../api/model/userPublic'

type modalProfileCardState = {
  visible: boolean
  user?: UserPublic
}

const initialModalProfileCardState: modalProfileCardState = {
  visible: false,
  user: undefined,
}

const fetchProfileCardById = createAsyncThunk('fetchProfileCardById', async (userId: number, { rejectWithValue }) => {
  try {
    return await appAPI.userProfile.getPublicProfile({ id: userId })
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const modalProfileCardSlice = createSlice({
  name: 'modalProfileCard',
  initialState: initialModalProfileCardState,
  reducers: {
    hideProfileModal: () => initialModalProfileCardState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfileCardById.fulfilled, (state, { payload }) => {
      state.visible = true
      state.user = payload.data
    })
  },
})

export const modalProfileCardSliceActions = { ...modalProfileCardSlice.actions, fetchProfileCardById }

export default modalProfileCardSlice.reducer
