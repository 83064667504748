import styles from './top-section.module.scss'
import React, { FC } from 'react'

type TopSectionProps = {
  children: React.ReactNode
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

const TopSection: FC<TopSectionProps> = ({ children, className, ...restProps }) => {
  return (
    <section className={[styles.topSection, className].join(' ')} {...restProps}>
      {children}
    </section>
  )
}

export default TopSection
