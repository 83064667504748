import React, { FC, SyntheticEvent } from 'react'

interface ISliderArrowProps {
  color?: string
  className?: string
  onClick?: (e: SyntheticEvent) => void
}

const SliderArrowRight: FC<ISliderArrowProps> = ({ color, className, onClick }) => {
  return (
    <svg className={className} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M7.6016 5.6484C7.17446 5.22126 7.17446 4.52874 7.6016 4.1016C8.02874 3.67447 8.72126 3.67447 9.1484 4.1016L15.2734 10.2266C15.7005 10.6537 15.7005 11.3463 15.2734 11.7734L9.1484 17.8984C8.72126 18.3255 8.02874 18.3255 7.6016 17.8984C7.17447 17.4713 7.17447 16.7787 7.6016 16.3516L12.9532 11L7.6016 5.6484Z"
        fill={color}
      />
    </svg>
  )
}

SliderArrowRight.defaultProps = {
  color: '#99A2AD',
}

export default SliderArrowRight
