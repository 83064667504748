import React, { FC } from 'react'
import { Popover as AntPopover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'
import styles from './popover.module.scss'

const Popover: FC<PopoverProps> = (props) => {
  return (
    <AntPopover {...props}>
      <div className={styles.popoverActionButton}>{props.children}</div>
    </AntPopover>
  )
}

export default Popover
