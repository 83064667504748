import React, { FC } from 'react'

const BackwardIcon: FC = () => {
  return (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 1L1 6L5 11" stroke="#025EA1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default BackwardIcon
