import React, { FC, useState } from 'react'
import { Spin } from 'antd'
import { Button, Link } from '../index'
import { Play, Download, Close } from '../icons'
import { Title } from '../text'
import PopupGallery from '../../gallery/popup-gallery/popup-gallery'
import styles from './file-preview.module.scss'
import './flie-preview.ant.scss'

type FilePreviewProps = {
  name?: string
  url?: string
  type?: string
  thumbScr?: string
  size?: string
  status?: 0 | 1 | 2
  videoManifest?: string
  onRemove?: () => void
  unclickable?: boolean
  isBig?: boolean
}

const FilePreview: FC<FilePreviewProps> = (props) => {
  const { name, type, thumbScr, onRemove, size, status, videoManifest, url, unclickable, isBig } = props

  const [popupShow, setPopupShow] = useState(false)
  const [doRemove, setDoRemove] = useState(false)

  const typeImage = /image*|image/gm.test(type || '')
  const typeVideo = /video*|video/gm.test(type || '')
  const typeMedia = typeImage || typeVideo

  return (
    <div className={isBig ? '' : styles.container} onClick={(e) => e.stopPropagation()}>
      <div
        className={`${styles.thumbContainer} ${isBig ? styles.big : styles.small}`}
        onClick={() => typeMedia && !unclickable && setPopupShow(true)}
      >
        {status === 0 && <Spin size={'small'} spinning={true} className={styles.spin} />}
        {status === 2 && <Close />}
        {typeMedia && status === 1 && (
          <div style={{ backgroundImage: `url("${thumbScr}")` }} className={styles.thumbPic}>
            {typeVideo && <Play color="#309BEC" />}
          </div>
        )}
        {!typeMedia && status === 1 && (
          <>
            {url && !unclickable ? (
              <Link to={url} external className={styles.link} download={name}>
                <Download />
              </Link>
            ) : (
              <Download />
            )}
          </>
        )}
      </div>
      <div className={styles.description}>
        <Title level={5} className={styles.fileName}>
          {name}
        </Title>
        {onRemove &&
          (doRemove ? (
            <span className={styles.acceptDialog}>
              <Button onClick={onRemove} type="link" className={styles.accept}>
                Подтвердить
              </Button>
              <Button onClick={() => setDoRemove(false)} type="link" className={styles.cancel}>
                Отменить
              </Button>
            </span>
          ) : (
            <Button onClick={() => setDoRemove(true)} type="link" className="btn-delete">
              Удалить
            </Button>
          ))}
        {size && <div>{size}</div>}
      </div>

      {typeMedia && (
        <>
          {status === 1 && (
            <PopupGallery label={name} visible={popupShow} onCancel={() => setPopupShow(false)}>
              {typeVideo ? (
                <PopupGallery.Video src={url} videoManifest={videoManifest} thumbnail={thumbScr} />
              ) : (
                <PopupGallery.Image src={url || ''} alt={name || ''} />
              )}
            </PopupGallery>
          )}
        </>
      )}
    </div>
  )
}

export default FilePreview
