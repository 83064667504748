import React, { ChangeEvent, FC, useState } from 'react'
import { addCountlyEvent } from '../../../../utils/stats'
import { InputSearch } from '../../../common'
import useGlobalInputSearch from './hooks/useInputGlobalSearch'

const GlobalInputSearch: FC = () => {
  const [searchInput, setSearchInput] = useState<string>('')
  const { submit, hideModal, loading, clearResultSearch } = useGlobalInputSearch()

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
    if (e.target.value.length <= 1) clearResultSearch()
  }

  const handleClearInput = () => {
    clearResultSearch()
    setSearchInput('')
  }

  const handleSubmit = () => {
    if (searchInput) {
      submit(searchInput.trim())
      addCountlyEvent('Поиск на портале', { 'Поисковые запросы': searchInput })
    }
  }

  return (
    <InputSearch
      value={searchInput}
      disabled={loading}
      placeholder={'Введите запрос'}
      onChange={handleChangeInput}
      onSubmit={handleSubmit}
      onClickClear={handleClearInput}
      onClickBackward={hideModal}
    />
  )
}

export default GlobalInputSearch
