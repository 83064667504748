import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const CommentCrossedOut: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2121 16.8251C13.4564 16.6106 13.7701 16.4923 14.0948 16.4923H17.7447C18.7049 16.4923 19.2766 15.9185 19.2766 14.9548V7.26721C19.2766 6.30344 18.7049 5.7297 17.7447 5.7297H6.25532C5.29505 5.7297 4.7234 6.30344 4.7234 7.26721V14.9548C4.7234 15.9185 5.29505 16.4923 6.25532 16.4923H8.64798C9.31753 16.6861 9.31753 16.6861 9.51064 17.3581V20.0762L13.2121 16.8251ZM8.49096 21.7126C8.03903 21.3724 7.78723 20.7722 7.78723 20.24V18.222H6.25532C4.34324 18.222 3 16.8738 3 14.9548V7.26721C3 5.34815 4.34324 4 6.25532 4H17.7447C19.6568 4 21 5.34815 21 7.26721V14.9548C21 16.8738 19.6568 18.222 17.7447 18.222H14.2387L10.6724 21.3538C9.97079 22.0445 9.15094 22.2094 8.49096 21.7126Z"
        fill={color}
      />
      <rect
        x="20.9038"
        y="2.28577"
        width="3"
        height="25.438"
        rx="1.5"
        transform="rotate(51.4857 20.9038 2.28577)"
        fill="#818C99"
        stroke="white"
      />
    </svg>
  )
}

CommentCrossedOut.defaultProps = {
  size: 24,
  className: '',
  color: '#818C99',
}

export default CommentCrossedOut
