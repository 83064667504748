import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const PushpinOutlined: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#309BEC" />
      <path
        d="M12.9652 5.10355C13.0827 4.51617 13.8074 4.29674 14.231 4.72031L19.2803 9.76966C19.7039 10.1932 19.4845 10.918 18.8971 11.0354L17.3966 11.3355C17.3443 11.346 17.2963 11.3717 17.2586 11.4094L14.8585 13.8095C14.8129 13.8551 14.7851 13.9155 14.7802 13.9798L14.491 17.7393C14.4484 18.2928 13.9652 18.7069 13.4118 18.6643C13.1726 18.6459 12.9479 18.5426 12.7782 18.3729L9.73333 15.3275L5.78033 19.281C5.48744 19.5739 5.01256 19.5739 4.71967 19.281C4.45118 19.0125 4.42881 18.5911 4.65255 18.2971L4.71967 18.2203L8.6725 14.2667L5.6277 11.2224C5.26138 10.8561 5.23696 10.2773 5.55444 9.88268L5.6277 9.80108C5.79734 9.63144 6.02208 9.52807 6.26128 9.50967L10.0209 9.22048C10.0851 9.21553 10.1455 9.18775 10.1911 9.14215L12.5912 6.74205C12.629 6.70434 12.6547 6.65632 12.6651 6.60402L12.9652 5.10355ZM14.1608 6.77148L14.136 6.8982C14.0675 7.24088 13.899 7.5556 13.6519 7.80271L11.2518 10.2028C10.953 10.5016 10.5572 10.6837 10.1359 10.7161L7.44917 10.9223L13.0775 16.5506L13.2846 13.8647C13.3129 13.4961 13.4559 13.147 13.6913 12.8653L13.7978 12.7488L16.1979 10.3487C16.445 10.1016 16.7598 9.93319 17.1024 9.86465L17.2283 9.83897L14.1608 6.77148Z"
        fill={color}
      />
    </svg>
  )
}

PushpinOutlined.defaultProps = {
  size: 24,
  className: '',
  color: '#FFFFFF',
}

export default PushpinOutlined
