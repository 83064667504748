import { EditSurvey, Statuses, SurveyAdminType, TestQuestionType } from '../../components/survey/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'

export type PaginatedResponse<T> = {
  pages: number
  results: Array<T>
  total: number
}

type SurveyAdminStateType = {
  surveyList: DataLoad<SurveyAdminType[]>
  surveyEdit: DataLoad<EditSurvey | null>
  surveyEditMode: 'default' | 'duplicate'
  surveyQuestions: DataLoad<TestQuestionType[]>
}

const initialState: SurveyAdminStateType = {
  surveyList: { data: [], loading: false },
  surveyEdit: { data: null, loading: false },
  surveyEditMode: 'default',
  surveyQuestions: { data: [], loading: false },
}

const fetchSurveyList = createAsyncThunk(
  '/fetchSurveyList',
  async (payload: { payload: Paginated; status: Statuses }) => {
    return await appAPI.survey.getAdminList(payload.payload, payload.status)
  },
)

const fetchEditSurvey = createAsyncThunk('/fetchEditSurvey', async (id: number) => {
  return await appAPI.survey.getEditSurvey(id)
})
const sendEditSurvey = createAsyncThunk('/sendEditSurvey', async (payload: EditSurvey) => {
  return await appAPI.survey.sendEditSurvey(payload)
})
const updateEditSurvey = createAsyncThunk('/updateEditSurvey', async (payload: EditSurvey) => {
  return await appAPI.survey.updateEditSurvey(payload)
})
const deleteEditSurvey = createAsyncThunk('/deleteEditSurvey', async (payload: number) => {
  return await appAPI.survey.deleteEditSurvey(payload)
})

const fetchSurveyQuestions = createAsyncThunk('/fetchSurveyQuestions', async (id: number) => {
  return await appAPI.survey.getQuestions(id)
})
const fetchSurveyAllQuestions = createAsyncThunk('/fetchSurveyAllQuestions', async () => {
  return await appAPI.survey.getAllQuestions()
})
const fetchCompetenceQuestion = createAsyncThunk('/fetchQuestionCompetence', async (id: number[]) => {
  return await appAPI.survey.getQuestionsByCompetence(id)
})
const fetchSectionQuestion = createAsyncThunk('/fetchSectionQuestions', async (id: number[]) => {
  return await appAPI.survey.getQuestionsBySection(id)
})

const SurveyAdminSlice = createSlice({
  name: 'SurveyAdmin',
  initialState,
  reducers: {
    setEditMode: (state, { payload }: PayloadAction<typeof initialState.surveyEditMode>) => {
      state.surveyEditMode = payload
    },
    clearQuestions: (state) => {
      state.surveyQuestions = initialState.surveyQuestions
    },
    deleteSurveyItem: (state, { payload }: PayloadAction<number>) => {
      state.surveyList.data = state.surveyList.data.filter((x) => x.id !== payload)
    },
    clearList: (state) => {
      state.surveyList = initialState.surveyList
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveyList.pending, (state) => {
        state.surveyList.loading = true
      })
      .addCase(fetchSurveyList.fulfilled, (state, { payload }) => {
        state.surveyList.loading = false
        state.surveyList.data = payload.data
      })
      .addCase(fetchSurveyList.rejected, (state) => {
        state.surveyList.loading = false
        state.surveyList.data = initialState.surveyList.data
      })
      .addCase(fetchEditSurvey.pending, (state) => {
        state.surveyEdit.loading = true
      })
      .addCase(fetchEditSurvey.fulfilled, (state, { payload }) => {
        state.surveyEdit.loading = false
        state.surveyEdit.data = payload.data
      })
      .addCase(fetchEditSurvey.rejected, (state) => {
        state.surveyEdit.loading = false
        state.surveyEdit.data = initialState.surveyEdit.data
      })
      .addCase(fetchSurveyQuestions.pending, (state) => {
        state.surveyQuestions.loading = true
      })
      .addCase(fetchSurveyQuestions.fulfilled, (state, { payload }) => {
        state.surveyQuestions.loading = false
        state.surveyQuestions.data = payload.data.results
      })
      .addCase(fetchSurveyQuestions.rejected, (state) => {
        state.surveyQuestions.loading = false
        state.surveyQuestions.data = initialState.surveyQuestions.data
      })
      .addCase(fetchSectionQuestion.pending, (state) => {
        state.surveyQuestions.loading = true
      })
      .addCase(fetchSectionQuestion.fulfilled, (state, { payload }) => {
        state.surveyQuestions.loading = false
        state.surveyQuestions.data = payload.data.map((x) => ({
          id: x.id,
          competence: x.competences[0],
          file: x.file,
          text: x.text,
          section: x.sectionId,
          type: x.type,
          answers: x.answers.map((a) => ({ id: a.id, answer: a.answer })),
        }))
      })
      .addCase(fetchSectionQuestion.rejected, (state) => {
        state.surveyQuestions.loading = false
      })
      .addCase(fetchCompetenceQuestion.pending, (state) => {
        state.surveyQuestions.loading = true
      })
      .addCase(fetchCompetenceQuestion.fulfilled, (state, { payload }) => {
        state.surveyQuestions.data = payload.data
        state.surveyQuestions.loading = false
      })
      .addCase(fetchCompetenceQuestion.rejected, (state) => {
        state.surveyQuestions.loading = false
      })
      .addCase(fetchSurveyAllQuestions.pending, (state) => {
        state.surveyQuestions.loading = true
      })
      .addCase(fetchSurveyAllQuestions.fulfilled, (state, { payload }) => {
        state.surveyQuestions.data = payload.data.results
        state.surveyQuestions.loading = false
      })
      .addCase(fetchSurveyAllQuestions.rejected, (state) => {
        state.surveyQuestions.loading = false
      })
  },
})

export default SurveyAdminSlice.reducer

export const surveySliceActions = {
  ...SurveyAdminSlice.actions,
  fetchSurveyList,
  fetchEditSurvey,
  fetchSurveyQuestions,
  sendEditSurvey,
  fetchSectionQuestion,
  deleteEditSurvey,
  updateEditSurvey,
  fetchCompetenceQuestion,
  fetchSurveyAllQuestions,
}
