import { useEffect, useState } from 'react'
import { DataType, statisticTypes } from '../types/statisticType'
import { TableColumnsType } from 'antd'
import { columns } from '../constants/columns'

interface PropsTypes {
  statisticType: statisticTypes
}

export const useColumns = (props: PropsTypes): TableColumnsType<DataType> | undefined => {
  const { statisticType } = props

  const [needColumns, setNeedColumns] = useState<TableColumnsType<DataType> | undefined>(undefined)

  useEffect(() => {
    setNeedColumns(columns?.[statisticType])
  }, [statisticType])

  return needColumns
}
