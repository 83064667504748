import React from 'react'

const Statistic: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24 } = props
  return (
    <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 8.5C7.10457 8.5 8 9.39543 8 10.5V18.5C8 19.6046 7.10457 20.5 6 20.5C4.89543 20.5 4 19.6046 4 18.5V10.5C4 9.39543 4.89543 8.5 6 8.5ZM18 11.5C19.1046 11.5 20 12.3954 20 13.5V18.5C20 19.6046 19.1046 20.5 18 20.5C16.8954 20.5 16 19.6046 16 18.5V13.5C16 12.3954 16.8954 11.5 18 11.5ZM12 2.5C13.1046 2.5 14 3.39543 14 4.5V18.5C14 19.6046 13.1046 20.5 12 20.5C10.8954 20.5 10 19.6046 10 18.5V4.5C10 3.39543 10.8954 2.5 12 2.5Z"
        fill="#309BEC"
      />
    </svg>
  )
}

export default Statistic
