import React, { FC } from 'react'

const MoreVert: FC = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="10" cy="10.0001" rx="1.66667" ry="1.66667" transform="rotate(-90 10 10.0001)" fill="#818C99" />
      <ellipse cx="10" cy="4.16671" rx="1.66667" ry="1.66667" transform="rotate(-90 10 4.16671)" fill="#818C99" />
      <ellipse cx="10" cy="15.8334" rx="1.66667" ry="1.66667" transform="rotate(-90 10 15.8334)" fill="#818C99" />
    </svg>
  )
}

export default MoreVert
