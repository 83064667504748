import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/store'
import { accountActions } from '../store/slices/accountSlice'

const useCheckUserProfile = (): void => {
  const dispatch = useDispatch()
  const clearUser = useSelector<RootState, boolean>((state) => state.account.clearUser)

  useEffect(() => {
    dispatch(accountActions.fetchProfile())
  }, [dispatch])

  useEffect(() => {
    if (clearUser) {
      dispatch(accountActions.clearUserData())
    }
  }, [clearUser, dispatch])
}

export default useCheckUserProfile
