import React, { FC, useState } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import store, { RootState } from '../../../store/store'

type PrivateRouteProps = RouteProps

const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const [hasReceivedProfile, setHasReceivedProfile] = useState(false)
  const { user, clearUser } = useSelector((state: RootState) => state.account)

  store.subscribe(() => {
    if (!store.getState().account.loading) setHasReceivedProfile(true)
  })

  if (!hasReceivedProfile && !user) return <></>
  if ((hasReceivedProfile && !user) || clearUser) return <Redirect to={'/'} />
  return <Route {...props} />
}

export default PrivateRoute
