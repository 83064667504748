import React, { FC, useCallback, useEffect } from 'react'
import { Col, Row, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { PayloadAction } from '@reduxjs/toolkit'

import { validateMessages } from '../../../utils/constants'
import styles from './feedback.module.scss'
import { Block, Button, Form, Input, Link, Modal } from '../../common'
import { Title } from '../../common/text'
import { feedbackActions } from '../../../store/slices/feedbackSlice'
import { RootState } from '../../../store/store'
import * as Models from '../../../api/model/models'
import useNeedCaptcha from '../../../hooks/useNeedCaptcha'
import { emailValidator } from '../../../utils/ant-form-validators'
import { addCountlyEvent } from '../../../utils/stats'

const Feedback: FC = () => {
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const { needCaptcha, checkCaptchaResponse } = useNeedCaptcha()

  const user = useSelector<RootState, Models.UserWSGetPrivate | undefined>((state) => state.account.user)
  const loading = useSelector<RootState, boolean>((state) => state.feedback.loading)

  const getUserName = useCallback(() => {
    if (!user) return ''
    const name = `${user.lastName} ${user.firstName} ${user.middleName}`.slice(0, 150)
    return name.trim() === '' ? user.username : name
  }, [user])

  const handleSubmit = async (fields: Models.FeedbackMsg) => {
    const response = (await dispatch<unknown>(
      feedbackActions.sendFeedback({
        ...fields,
      }),
    )) as PayloadAction<unknown, string, { payload: void; type: string }, { message: string }>

    if (
      checkCaptchaResponse(response, () => {
        form.scrollToField('captchaValue')
      })
    ) {
      form.setFieldsValue({ captchaValue: '' })
      return
    }

    addCountlyEvent('Обратная связь', { Действие: 'Выполнена попытка отправки', Результат: 'Неудачно' })

    if (response.type === 'sendFeedback/fulfilled') {
      if (user) {
        form.setFieldsValue({
          messageText: '',
        })
      } else {
        form.resetFields()
      }

      addCountlyEvent('Обратная связь', { Действие: 'Выполнена попытка отправки', Результат: 'Удачно' })

      Modal.success({
        title: 'Сообщение отправлено',
        content: 'Скоро мы ответим',
        maskClosable: true,
        okButtonProps: {
          style: { display: 'none' },
        },
      })
    }
  }

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        senderName: getUserName(),
        senderEmail: user.email.slice(0, 254),
      })
    } else {
      form.setFieldsValue({
        senderName: '',
        senderEmail: '',
      })
    }
  }, [getUserName, user, form])

  useEffect(() => {
    return () => {
      dispatch(feedbackActions.clearFeedback())
    }
  }, [dispatch])

  return (
    <Spin spinning={loading}>
      <Block className={styles.block}>
        <Form form={form} name="feedback" validateMessages={validateMessages} layout="vertical" onFinish={handleSubmit}>
          <Row>
            <Col xs={24} sm={24} md={24}>
              <Title level={4}>Написать нам</Title>
              <p className={styles.p}>
                Если у Вас есть вопросы по работе портала, предложения по улучшению и любые другие комментарии, напишите
                нам.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                name="senderName"
                label={'Как к Вам обращаться?'}
                rules={[
                  {
                    whitespace: true,
                    max: 150,
                  },
                ]}
              >
                <Input autoFocus placeholder={'Ф.И.О.'} hidden={!!user} />
              </Form.Item>
              {!!user && <div className={styles.contact}>{getUserName()}</div>}
              <Form.Item
                name="senderEmail"
                label={'E-mail'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    max: 254,
                    validator: emailValidator,
                  },
                ]}
              >
                <Input autoFocus placeholder={'rea@example.ru'} hidden={!!user} />
              </Form.Item>
              {!!user && <div className={styles.contact}>{user.email}</div>}
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="messageText"
                label={'Текст обращения'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    max: 1000,
                  },
                ]}
              >
                <Input.TextArea autoFocus placeholder={'Как зарегистрироваться?'} rows={6} />
              </Form.Item>
            </Col>
          </Row>
          {needCaptcha && (
            <Row>
              <Col xs={24} sm={24} md={16}>
                <Form.Captcha form={form} />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} sm={24} md={24}>
              <Form.Item>
                Нажимая «ОТПРАВИТЬ», Вы соглашаетесь c условиями{' '}
                <Link to={'/terms-of-service'}>Пользовательского соглашения</Link> и{' '}
                <Link to={'/confidentiality-policy'}>Политики в отношении обработки персональных данных</Link>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24}>
              <Form.Item className={styles.groupButtons}>
                <Button type="primary" htmlType="submit">
                  ОТПРАВИТЬ
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Block>
    </Spin>
  )
}

export default Feedback
