import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-http-source-selector'

import styles from './video-gallery.module.scss'

export interface PropsVideoGallery {
  src?: string
  thumbnail?: string
  videoManifest?: string
  visible?: boolean
}

const VideoGallery: FC<PropsVideoGallery> = (props) => {
  const { src, thumbnail, videoManifest, visible } = props
  const [ready, setReady] = useState(false)
  const videoNode = useRef<HTMLVideoElement | null>(null)
  const player = useRef<videojs.Player | null>(null)

  const videoJsOptions: videojs.PlayerOptions = useMemo(() => {
    return {
      autoplay: false,
      controls: true,
      poster: thumbnail,
      fill: true,
      plugins: {
        httpSourceSelector: { default: 'auto' },
      },
    }
  }, [thumbnail])

  const source = useMemo(() => {
    return [
      { src: videoManifest || '', type: videoManifest?.endsWith('.mpd') ? 'application/dash+xml' : '' },
      { src: src || '' },
    ]
  }, [videoManifest, src])

  const setPlayerSrc = useCallback(() => {
    if (visible && ready && source) {
      player.current?.src(source)
    }
  }, [visible, ready, source])

  useEffect(() => {
    setPlayerSrc()

    return () => {
      player.current?.reset()
    }
  }, [setPlayerSrc])

  useEffect(() => {
    player.current = videojs(videoNode.current as HTMLVideoElement, videoJsOptions, () => setReady(true))

    return () => {
      player.current?.dispose()
    }
  }, [videoJsOptions])

  return (
    <div className={[styles.boxVideo, 'video-gallery'].join(' ')}>
      <video className="video-js" ref={videoNode} />
    </div>
  )
}

export default VideoGallery
