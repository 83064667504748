import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { CurrentDataDetailResultType, IdTitleType, ResultTest, Statuses } from '../../components/survey/types'

const initialState: StateType = {
  results: { loading: false, data: [] },
  result: { loading: false, data: undefined as unknown as CurrentDataDetailResultType },
  search: '',
  surveysList: [],
  currentId: 0,
}

const getResult = createAsyncThunk('getResultBySurveys', ({ id, offset, limit, query }: ResultQuery) => {
  return appAPI.results.get(offset, limit, query, id)
})
const getFullResult = createAsyncThunk('getFullResult', (id: number) => {
  return appAPI.results.getFull(id)
})
const getSurveysList = createAsyncThunk('getSurveysList', () => {
  return appAPI.survey.getAdminList({ offset: 0, limit: 0 }, Statuses.all)
})

const ResultSlice = createSlice({
  name: 'resultSlice',
  initialState,
  reducers: {
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload
    },
    clearResult: (state) => {
      state.results.data = []
    },
    setCurrentId: (state, { payload }: PayloadAction<number>) => {
      state.currentId = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResult.pending, (state) => {
        state.results.loading = true
      })
      .addCase(getResult.fulfilled, (state, { payload }) => {
        state.results.loading = false
        state.results.data = state.results.data.concat(payload.data.results)
      })
      .addCase(getResult.rejected, (state) => {
        state.results.loading = false
      })
      .addCase(getFullResult.pending, (state) => {
        state.result.loading = true
      })
      .addCase(getFullResult.fulfilled, (state, { payload }) => {
        state.result.loading = false
        state.result.data = payload.data
      })
      .addCase(getFullResult.rejected, (state) => {
        state.result.loading = false
      })
      .addCase(getSurveysList.fulfilled, (state, { payload }) => {
        state.surveysList = payload.data.map((x) => ({ id: x.id, title: x.title }))
      })
  },
})

export default ResultSlice.reducer

export const resultSliceActions = {
  ...ResultSlice.actions,
  getResult,
  getFullResult,
  getSurveysList,
}

type StateType = {
  results: DataLoad<ResultTest[]>
  result: DataLoad<CurrentDataDetailResultType>
  search: string
  surveysList: IdTitleType[]
  currentId: number
}

type ResultQuery = {
  id: number
  offset: number
  limit: number
  query?: string
}
