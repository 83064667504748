import { Col, Row, Typography } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useIsSticky from '../../../hooks/useIsSticky'
import { RootState } from '../../../store/store'
import { addCountlyEvent } from '../../../utils/stats'
import { Modal } from '../../common'
import { Close } from '../../common/icons'
import Feedback from '../../forms/feedback/feedback'
import DigitalAtomLogo from '../../../assets/img/digitalatom-logo.svg'
import ConsistLogo from '../../../assets/img/consist-logo.svg'
import styles from './footer.module.scss'

const Footer: FC = () => {
  const [visibleFeedback, setVisibleFeedback] = useState(false)
  const successSend = useSelector((state: RootState) => state.feedback.successSend)

  useEffect(() => {
    if (successSend) {
      setVisibleFeedback(false)
    }
  }, [successSend])

  const handleClickFeedback = () => {
    if (!visibleFeedback) {
      setVisibleFeedback(true)
      addCountlyEvent('Обратная связь', { Действие: 'Форма открыта', Источник: 'Футер' })
    }
  }

  const handleClickCloseFeedback = () => {
    setVisibleFeedback(false)
    addCountlyEvent('Обратная связь', { Действие: 'Форма закрыта без отправки', Источник: 'Футер' })
  }

  const { stickedClassName } = useIsSticky(
    false,
    styles.sticked,
    document.body.scrollHeight - window.innerHeight > 100 ? 100 : 0,
  )

  return (
    <div className={[styles.wrapper, stickedClassName].join(' ')}>
      <div className={styles.container}>
        <Row align={'middle'} justify={'space-between'} wrap={false}>
          <Col className={styles.menuCol} onClick={handleClickFeedback}>
            <Typography className={styles.feedback}>Обратная связь</Typography>
          </Col>
          <Col className={styles.menuCol}>
            <div className={styles.divider} />
          </Col>
          <Col className={styles.menuCol}>
            <Link to={'/terms-of-service'}>Пользовательское соглашение</Link>
          </Col>
          <Col className={styles.menuCol}>
            <div className={styles.divider} />
          </Col>
          <Col className={styles.menuCol}>
            <Link to={'/confidentiality-policy'}>Политика в отношении обработки персональных данных</Link>
          </Col>
          <Col className={styles.menuCol}>
            <div className={styles.divider} />
          </Col>
          <Col className={styles.menuCol}>
            <Typography>© {new Date().getFullYear()} РОСЭНЕРГОАТОМ КОНСИСТ-ОС</Typography>
          </Col>
        </Row>
        <Row align={'middle'} justify={'center'} wrap={false} className={styles.digitalatomRow}>
          <Col>
            <Typography>
		ПО «Портал WorldSkills»
              <a
                href="https://digitalatom.ru"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.digitalatom}
                onClick={() => addCountlyEvent('Клик по ссылке Digital Atom')}
              >
                <img src={DigitalAtomLogo} alt=" " />
              </a>
              <a
                  href="https://consyst-os.ru/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.digitalatom}
                  onClick={() => addCountlyEvent('Клик по ссылке Consist')}
              >
                <img src={ConsistLogo} alt=" " />
              </a>
            </Typography>
          </Col>
        </Row>
      </div>
      <Modal
        visible={visibleFeedback}
        onCancel={handleClickCloseFeedback}
        footer={null}
        className={styles.modal}
        closeIcon={<Close />}
        destroyOnClose={true}
      >
        <Feedback />
      </Modal>
    </div>
  )
}

export default Footer
