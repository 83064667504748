import React, { FC } from 'react'
import useCheckUserProfile from '../../hooks/useCheckUserProfile'
import { Layout } from 'antd'
import HeaderSurvey from '../../components/navigation/header/header-survey/header-survey'
import IcoMenu from '../../components/navigation/menu/ico-menu/ico-menu'
import ErrorHandler from '../../components/navigation/error-handler/error-handler'
import Alert from '../../components/common/alert/alert'
import './survey-app.ant.scss'
import SurveyFooter from '../../components/navigation/footer/survey-footer/survey-footer'

const SurveyApp: FC = (props) => {
  const { children } = props
  useCheckUserProfile()
  return (
    <>
      <Layout className={'customSurveyLayout'}>
        <Layout.Header role={'heading'}>
          <HeaderSurvey />
        </Layout.Header>
        <Layout>
          <Layout.Sider width={56} collapsedWidth={0}>
            <IcoMenu />
          </Layout.Sider>
          <Layout.Content>
            <ErrorHandler>{children}</ErrorHandler>
          </Layout.Content>
        </Layout>
        <Alert />
      </Layout>
      <Layout.Footer className={'customSurveyLayoutFooter'}>
        <SurveyFooter />
      </Layout.Footer>
    </>
  )
}

export default SurveyApp
