import React from 'react'

const Picture: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19L3 5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3L19 3C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5L21 19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21L5 21ZM5 19H19L19 5L5 5L5 19ZM6 17H18L14.25 12L11.25 16L9 13L6 17Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Picture
