import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const TrashBin: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 15.816909,9.1644003 C 15.907709,8.6196 15.539709,8.1044 14.994909,8.0136 c -0.5448,-0.0908 -1.06,0.2772 -1.1508,0.8220003 z M 5.8169082,8.8356003 C 5.7261082,8.2908 5.2109082,7.9228 4.6661082,8.0136 c -0.5448,0.0908 -0.9128,0.606 -0.822,1.1508003 z M 13.609009,16.3288 12.622609,16.1644 Z M 8.0248082,19 H 11.636209 V 17 H 8.0248082 Z m 6.5706008,-2.5068 1.2215,-7.3287997 -1.9728,-0.3288 -1.2215,7.3287997 z M 3.8441082,9.1644003 l 1.2215,7.3287997 1.9728,-0.3288 -1.2215,-7.3287997 z M 11.636209,19 c 1.4666,0 2.7181,-1.0602 2.9592,-2.5068 l -1.9728,-0.3288 C 12.542309,16.6466 12.125109,17 11.636209,17 Z M 8.0248082,17 c -0.4889,0 -0.9061,-0.3534 -0.9864,-0.8356 l -1.9728,0.3288 c 0.2411,1.4466 1.4926,2.5068 2.9592,2.5068 z"
        fill={color}
      />
      <rect x="1.8" y="4" width="16" height="2" rx="1" fill={color} />
      <rect x="6.8305082" y="1" width="6" height="2" rx="1" fill={color} />
    </svg>
  )
}

TrashBin.defaultProps = {
  size: 20,
  className: '',
  color: '#818C99',
}

export default TrashBin
