import React, { FC } from 'react'

const Download: FC = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1.00012V13.0001" stroke="#309BEC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5 9.00012L9 13.0001L13 9.00012"
        stroke="#309BEC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 14.0001V15.0001C1 17.2093 2.79086 19.0001 5 19.0001H13C15.2091 19.0001 17 17.2093 17 15.0001V14.0001"
        stroke="#309BEC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Download
