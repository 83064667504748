import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api/api'
import { SearchGroup } from '../../api/model/models'
import { addCountlyEvent } from '../../utils/stats'

type GlobalSearchState = {
  isShowModal: boolean
  loading: boolean
  loadingGroup: boolean
  resultSearch?: SearchGroup[]
  resultSearchByGroup?: SearchGroup
  currentExpression?: string
}

const initialGlobalSearchState: GlobalSearchState = {
  isShowModal: false,
  loading: false,
  loadingGroup: false,
  resultSearch: undefined,
  resultSearchByGroup: undefined,
  currentExpression: undefined,
}

export const fetchSearch = createAsyncThunk('fetchSearch', async (payload: GlobalSearchPayload) => {
  return api.globalSearch.getSearch(payload)
})

export const fetchSearchByGroup = createAsyncThunk(
  'fetchSearchByGroup',
  async (payload: GlobalSearchByGroupPayload) => {
    const res = await api.globalSearch.getSearchByGroup(payload)
    const group = payload.modelName
    return { res, group }
  },
)

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState: initialGlobalSearchState,
  reducers: {
    hideModal: (state) => {
      state.isShowModal = false
      state.resultSearch = initialGlobalSearchState.resultSearch
    },
    showModal: (state) => {
      state.isShowModal = true
    },
    clearAll: () => {
      return initialGlobalSearchState
    },
    clearResultSearch: (state) => {
      state.resultSearch = initialGlobalSearchState.resultSearch
      state.currentExpression = initialGlobalSearchState.currentExpression
    },
    clearResultSearchByGroup: (state) => {
      state.resultSearchByGroup = initialGlobalSearchState.resultSearchByGroup
    },
    setCurrentExpression: (state, { payload }) => {
      state.currentExpression = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearch.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSearch.fulfilled, (state, { payload }) => {
        state.loading = false
        state.resultSearch = payload.data
        if (payload.data.length > 0) {
          addCountlyEvent('Поиск на портале', { Результат: 'Удачно', Раздел: 'Global' })
        } else {
          addCountlyEvent('Поиск на портале', { Результат: 'Недачно' })
        }
      })
      .addCase(fetchSearch.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchSearchByGroup.pending, (state) => {
        state.loadingGroup = true
      })
      .addCase(fetchSearchByGroup.fulfilled, (state, { payload }) => {
        state.loadingGroup = false
        state.resultSearchByGroup = payload.res.data.results
        addCountlyEvent('Поиск на портале', { Раздел: payload.group })
      })
      .addCase(fetchSearchByGroup.rejected, (state) => {
        state.loadingGroup = false
        state.resultSearchByGroup = initialGlobalSearchState.resultSearchByGroup
      })
  },
})

export const globalSearchActions = {
  ...globalSearchSlice.actions,
  fetchSearch,
  fetchSearchByGroup,
}

export default globalSearchSlice.reducer
