import { Menu as AntMenu } from 'antd'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import { RootState } from '../../../store/store'
import { addCountlyEvent } from '../../../utils/stats'
import {
  BarChart,
  Briefcase,
  Contact,
  Globe,
  House,
  Newspaper,
  Picture,
  QuestionCircleFilled,
  Tags,
  TestingIcon,
  People,
} from '../../common/icons'
import Item from './item/item'
import './menu.ant.scss'
import Group from '../../common/icons/group'
import Sections from '../../common/icons/sections'
import Questions from '../../common/icons/questions'
import { ContainerOutlined } from '@ant-design/icons'
import useInvisibleScroll from '../../survey/hooks/invisible-scoll'
import Results from '../../common/icons/results'

const Menu: FC = () => {
  const location = useLocation()
  const user = useSelector((state: RootState) => state.account.user)
  const userAdminOrExpert = user?.isAdmin || user?.competence?.isDivisionExpert
  const userIsORP = user?.competence?.isOrp
  const rootLocation = /^\/[^/]+/g.exec(location.pathname)
  useInvisibleScroll()
  const path =
    (rootLocation &&
      (routes.SURVEYS_ADMIN.includes(rootLocation[0]) && rootLocation[0] !== routes.SURVEYS
        ? '/' + location.pathname.split('/')[1] + '/' + location.pathname.split('/')[2]
        : rootLocation[0])) ||
    '/'

  return (
    <AntMenu mode="inline" className={'custom-menu dy-scrollbar-off'} defaultSelectedKeys={['/']} selectedKeys={[path]}>
      <Item route={routes.MAIN} title={'Главная'} icon={<House />} key={1} />
      <Item
        route={routes.ABOUT}
        title={'WorldSkills'}
        icon={<Globe />}
        key={2}
        onClick={() => addCountlyEvent('Переход в раздел О WorldSkills', { Источник: 'Меню' })}
      />
      <Item
        route={routes.NEWS}
        title={'Новости'}
        icon={<Newspaper />}
        key={3}
        onClick={() => addCountlyEvent('Переход в раздел новостей', { Источник: 'Меню' })}
      />
      <Item route={routes.CHAMPIONSHIPS} title={'Чемпионаты'} icon={<BarChart />} key={4} />
      {/* Добавить условие в зависимости от того, есть ли доступ к разделу */}
      {userAdminOrExpert || userIsORP ? (
        <Item route={routes.PARTICIPANTS} title={'Участники'} icon={<People />} key={5} />
      ) : null}
      <Item route={routes.COMPETENCIES} title={'Компетенции'} icon={<Briefcase />} key={6} />
      <Item route={routes.EVENTS} title={'События'} icon={<Tags />} key={7} />
      <Item route={routes.GALLERY} title={'Галерея'} icon={<Picture />} key={8} />
      {/*TODO: условия по админу / обычному пользователю*/}
      {userAdminOrExpert ? (
        <>
          {/*<AntMenu.SubMenu key={11} icon={<TestingIcon />} title={<Text size={15}>Тестирование</Text>}>
            <Item route={routes.SURVEYS_CONTROL} title={'Тесты'} key={12} />
            <Item route={routes.SURVEYS_GROUP} title={'Группы'} key={14} />
            <Item route={routes.SURVEYS_SECTION} title={'Секции'} key={15} />
            <Item route={routes.SURVEYS_QUESTIONS} title={'Вопросы'} key={16} />
          </AntMenu.SubMenu>*/}
          <AntMenu.Item key={99} className={'item-line-full'} />
          <Item
            route={routes.SURVEYS_CONTROL}
            title={'Тестирования'}
            icon={<TestingIcon />}
            className={'up-line'}
            key={12}
          />
          <Item route={routes.SURVEYS_GROUP} title={'Группы'} icon={<Group />} key={13} />
          <Item route={routes.SURVEYS_SECTION} title={'Секции'} icon={<Sections />} key={14} />
          <Item route={routes.SURVEYS_QUESTIONS} title={'Вопросы'} icon={<Questions />} key={15} />
          <Item
            route={routes.SURVEYS_RESULTS}
            title={'Результаты'}
            icon={<Results />}
            className={'down-line'}
            key={16}
          />
          <AntMenu.Item key={98} className={'item-line-full'} />
        </>
      ) : (
        <>{!user || <Item route={routes.SURVEYS} title={'Тестирование'} icon={<TestingIcon />} key={11} />}</>
      )}
      <Item
        route={routes.VOTING}
        title={'Голосования'}
        icon={<ContainerOutlined style={{ fontSize: '24px', color: '#818C99' }} />}
        key={17}
      />
      <Item route={routes.FAQ} title={'FAQ'} icon={<QuestionCircleFilled />} key={9} />
      <Item
        route={routes.CONTACTS}
        title={'Контакты'}
        icon={<Contact />}
        key={10}
        onClick={() => addCountlyEvent('Обратная связь', { Действие: 'Форма открыта', Источник: 'Меню' })}
      />
    </AntMenu>
  )
}

export default Menu
