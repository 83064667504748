import { Button, Form, Input, Modal, Typography } from 'antd'
import React, { FC, useState } from 'react'
import { validateMessages } from '../../../../../utils/constants'
import './../competence-docs.ant.scss'
import styles from './competence-docs-modal.module.scss'

type PropsCompetenceDocsModal = {
  visibleForm: boolean
  onCloseForm: () => void
  onSaveForm?: (folderName: string) => void
  title: string
  initialName?: string
  typeModal?: string
}

const CompetenceDocsAddAndEditFolderModal: FC<PropsCompetenceDocsModal> = (props) => {
  const { visibleForm, onCloseForm, onSaveForm, title, initialName, typeModal } = props

  const { useForm } = Form
  const [form] = useForm()

  const [folderName, setFolderName] = useState(initialName)

  const onSave = () => {
    const fields = form.getFieldsValue()

    if (onSaveForm) {
      onSaveForm(fields.name)
    }

    if (typeModal === 'edit') {
      setFolderName(fields.name)
    }

    form.resetFields()
    onCloseForm()
  }

  const onClose = () => {
    form.resetFields()
    onCloseForm()
  }

  return (
    <Modal
      visible={visibleForm}
      onCancel={onClose}
      destroyOnClose={true}
      maskClosable={true}
      footer={null}
      className={styles.modal}
    >
      <header>
        <Typography.Title level={4}>{title}</Typography.Title>
      </header>

      <Form
        form={form}
        name="directory"
        layout="vertical"
        validateMessages={validateMessages}
        onFinish={onSave}
        autoComplete="off"
      >
        <section>
          <Form.Item
            name="name"
            initialValue={folderName}
            rules={[
              { required: true, message: 'Это поле не должно быть пустым' },
              { type: 'string', max: 20, message: 'Название слишком длинное' },
            ]}
          >
            <Input placeholder="Введите название папки" />
          </Form.Item>
        </section>
        <footer>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
          <Button onClick={onClose}>Отменить</Button>
        </footer>
      </Form>
    </Modal>
  )
}

export default CompetenceDocsAddAndEditFolderModal
