import React, { FC } from 'react'

const Pen: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 15.4706L14.7059 5.76471C15.6805 4.79009 17.2607 4.79009 18.2353 5.76471C19.2099 6.73933 19.2099 8.3195 18.2353 9.29412L8.52941 19H5V15.4706Z"
        stroke="#309BEC"
        strokeWidth="2"
      />
      <path d="M15.4166 11.4166L12.0833 8.08325" stroke="#309BEC" strokeWidth="2" />
    </svg>
  )
}

export default Pen
