import React, { FC } from 'react'

type Props = {
  representation?: 'default' | 'header'
  size?: string | number
  color?: string
  className?: string
  onClick?: () => void
}

const Search: FC<Props> = (props) => {
  const { className = '', representation = 'default', onClick } = props
  const color = props.color ? props.color : styles[representation].color
  const size = props.size ? props.size : styles[representation].size

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M17 10C17 13.3137 14.3137 16 11 16C7.68629 16 5 13.3137 5 10C5 6.68629 7.68629 4 11 4C14.3137 4 17 6.68629 17 10ZM19 10C19 14.4183 15.4183 18 11 18C6.58172 18 3 14.4183 3 10C3 5.58172 6.58172 2 11 2C15.4183 2 19 5.58172 19 10ZM18.7071 16.2929C18.3166 15.9024 17.6834 15.9024 17.2929 16.2929C16.9024 16.6834 16.9024 17.3166 17.2929 17.7071L20.2929 20.7071C20.6834 21.0976 21.3166 21.0976 21.7071 20.7071C22.0976 20.3166 22.0976 19.6834 21.7071 19.2929L18.7071 16.2929Z"
      />
    </svg>
  )
}

const styles = {
  default: {
    size: '24px',
    color: '#818C99',
  },
  header: {
    size: '19px',
    color: '#309BEC',
  },
}

export default Search
