import React, { FC } from 'react'
import { TitleProps } from 'antd/lib/typography/Title'
import { Typography } from 'antd'
import './text.ant.scss'

const Title: FC<TitleProps> = (props) => {
  const { children } = props

  return <Typography.Title {...props}>{children}</Typography.Title>
}

export default Title
