import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { Event } from '../../api/model/event'

export type EventsState = {
  events: Event[]
  bannerOnMainPage?: Event
  page: number
  pages: number
  perPage: number
  loading: boolean
  bannerOnMainPageLoading: boolean
}

const initialEventsState: EventsState = {
  events: [],
  bannerOnMainPage: undefined,
  page: 0,
  pages: 1,
  perPage: 10,
  loading: false,
  bannerOnMainPageLoading: false,
}

const fetchEvents = createAsyncThunk('fetchEvents', async (payload: GetEventsPayload = { limit: 0, offset: 0 }) => {
  return await appAPI.events.getEvents(payload)
})

const fetchEventBannerOnMainPage = createAsyncThunk('fetchEventBannerOnMainPage', async () => {
  const response = await appAPI.events.getEvents({ bannerOnMainPage: true })
  if (response.data.results && response.data?.results[0]) {
    //need download a image before return the response data
    await new Promise((resolve) => {
      const image = new Image()
      image.addEventListener('load', () => {
        resolve(true)
      })
      response.data.results && (image.src = response.data.results[0].mainImageThumb550x280)
    })
  }
  return response
})

const eventsSlice = createSlice({
  name: 'events',
  initialState: initialEventsState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    clearEvents: () => initialEventsState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchEvents.fulfilled, (state, { payload, meta }) => {
        if (meta.arg?.offset !== 0) {
          state.events = [...state.events, ...(payload.data.results || [])]
        } else {
          state.events = payload.data.results || []
        }
        state.pages = payload.data.pages || 1
        state.loading = false
      })
      .addCase(fetchEvents.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchEventBannerOnMainPage.pending, (state) => {
        state.bannerOnMainPageLoading = true
      })
      .addCase(fetchEventBannerOnMainPage.fulfilled, (state, { payload }) => {
        state.bannerOnMainPage = payload.data.results && payload.data.results[0]
        state.bannerOnMainPageLoading = false
      })
      .addCase(fetchEventBannerOnMainPage.rejected, (state) => {
        state.bannerOnMainPageLoading = false
      })
  },
})

export default eventsSlice.reducer

export const eventsSliceActions = {
  ...eventsSlice.actions,
  fetchEvents,
  fetchEventBannerOnMainPage,
}
