import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const EyeInvisible: FC<Props> = (props) => {
  const { size = '24', className = '', color = '#333333', ...restProps } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5962 10.3312C13.1072 10.1181 12.5674 10 12 10C9.79086 10 8 11.7909 8 14C8 14.0531 8.00103 14.106 8.00308 14.1586L13.5962 10.3312ZM10.357 17.6481C10.8583 17.8742 11.4144 18 12 18C14.2091 18 16 16.2091 16 14C16 13.9297 15.9982 13.8598 15.9946 13.7903L10.357 17.6481Z"
        fill={color}
      />
      <path d="M21.5 7.5L4.5 19" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3406 7.76887C14.8349 6.18363 12.3581 5.86484 10.0919 6.34688C6.75258 7.05718 4.11218 9.45799 2.69121 11.4118C2.36637 11.8585 2.46512 12.4839 2.91178 12.8087C3.35843 13.1336 3.98385 13.0348 4.30868 12.5882C5.55438 10.8753 7.81398 8.87615 10.508 8.30311C11.9913 7.9876 13.6876 8.08764 15.5099 9.02163L17.3406 7.76887ZM20.632 10.617L18.9659 11.757C19.2181 12.0305 19.4715 12.3222 19.726 12.6332C20.0757 13.0607 20.7057 13.1237 21.1332 12.774C21.5606 12.4242 21.6236 11.7942 21.2739 11.3668C21.0604 11.1058 20.8464 10.8559 20.632 10.617Z"
        fill={color}
      />
    </svg>
  )
}

export default EyeInvisible
