import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginatedForumSearchResultList } from '../../api/model/paginatedForumSearchResultList'
import appAPI from '../../api/api'

type ForumSearchState = Omit<PaginatedForumSearchResultList, 'results'> & {
  loading: boolean
  currentExpression?: string
  resultSearch: PaginatedForumSearchResultList['results']
  limit: number
  page: number
  displayResults: boolean
}

const initialForumSearchState: ForumSearchState = {
  loading: false,
  currentExpression: '',
  resultSearch: [],
  limit: 20,
  page: 0,
  pages: 1,
  total: 0,
  displayResults: false,
}

const fetchForumSearch = createAsyncThunk(
  'fetchForumSearch',
  async (payload: ForumSearchPayload, { rejectWithValue }) => {
    try {
      return await appAPI.forumSearch.getSearch(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const forumSearchSlice = createSlice({
  name: 'forumSearch',
  initialState: initialForumSearchState,
  reducers: {
    setCurrentExpression: (state, { payload }: PayloadAction<string>) => {
      state.currentExpression = payload
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    clearForumSearch: () => initialForumSearchState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchForumSearch.pending, (state: ForumSearchState) => {
        state.loading = true
      })
      .addCase(fetchForumSearch.fulfilled, (state: ForumSearchState, { payload, meta: { arg } }) => {
        if (!payload.data.results) {
          return
        }
        // Concat results if search expression not change - used for pagination
        // or if expression change set new result for displaying updated array in search list
        if (state.currentExpression === arg.expr) {
          state.resultSearch?.push(...payload.data.results)
        } else {
          state.resultSearch = payload.data.results
        }

        state.currentExpression = arg.expr // Hashing last search expression

        state.pages = payload.data.pages
        state.total = payload.data.total
        state.displayResults = true
        state.loading = false
      })
      .addCase(fetchForumSearch.rejected, (state: ForumSearchState) => {
        state.loading = false
      })
  },
})

export default forumSearchSlice.reducer

export const forumSearchActions = {
  ...forumSearchSlice.actions,
  fetchForumSearch,
}
