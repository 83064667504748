import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { News } from '../../api/model/news'

export type NewsState = {
  news: News[]
  years: number[] | undefined
  currentYear: number | undefined
  page: number
  pages: number
  perPage: number
  loading: boolean
  loadingYears: boolean
}

const initialNewsState: NewsState = {
  news: [],
  years: undefined,
  currentYear: undefined,
  page: 0,
  pages: 1,
  perPage: 10,
  loading: false,
  loadingYears: false,
}

const fetchNews = createAsyncThunk(
  '/fetchNews',
  async (payload: GetNewsPayload = { limit: 0, offset: 0, publishOnMainPage: false }) => {
    return await appAPI.news.getNews(payload)
  },
)

const fetchNewsYears = createAsyncThunk('/fetchNewsYears', async () => {
  return await appAPI.news.getNewsYears()
})

const newsSlice = createSlice({
  name: 'news',
  initialState: initialNewsState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    setCurrentYear: (state, { payload }: PayloadAction<number | undefined>) => {
      state.currentYear = payload
    },
    clearNews: () => initialNewsState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchNews.fulfilled, (state, { payload, meta }) => {
        if (!meta.arg?.offset) {
          state.news = payload.data.results || []
          state.page = 0
        } else {
          state.news = [...state.news, ...(payload.data.results || [])]
        }

        state.pages = payload.data.pages || 1
        state.loading = false
      })
      .addCase(fetchNews.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchNewsYears.pending, (state) => {
        state.loadingYears = true
      })
      .addCase(fetchNewsYears.fulfilled, (state, { payload }) => {
        state.years = payload.data.years.sort((a, b) => b - a) || []
        state.loadingYears = false
      })
      .addCase(fetchNewsYears.rejected, (state) => {
        state.loadingYears = false
        state.years = []
      })
  },
})

export default newsSlice.reducer

export const newsSliceActions = {
  ...newsSlice.actions,
  fetchNews,
  fetchNewsYears,
}
