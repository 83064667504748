import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const Pencil: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.91663 11.75L11.0833 2.58332C12.0038 1.66285 13.4962 1.66285 14.4166 2.58332C15.3371 3.5038 15.3371 4.99618 14.4166 5.91666L5.24996 15.0833H1.91663V11.75Z"
        stroke={color}
        strokeWidth="2"
      />
      <path d="M12.3333 7.33333L9 4" stroke={color} strokeWidth="2" />
    </svg>
  )
}

Pencil.defaultProps = {
  size: 17,
  className: '',
  color: '#818C99',
}

export default Pencil
