import { useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import { ICO_MENU_PATHS } from '../constants/ico-menu-const'

type useIcoMenuType = () => boolean

export const useIcoMenu: useIcoMenuType = () => {
  const location = useLocation()
  return !!matchPath(location.pathname, ICO_MENU_PATHS)?.isExact
}
