import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { MainPageSlider } from '../../api/model/mainPageSlider'

type MainPageSliderState = {
  slides: MainPageSlider[]
  loading: boolean
}

const initialMainPageSliderState: MainPageSliderState = {
  slides: [],
  loading: false,
}

const fetchMainPageSlider = createAsyncThunk('/fetchMainPageSlider', async () => {
  const response = await appAPI.main.getMainPageSlider()
  if (response.data[0]) {
    //need download a first slide image before return the response data
    await new Promise((resolve) => {
      const image = new Image()
      image.addEventListener('load', () => {
        resolve(true)
      })
      image.src = response.data[0].image
    })
  }
  return response
})

const mainPageSliderSlice = createSlice({
  name: 'mainPageSlider',
  initialState: initialMainPageSliderState,
  reducers: {
    clearMainPageSlider: (state) => {
      state = initialMainPageSliderState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMainPageSlider.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchMainPageSlider.fulfilled, (state, { payload }) => {
        state.slides = payload.data
        state.loading = false
      })
  },
})

export default mainPageSliderSlice.reducer

export const mainPageSliderActions = {
  ...mainPageSliderSlice.actions,
  fetchMainPageSlider,
}
