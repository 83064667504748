export const roles_mock = [
  {
    id: 'participant',
    name: 'Участник',
  },
  {
    id: 'orp',
    name: 'Ответственный от ОРП',
  },
  {
    id: 'expert',
    name: 'Эксперт с дополнительными полномочиями',
  },
  {
    id: 'division_expert',
    name: 'Главный дивизионный эксперт',
  },
  {
    id: 'expert_compatriot',
    name: 'Эксперт-компатриот',
  },
]
