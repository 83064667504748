import React, { FC } from 'react'
import { Tooltip as AntTooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'
import './tooltip.ant.scss'

const Tooltip: FC<TooltipProps> = (props) => {
  return <AntTooltip {...props}></AntTooltip>
}

export default Tooltip
