import React, { FC } from 'react'
import { Rule } from 'antd/lib/form'

import Form from '../../common/form/form'
import Input from '../../common/input/input'
import { Eye, EyeInvisible } from '../../common/icons'

const { Item } = Form

type NamePath = string | number | (string | number)[]

interface Props {
  name: string
  label?: string
  placeholder?: string
  rules?: Rule[]
  type?: string
  hasFeedback?: boolean
  dependencies?: NamePath[]
  autoComplete?: string
}

const Field: FC<Props> = (props) => {
  const { type, placeholder, autoComplete, ...itemProps } = props
  return (
    <Item {...itemProps}>
      {type === 'password' ? (
        <Input.Password
          placeholder={placeholder}
          iconRender={(visible) => (visible ? <Eye color="#818C99" /> : <EyeInvisible color="#818C99" />)}
          autoComplete={autoComplete}
        />
      ) : (
        <Input type={type} placeholder={placeholder} autoComplete={autoComplete} />
      )}
    </Item>
  )
}

export default Field
