import React from 'react'

const Testing: React.FC<DefaultPropsIcon> = (props) => {
  const { onClick, className } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 21H5C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19L3 5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3L9.175 3C9.35833 2.41667 9.71667 1.93733 10.25 1.562C10.7833 1.18733 11.3667 1 12 1C12.6667 1 13.2627 1.18733 13.788 1.562C14.3127 1.93733 14.6667 2.41667 14.85 3L19 3C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V10L19 10V5L17 5V8L7 8V5H5L5 19H11V21ZM15.5 19.925L11.25 15.675L12.65 14.275L15.5 17.125L21.15 11.475L22.55 12.875L15.5 19.925ZM12 5C12.2833 5 12.521 4.904 12.713 4.712C12.9043 4.52067 13 4.28333 13 4C13 3.71667 12.9043 3.479 12.713 3.287C12.521 3.09567 12.2833 3 12 3C11.7167 3 11.4793 3.09567 11.288 3.287C11.096 3.479 11 3.71667 11 4C11 4.28333 11.096 4.52067 11.288 4.712C11.4793 4.904 11.7167 5 12 5Z"
          fill="#818C99"
        />
      </svg>
    </span>
  )
}

export default Testing
