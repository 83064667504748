import React, { FC } from 'react'

interface IArrowRightBreadcrumb {
  className?: string
  color?: string
}

const ArrowRightBreadcrumb: FC<IArrowRightBreadcrumb> = ({ className, color = '#B8C1CC' }) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 17L15 12L11 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowRightBreadcrumb
