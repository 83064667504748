import React, { FC, ReactNode } from 'react'
import { Menu as AntMenu } from 'antd'
import { Link } from 'react-router-dom'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'

import { Text } from '../../../common/text'

type ItemProps = {
  route: string
  icon?: ReactNode
  title: ReactNode
  showTitle?: boolean
}

const Item: FC<MenuItemProps & ItemProps> = (props) => {
  const { route, title, showTitle = true } = props

  // force assign a props to the Ant's Item to move the route value into a 'key'
  // for correct identify the selected item
  const antItemProps: MenuItemProps = { ...props, eventKey: route }

  return (
    <AntMenu.Item {...antItemProps}>
      <Link to={route}>{showTitle && <Text size={15}>{title}</Text>}</Link>
    </AntMenu.Item>
  )
}

export default Item
