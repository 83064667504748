import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { EventDetail } from '../../api/model/models'

export type EventDetailState = {
  event: EventDetail | null
  loading: boolean
}

const initialEventDetailState: EventDetailState = {
  event: null,
  loading: false,
}

const fetchEventDetail = createAsyncThunk('/fetchEventDetail', async (payload: { id: number }, { rejectWithValue }) => {
  try {
    return await appAPI.events.getEventDetail(payload.id)
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const eventDetailSlice = createSlice({
  name: 'eventDetail',
  initialState: initialEventDetailState,
  reducers: {
    clearEventDetail: (state) => {
      state.event = initialEventDetailState.event
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventDetail.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchEventDetail.fulfilled, (state, { payload }) => {
        state.event = payload.data
        state.loading = false
      })
      .addCase(fetchEventDetail.rejected, (state) => {
        state.loading = false
      })
  },
})

export default eventDetailSlice.reducer

export const eventDetailSliceActions = {
  ...eventDetailSlice.actions,
  fetchEventDetail,
}
