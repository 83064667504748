import React, { FC } from 'react'
import Typography from 'antd/lib/typography'
import { TextProps } from 'antd/lib/typography/Text'
import './text.ant.scss'

const Text: FC<TextProps & { size?: 'xxl' | 'xl' | 'lg' | 'sm' | number; paragraph?: boolean }> = (props) => {
  const { size, children, paragraph, className } = props

  return (
    <>
      {paragraph ? (
        <Typography.Paragraph
          {...{ ...props, paragraph: undefined }}
          className={`${className || ''} ${typeof size === 'string' ? size : ''}`}
          style={{ fontSize: typeof size === 'number' ? size : undefined }}
        >
          {children}
        </Typography.Paragraph>
      ) : (
        <Typography.Text
          {...props}
          className={`${className || ''} ${typeof size === 'string' ? size : ''}`}
          style={{ fontSize: typeof size === 'number' ? size : undefined }}
        >
          {children}
        </Typography.Text>
      )}
    </>
  )
}

export default Text
