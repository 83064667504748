import React, { FC } from 'react'

type Props = {
  size?: string | number
  className?: string
  color?: string
  onClick?: () => void
}

const Comment: FC<Props> = ({ size, color, ...restProps }) => {
  return (
    <svg width={size} height={size} {...restProps} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2121 12.8251C10.4564 12.6106 10.7701 12.4923 11.0948 12.4923H14.7447C15.7049 12.4923 16.2766 11.9185 16.2766 10.9548V3.26721C16.2766 2.30344 15.7049 1.7297 14.7447 1.7297H3.25532C2.29505 1.7297 1.7234 2.30344 1.7234 3.26721V10.9548C1.7234 11.9185 2.29505 12.4923 3.25532 12.4923H5.64798C6.31753 12.6861 6.31753 12.6861 6.51064 13.3581V16.0762L10.2121 12.8251ZM5.49096 17.7126C5.03903 17.3724 4.78723 16.7722 4.78723 16.24V14.222H3.25532C1.34324 14.222 0 12.8738 0 10.9548V3.26721C0 1.34815 1.34324 0 3.25532 0H14.7447C16.6568 0 18 1.34815 18 3.26721V10.9548C18 12.8738 16.6568 14.222 14.7447 14.222H11.2387L7.67244 17.3538C6.97079 18.0445 6.15094 18.2094 5.49096 17.7126Z"
        fill={color}
      />
    </svg>
  )
}

Comment.defaultProps = {
  size: 18,
  className: '',
  color: '#818C99',
}

export default Comment
