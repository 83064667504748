import React, { FC } from 'react'
import styles from './date-period-tag.module.scss'

type DatePeriodTagProps = {
  dateStart: string
  dateEnd?: string
  className?: string
}

const DatePeriodTag: FC<DatePeriodTagProps> = ({ dateStart, dateEnd, className = '' }) => {
  return (
    <div className={`${styles.dates} ${className}`}>
      <span>{`${dateStart}${dateEnd ? ` - ${dateEnd}` : ''}`}</span>
    </div>
  )
}

export default DatePeriodTag
