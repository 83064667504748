import React, { FC } from 'react'

type Props = {
  size?: string | number
  color?: string
  className?: string
  onClick?: () => void
}

const Calendar: FC<Props> = (props) => {
  const { className = '', color = '#333333', ...restProps } = props

  return (
    <svg
      width="18"
      height="18"
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.625 5.62506C5.00368 5.62506 4.5 5.12138 4.5 4.50006H3.375C2.75368 4.50006 2.25 5.00375 2.25 5.62506V14.6251C2.25 15.2464 2.75368 15.7501 3.375 15.7501H14.625C15.2463 15.7501 15.75 15.2464 15.75 14.6251V5.62506C15.75 5.00375 15.2463 4.50006 14.625 4.50006H13.5C13.5 5.12138 12.9963 5.62506 12.375 5.62506C11.7537 5.62506 11.25 5.12138 11.25 4.50006H6.75C6.75 5.12138 6.24632 5.62506 5.625 5.62506ZM11.25 2.25006H6.75V1.12506C6.75 0.50374 6.24632 6.10352e-05 5.625 6.10352e-05C5.00368 6.10352e-05 4.5 0.50374 4.5 1.12506V2.25006H3.375C1.51104 2.25006 0 3.7611 0 5.62506V14.6251C0 16.4891 1.51104 18.0001 3.375 18.0001H14.625C16.489 18.0001 18 16.4891 18 14.6251V5.62506C18 3.7611 16.489 2.25006 14.625 2.25006H13.5V1.12506C13.5 0.50374 12.9963 6.10352e-05 12.375 6.10352e-05C11.7537 6.10352e-05 11.25 0.50374 11.25 1.12506V2.25006ZM5.625 7.87506C5.00368 7.87506 4.5 8.37875 4.5 9.00006C4.5 9.62139 5.00368 10.1251 5.625 10.1251H12.375C12.9963 10.1251 13.5 9.62139 13.5 9.00006C13.5 8.37875 12.9963 7.87506 12.375 7.87506H5.625Z"
        fill={color}
      />
    </svg>
  )
}

export default Calendar
