import React, { FC, createContext, useState, useContext, useMemo, Dispatch, SetStateAction, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Error404 from '../../../pages/errors/404'

export type ErrorStatusProps = number | undefined

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ErrorStatusContext = createContext<{ setErrorStatusCode: Dispatch<SetStateAction<ErrorStatusProps>> }>({})

const ErrorHandler: FC = (props) => {
  const { children } = props
  const [errorStatusCode, setErrorStatusCode] = useState<ErrorStatusProps>(undefined)

  const contextPayload = useMemo(() => ({ setErrorStatusCode }), [setErrorStatusCode])

  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(() => setErrorStatusCode(undefined))
    return unlisten
  }, [history])

  return (
    <ErrorStatusContext.Provider value={contextPayload}>
      {errorStatusCode === 404 && <Error404 />}
      {!errorStatusCode && <>{children}</>}
    </ErrorStatusContext.Provider>
  )
}

export const useErrorStatus = () => useContext(ErrorStatusContext)

export default ErrorHandler
