import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from 'antd'

import styles from './modal-profile-card.module.scss'
import { Modal, ProfileCard } from '../index'
import { Title } from '../text'
import { Plus } from '../icons'
import { RootState } from '../../../store/store'
import { modalProfileCardSliceActions } from '../../../store/slices/modalProfileCardSlice'

/**
 * Modal Profile Card is a component showing when need displaying user profile card.
 * Included on App.tsx because we need displaying it modal different places.
 * If you need add this behavior any plaice, you need call fetchProfileCardById of modalProfileCardSlice.ts in your code.
 * */
const ModalProfileCard: React.FC = () => {
  const { visible, user } = useSelector((state: RootState) => state.modalProfileCard)
  const dispatch = useDispatch()

  const handleCloseModal = () => {
    dispatch(modalProfileCardSliceActions.hideProfileModal())
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleCloseModal}
      modalRender={() => (
        <div className={'ant-modal-content ' + styles.wrapper}>
          <div className={styles.header}>
            <Title level={2}>Профиль</Title>
            <Plus color={'#309BEC'} onClick={handleCloseModal} />
          </div>
          <Divider className={styles.divider} />
          <ProfileCard user={user} onClickCompetence={handleCloseModal} />
        </div>
      )}
    />
  )
}

export default ModalProfileCard
