import React, { FC } from 'react'
import Tooltip from '../tooltip/tooltip'
import { QuestionCircleOutlined } from '../icons'

type TooltipHelperProps = {
  description: React.ReactNode
  size?: number
  color?: string
  className?: string
}

const TooltipHelper: FC<TooltipHelperProps> = (props) => {
  const { description, size, color, className } = props
  return (
    <Tooltip title={description}>
      <QuestionCircleOutlined className={className} size={size} color={color} />
    </Tooltip>
  )
}

export default TooltipHelper
