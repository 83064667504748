import { useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'

type UseCheckPathForUserProfile = () => boolean

const useCheckPathForUserProfile: UseCheckPathForUserProfile = () => {
  const slug = useLocation()
  return !!matchPath(slug.pathname, {
    path: '/user-profile',
    exact: true,
  })?.isExact
}

export default useCheckPathForUserProfile
