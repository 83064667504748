import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { FaqQuestion } from '../../api/model/faqQuestion'
import appAPI from '../../api/api'

type FAQState = {
  questions: FaqQuestion[]
  loading: boolean
}

const initialFAQState: FAQState = {
  questions: [],
  loading: false,
}

const fetchFAQ = createAsyncThunk('/fetchFAQ', async () => {
  return await appAPI.FAQ.get()
})

const FAQSlice = createSlice({
  name: 'FAQ',
  initialState: initialFAQState,
  reducers: {
    clearFeedback: () => initialFAQState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFAQ.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchFAQ.fulfilled, (state, { payload }) => {
        state.loading = false
        state.questions = payload.data
      })
  },
})

export default FAQSlice.reducer

export const FAQActions = {
  ...FAQSlice.actions,
  fetchFAQ,
}
