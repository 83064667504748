import React, { FC } from 'react'

type Props = {
  size?: string | number
  color?: string
  className?: string
  onClick?: () => void
}

const Eye: FC<Props> = (props) => {
  const { size = '24', className = '', color = '#333333', ...restProps } = props

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.5 12C6.16667 8.33334 13.3 3.2 20.5 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="14" r="3" stroke={color} strokeWidth="2" />
    </svg>
  )
}

export default Eye
