import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { TopicPollOptionsResults } from '../../api/model/topicPollOptionsResults'
import { TopicPollVote } from '../../api/model/topicPollVote'
import { RootState } from '../store'
import { TopicPollCustomOptionsResults } from '../../api/model/topicPollCustomOptionsResults'

export type TopicPollVotesState = {
  customOptionsResults: Array<TopicPollCustomOptionsResults>
  optionsResults: Array<TopicPollOptionsResults>
  loading: boolean
}

export const initialTopicPollVotesState: TopicPollVotesState = {
  customOptionsResults: [],
  optionsResults: [],
  loading: false,
}

const createTopicPollVote = createAsyncThunk(
  'createTopicPollVote',
  async (payload: { topicId: number; vote: Omit<TopicPollVote, 'totalVotesCount'> }, { rejectWithValue }) => {
    try {
      return await appAPI.forumTopic.postTopicPollVote(payload.topicId, payload.vote)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const fetchTopicPollOptionsResults = createAsyncThunk(
  'fetchTopicPollOptionsResults',
  async (payload: { topicId: number }) => {
    return await appAPI.forumTopic.getTopicPollOptionsResults(payload.topicId)
  },
)

const fetchTopicPollCustomOptionsResults = createAsyncThunk(
  'fetchTopicPollCustomOptionsResults',
  async (payload: { topicId: number }) => {
    return await appAPI.forumTopic.getTopicPollCustomOptionsResults(payload.topicId)
  },
)

const topicPollVotesSlice = createSlice({
  name: 'topicPollVotes',
  initialState: initialTopicPollVotesState,
  reducers: {
    clearTopicPollOptionsResults: (state) => {
      state.optionsResults = initialTopicPollVotesState.optionsResults
    },
    clearTopicPollCustomOptionsResults: (state) => {
      state.customOptionsResults = initialTopicPollVotesState.customOptionsResults
    },
  },
  extraReducers: {
    [fetchTopicPollOptionsResults.fulfilled.type]: (state, { payload }) => {
      state.optionsResults = payload.data.results
    },
    [fetchTopicPollCustomOptionsResults.fulfilled.type]: (state, { payload }) => {
      state.customOptionsResults = payload.data.results
    },
  },
})

export default topicPollVotesSlice.reducer

export const topicPollVotesActions = {
  ...topicPollVotesSlice.actions,
  createTopicPollVote,
  fetchTopicPollOptionsResults,
  fetchTopicPollCustomOptionsResults,
}

export type topicPollResultInPercent = {
  [key: number]: number
}

export const selectTopicPollResultsInPercent = (state: RootState): topicPollResultInPercent => {
  const result: topicPollResultInPercent = {}
  const optionsResults = state.forumTopicPollVotes.optionsResults
  const totalResultCount = optionsResults.reduce((a, b) => a + parseInt(b.votesCount), 0) || 1

  for (const i of optionsResults) {
    const { id, votesCount } = i
    result[id] = parseInt(String((parseInt(votesCount) / totalResultCount) * 100))
  }

  return result
}
