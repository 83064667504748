import React, { useEffect, FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message as antMessage } from 'antd'
import './alert.ant.scss'

import { RootState } from '../../../store/store'
import { alertActions } from '../../../store/slices/alertSlice'

const Alert: FC = () => {
  const dispatch = useDispatch()

  const messages = useSelector((state: RootState) => state.alert.message)
  const severity = useSelector((state: RootState) => state.alert.severity)

  const success = useCallback(
    (message: string) => {
      antMessage.success(message)
      dispatch(alertActions.clearAlert())
    },
    [dispatch],
  )

  const error = useCallback(
    (message: string) => {
      antMessage.error(message)
      dispatch(alertActions.clearAlert())
    },
    [dispatch],
  )

  const warning = useCallback(
    (message: string) => {
      antMessage.warning(message)
      dispatch(alertActions.clearAlert())
    },
    [dispatch],
  )

  useEffect(() => {
    const show = (severity: string | undefined, message: string | undefined) => {
      if (!message) return
      if (severity === 'success') success(message)
      if (severity === 'warning') warning(message)
      if (severity === 'error') error(message)
    }

    if (messages) {
      if (Array.isArray(messages)) {
        messages.forEach((message: string) => {
          show(severity, message)
        })
      } else if (typeof messages === 'string') {
        show(severity, messages)
      }
    }
  }, [messages, success, warning, error, severity])

  return <></>
}

export default Alert
