import React, { FC } from 'react'

type DropdownIconProps = {
  width?: number
  height?: number
  backgroundColor?: string
  arrowColor?: string
  className?: string
}

const DropdownIcon: FC<DropdownIconProps> = (props) => {
  const { width = 24, height = 24, backgroundColor = '#E7EAED', arrowColor = '#333333', className = '' } = props

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="5" transform="matrix(1 0 0 -1 0 24)" fill={backgroundColor} />
      <path d="M7 11L12 15L17 11" stroke={arrowColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default DropdownIcon
