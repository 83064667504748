import React from 'react'
import { Link } from 'react-router-dom'

import styles from './button-only-icon.module.scss'

type ButtonOnlyIconProps = {
  icon: React.ReactNode
  color?: string
  paddingX?: number
  paddingY?: number
  href?: string
  onClick?: () => void
  simpleLink?: boolean
}

/**
 * Button component - use it if you need create button with icon and NOT more.
 * It special not use children for simplicity
 */
const ButtonOnlyIcon: React.FC<ButtonOnlyIconProps> = (props) => {
  const { icon, color = '#E6EFF6FF', paddingX = 8, paddingY = 8, href, simpleLink, onClick } = props

  const onClickHandler = () => {
    if (onClick) {
      onClick()
    }
  }

  const padding = { padding: `${paddingY}px ${paddingX}px` }
  const background = { background: color }

  return (
    <>
      {href ? (
        simpleLink ? (
          <a href={href}>
            <div className={styles.btn} style={{ ...padding, ...background }}>
              {icon}
            </div>
          </a>
        ) : (
          <Link to={href}>
            <div className={styles.btn} style={{ ...padding, ...background }}>
              {icon}
            </div>
          </Link>
        )
      ) : (
        <button className={styles.btn} style={{ ...padding, ...background }} onClick={onClickHandler}>
          {icon}
        </button>
      )}
    </>
  )
}

export default ButtonOnlyIcon
