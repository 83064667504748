import React, { FC } from 'react'

type ArrowSwitcherRightProps = {
  width?: number | string
  height?: number | string
  color?: string
}

const ArrowSwitcherRight: FC<ArrowSwitcherRightProps> = ({ color = '#309BEC', width = 20, height = 28 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4393 12.9395L6.99998 4.50015C6.4477 3.94787 5.55227 3.94787 4.99998 4.50015C4.4477 5.05244 4.4477 5.94787 4.99998 6.50015L12.5 14.0002L4.99998 21.5002C4.4477 22.0524 4.4477 22.9479 4.99998 23.5002C5.55227 24.0524 6.4477 24.0524 6.99998 23.5002L15.4393 15.0608C16.0251 14.475 16.0251 13.5253 15.4393 12.9395Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowSwitcherRight
